import * as yup from "yup";
import { CHECK_NECESARIO, CMB_NECESARIO, CP_INVALIDO, FECHA_VALIDA, MAYOR_EDAD, VALOR_NECESARIO } from "../Utils/Constantes";
import { validaMayorEdad } from "../Utils/Utils";


export const validationForm = yup.object().shape({
  fechanacimiento: yup
    .date()
    .required(VALOR_NECESARIO)
    .typeError(FECHA_VALIDA)
    .test(
      "fechaValida",
      FECHA_VALIDA,
      (fecha) => {
        if(fecha instanceof Date && !isNaN(fecha)){
          return true;
        } else {
          return false;
        }
      }
    )
    .test(
      "mayorEdad",
      MAYOR_EDAD,
      (val) => {
        if(validaMayorEdad(val)){
          return true;
        } else {
          return false;
        }
      }
    ),
  genero: yup
    .string()
    .required(VALOR_NECESARIO)
    .nullable(),
  cp: yup
    .string()
    .required(CP_INVALIDO)
    .nullable()
    .typeError(CP_INVALIDO)
    .length(5,CP_INVALIDO)
    .test(
      "formato",
      CP_INVALIDO,
      (val) => {
        var regex = /^\D*\d{5}$/;
        return regex.test(val);
      }
    ),
});

export const validationTratamientoForm = yup.object().shape({
  tipoEvento: yup
    .string()
    .required(CHECK_NECESARIO)
    .min(1, CHECK_NECESARIO)
    .max(1, CHECK_NECESARIO),
  descripcion: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(2000,'Debe tener como máximo 2000 caracteres'),
  mes: yup
    .number()
    .required(CMB_NECESARIO)
    .min(0, CMB_NECESARIO),
  anio: yup
    .number()
    .required(CMB_NECESARIO)
    .min(0, CMB_NECESARIO),
  estadoActual: yup
    .string()
    .required(CHECK_NECESARIO)
    .min(1, CHECK_NECESARIO)
    .max(1, CHECK_NECESARIO),
});

export const validationRecuperadoForm = yup.object().shape({
  tipoEvento: yup
    .string()
    .required(CHECK_NECESARIO)
    .min(1, CHECK_NECESARIO)
    .max(1, CHECK_NECESARIO),
  descripcion: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(2000,'Debe tener como máximo 2000 caracteres'),
  mes: yup
    .number()
    .required(CMB_NECESARIO)
    .min(0, CMB_NECESARIO),
  anio: yup
    .number()
    .required(CMB_NECESARIO)
    .min(0, CMB_NECESARIO),
  estadoActual: yup
    .string()
    .required(CHECK_NECESARIO)
    .min(1, CHECK_NECESARIO)
    .max(1, CHECK_NECESARIO),
  mesUltimaVez: yup
    .number()
    .required(CMB_NECESARIO)
    .min(0, CMB_NECESARIO),
  anioUltimaVez: yup
    .number()
    .required(CMB_NECESARIO)
    .min(0, CMB_NECESARIO),
});