import React, { useState }  from 'react';
import './Cuestionario.css';
import borderLineaDos from '../../assets/img/cuestionario/border-rounded-linea2.svg';
import Grid from '@mui/material/Grid';
import { calcularEdad, medidaGridByLength, serviceToGeneroValue, validaCombo, validaResponse } from '../Utils/Utils';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, MenuItem } from '@mui/material';
import ApiCuestionario from '../Api/Cuestionario'
import { Form, Formik } from "formik";
import { validationHombreMayor, validationMujer, validationMujerMayor, validationSimple } from "./ValidationForm.jsx"
import CustomRadioGroup from "../CustomFields/CustomRadioGroup";
import CustomRadio from "../CustomFields/CustomRadio";
import CustomSelect from "../CustomFields/CustomSelect";
import { CMB_MENOS_UNO, INTER_CONTRATANTE, TIPO_FLUJO } from '../Utils/Constantes';
import clsx from 'clsx';
import { pasoCuatro } from '../Utils/Cuestionario';
import { useEffect } from 'react';
import Loading from '../Commons/Loader';

export default function PasoCuatro(props) {
  const [reglaProstata, setReglaProstata] = useState(false);
  const [reglaRevisionM, setReglaRevisionM] = useState(false);
  const [reglaRevisionMS, setReglaRevisionMS] = useState(false);
  const [tipoFlujo, setTipoFlujo] = useState(0);
  const [openLoader, setOpenLoader] = useState(false);

  const handleOnChangeValue = (event, setFieldValue) => {
    const { target: { value, name } } = event;
    setFieldValue(name, value);
  };

  const handleOnChangeCombo = (event, setFieldValue) => {
    const { target: { value, name } } = event;
    setFieldValue(name, value);
  };

  const handleGuardar = (values, setSubmitting) => {
        window.dataLayer.push ({
          'event': 'form_step_8_submit'
        });

    let prevenciones = [];
    const idAsegurado = props.asegurado.id;
    let idContratante = null;
    if (window.sessionStorage.getItem(INTER_CONTRATANTE)) {
      const contratante = JSON.parse(sessionStorage.getItem(INTER_CONTRATANTE));
      idContratante = contratante.id
      window.dataLayer.push({
        event: 'medico_seguro_contratar_prevencion',
        no_cotizacion: contratante.folio,
        revision_medica: values.revisionGral == 0 ? 'No' : 'Si',
        cuando_fue: values.FechaG == -1 ? '' : values.FechaG,
        que_le_dijeron: values.resultadoG == -1 ? '' : values.resultadoG ,
        boton_contratar:'continuar',
      })
    }
    if(values.prostata === "1") {
      let prostata = {
        nombrePrevencion: "Prostata",
        Fecha: values.FechaP,
        resultado: values.resultadoP,
        id: values.idP,
      }
      prevenciones = prevenciones.concat(prostata);
    }
    if(values.revisionMujer === "1") {
      let revisionMujer = {
        nombrePrevencion: "Papanicolau",
        Fecha: values.FechaM,
        resultado: values.resultadoM,
        id: values.idM,
      }
      prevenciones = prevenciones.concat(revisionMujer);
    }
    if(values.revisionMujer2 === "1") {
      let revisionMujer2 = {
        nombrePrevencion: "Mastografía",
        Fecha: values.FechaMS,
        resultado: values.resultadoMS,
        id: values.idMS,
      }
      prevenciones = prevenciones.concat(revisionMujer2);
    }
    if(values.revisionGral === "1") {
      let revisionGral = {
        nombrePrevencion: "Revisión General",
        Fecha: values.FechaG,
        resultado: values.resultadoG,
        id: values.idG,
      }
      prevenciones = prevenciones.concat(revisionGral);
    }
    if(prevenciones.length > 0) {
      ApiCuestionario.guardaPrevenciones(prevenciones, idContratante, idAsegurado)
      .then(res => {
        setOpenLoader(false);
        setSubmitting(false);
        if(validaResponse(res)){
          if(res.data.prevencionYControl && res.data.prevencionYControl.length > 0) {
            props.asegurado.prevencionControl = res.data.prevencionYControl;
            props.actualizaContratante(props.asegurado);
          }
          props.siguienteAsegurado(props.asegurado.indice);
        } else {
          console.log("ERROR");
        }
      })
      .catch(error => {
        setOpenLoader(false);
        console.log(error);
        setSubmitting(false);
      });
    } else {
      setOpenLoader(false);
      setSubmitting(false);
      props.siguienteAsegurado(props.asegurado.indice);
    }
  };
  
  const reglaMuestraProstata = (pregunta) => {
    const edad = calcularEdad(new Date(props.asegurado.fechanacimiento));
    return (serviceToGeneroValue(props.asegurado.genero) === 0 && edad > 49 && pregunta.campo === "prostata");
  };
  
  const reglaMuestraColo = (pregunta) => {
    const edad = calcularEdad(new Date(props.asegurado.fechanacimiento));
    return (serviceToGeneroValue(props.asegurado.genero) === 1 && edad > 24 && pregunta.campo === "revisionMujer");
  };
  
  const reglaMuestraMas = (pregunta) => {
    const edad = calcularEdad(new Date(props.asegurado.fechanacimiento));
    return (serviceToGeneroValue(props.asegurado.genero) === 1 && edad > 39 && pregunta.campo === "revisionMujer2");
  };

  const existePCRegistrado = (nombrePrevencion) => {
    return props.asegurado && props.asegurado.prevencionControl && props.asegurado.prevencionControl.length > 0 
            && props.asegurado.prevencionControl.find(currentPC => currentPC.nombrePrevencion === nombrePrevencion) !== undefined;
  };

  const guardoPC = () => {
    return ( (props.asegurado.prevencionControl && props.asegurado.prevencionControl.length > 0) || props.registroPaso) ? "0" : null;
  };

  const getPrevencionControlReg = (nombrePrevencion) => {
      return props.asegurado.prevencionControl.find(currentPC => currentPC.nombrePrevencion === nombrePrevencion);
  };

  const getResultadoByPrevencionControl = (nombrePrevencion) => {
    return existePCRegistrado(nombrePrevencion) ? getPrevencionControlReg(nombrePrevencion).resultado : CMB_MENOS_UNO;
  };

  const getFechaByPrevencionControl = (nombrePrevencion) => {
    return existePCRegistrado(nombrePrevencion) ? getPrevencionControlReg(nombrePrevencion).fecha : CMB_MENOS_UNO;
  };

  const getIdByPrevencionControl = (nombrePrevencion) => {
    return existePCRegistrado(nombrePrevencion) ? getPrevencionControlReg(nombrePrevencion).id : null;
  };

  useEffect(() => {
    const edad = calcularEdad(new Date(props.asegurado.fechanacimiento));
    // && pregunta.campo === "revisionMujer"
    if((serviceToGeneroValue(props.asegurado.genero) === 0 && edad > 49)){
      setReglaProstata(true);
    } else {
      setReglaProstata(false);
    }
    // && pregunta.campo === "revisionMujer"
    if((serviceToGeneroValue(props.asegurado.genero) === 1 && edad > 24)){
      setReglaRevisionM(true);
    } else {
      setReglaRevisionM(false);
    }
    // && pregunta.campo === "revisionMujer2"
    if((serviceToGeneroValue(props.asegurado.genero) === 1 && edad > 39)){
      setReglaRevisionMS(true);
    } else {
      setReglaRevisionMS(false);
    }
    /* Especifica cómo sanear este efecto:
    return function cleanup() {
    };*/
  },[props.asegurado.fechanacimiento, props.asegurado.genero]);

  useEffect(() => {
        window.dataLayer.push ({
          'event': 'form_step_8_view'
        });
    if (window.sessionStorage.getItem(TIPO_FLUJO)) {
      setTipoFlujo(JSON.parse(sessionStorage.getItem(TIPO_FLUJO)));
    }
    /* Especifica cómo sanear este efecto:
    return function cleanup() {
    };*/
  }, []);
  
  return (
    <>
      <Accordion disabled={!props.disabled} square={true} expanded={props.disabled} className="accordion-w100">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="linea-amarilla">
              <img src={borderLineaDos} alt='' />
              <div className="titular">{ props.asegurado.titular ? "Titular" : "Asegurado adicional" }</div>
              <div className="nombre">{props.asegurado.nombre}</div>
            </div>
            <hr className="divider"/>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Formik validateOnChange={true} innerRef={props.formRef}
            initialValues={{
              prostata: (existePCRegistrado("Prostata") ? "1" : guardoPC()),
              resultadoP: (getResultadoByPrevencionControl("Prostata")),
              FechaP: (getFechaByPrevencionControl("Prostata")),
              idP: (getIdByPrevencionControl("Prostata")),
              revisionMujer: (existePCRegistrado("Papanicolau") ? "1" : guardoPC()),
              resultadoM: (getResultadoByPrevencionControl("Papanicolau")),
              FechaM: (getFechaByPrevencionControl("Papanicolau")),
              idM: (getIdByPrevencionControl("Papanicolau")),
              revisionMujer2: (existePCRegistrado("Mastografía") ? "1" : guardoPC()),
              resultadoMS: (getResultadoByPrevencionControl("Mastografía")),
              FechaMS: (getFechaByPrevencionControl("Mastografía")),
              idMS: (getIdByPrevencionControl("Mastografía")),
              revisionGral: (existePCRegistrado("Revisión General") ? "1" : guardoPC()),
              resultadoG: (getResultadoByPrevencionControl("Revisión General")),
              FechaG: (getFechaByPrevencionControl("Revisión General")),
              idG: (getIdByPrevencionControl("Revisión General")),
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              setOpenLoader(true);
              handleGuardar(values, setSubmitting);
            }}
            validateOnMount = {true}
            validationSchema={(reglaRevisionMS ? validationMujerMayor : 
              (reglaRevisionM ? validationMujer : 
                (reglaProstata ? validationHombreMayor : 
                  validationSimple)))}>
            {({ values, submitForm, setFieldValue, handleChange, errors, isSubmitting, setFieldError }) => (
              <Form>
                {pasoCuatro.map((pregunta, index) => (
                  <React.Fragment key={index}>
                    {(reglaMuestraProstata(pregunta) || reglaMuestraColo(pregunta) || reglaMuestraMas(pregunta) || pregunta.campo === "revisionGral") &&
                      <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} 
                          className={clsx({"mt-16": (index > 0)})}>
                          {
                            props.asegurado.titular && tipoFlujo !== 3 ? 
                            <span className='text-preguntas'>{pregunta.pregunta}</span>
                            :
                            <span className='text-preguntas'>{pregunta.preguntaAdicional}</span>
                          }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <CustomRadioGroup row name={pregunta.campo} 
                            onChange={(event) =>
                              handleOnChangeValue(event, setFieldValue)
                            }>
                            {pregunta.respuestas.map((res, jndex) => (
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} key={jndex}>
                                <CustomRadio name={pregunta.campo} type="radio" value={res.valor} label={res.respuesta}/>
                              </Grid>
                            ))}
                          </CustomRadioGroup>
                        </Grid>
                        {(values[pregunta.campo] === "1") &&
                          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}
                            className="mt-15">
                            {pregunta.preguntasDetalle.map((det, kndex) => (
                              <React.Fragment key={kndex}>
                                <Grid item xs={12} sm={12} 
                                  md={medidaGridByLength(pregunta.preguntasDetalle.length)} 
                                  lg={medidaGridByLength(pregunta.preguntasDetalle.length)} 
                                  xl={medidaGridByLength(pregunta.preguntasDetalle.length)}>
                                  {
                                    props.asegurado.titular && tipoFlujo !== 3 ? 
                                    <span className='text-preguntas'>{det.pregunta}</span>
                                    :
                                    <span className='text-preguntas'>{det.preguntaAdicional}</span>
                                  }
                                  {
                                    props.asegurado.titular && tipoFlujo !== 3 ? 
                                    <CustomSelect id={det.nombre+pregunta.identificador} name={det.nombre+pregunta.identificador} className="grid-item-select w-100"
                                      validate={validaCombo} onChange={(event) => handleOnChangeCombo(event, setFieldValue)} sx={{ mt: 1 }}>
                                      <MenuItem value={CMB_MENOS_UNO}>Seleccionar</MenuItem>
                                      {det.valores.map((valor, index) => (
                                        <MenuItem key={valor} value={valor}>{valor}</MenuItem>
                                      ))}
                                    </CustomSelect>
                                    :
                                    <CustomSelect id={det.nombre+pregunta.identificador} name={det.nombre+pregunta.identificador} className="grid-item-select w-100"
                                      validate={validaCombo} onChange={(event) => handleOnChangeCombo(event, setFieldValue)} sx={{ mt: 1 }}>
                                      <MenuItem value={CMB_MENOS_UNO}>Seleccionar</MenuItem>{det.valoresAdicional.map((valor, index) => (
                                        <MenuItem key={valor} value={valor}>{valor}</MenuItem>
                                      ))}
                                    </CustomSelect>
                                  }
                                </Grid>
                              </React.Fragment>
                            ))}
                          </Grid>
                        }
                      </>
                    }
                  </React.Fragment>
                ))}
                {props.muestraGuardar &&
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="w-100 mt-15">
                    <Button variant="outlined" disableElevation
                      className="boton-submit boton-form-seleccionado" onClick={submitForm}
                      > GUARDAR
                    </Button>
                  </Grid>
                }
              </Form>
            )}
          </Formik>
        </AccordionDetails>
      </Accordion>
      <Loading open={openLoader} onClose={() => setOpenLoader(false)}/>
    </>
  );

}