import AyudaModal from "./AyudaModal"
import { useLocation } from "react-router-dom"
import { INTER_CONTRATANTE } from "../../Utils/Constantes"
import ContactosModal from "./ContactosModal"

const ManejadorModal = (props) => {
  const location = useLocation()
  const contratante = JSON.parse(
    window.sessionStorage.getItem(INTER_CONTRATANTE)
  )
  const handleHelpModal =
    location.pathname === "/inicio" || contratante === null ? (
      <ContactosModal {...props} />
    ) : (
      <ContactosModal {...props} />
    )
  return handleHelpModal
}

export default ManejadorModal
