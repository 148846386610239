import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { EMBARAZO, INTER_CONTRATANTE, PERSONA_MAYOR, TIPO_FLUJO } from '../Utils/Constantes';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Api from '../Api/Api';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import * as React from 'react';
import FormDialogContactoMayorEdad from './FormDialogContactoMayorEdad';


export default function Perfilamiento(props) {
  const navigate = useNavigate();
  const [tipoFlujo, setTipoFlujo] = useState(0);
  const [openFormModal, setOpenFormModal] = React.useState(false);

  const handleSiMayor = () => {
    switch (tipoFlujo) {
      case 1:
        //para mi
        //sessionStorage.clear();
        //sessionStorage.setItem('nombre', props.datos.nombre);
        //navigate('/mayor');
        handleClickModal();
        break;
      case 2:
        //pm y am
        if (props.isTitular) {
          // sessionStorage.clear();
          // sessionStorage.setItem('nombre', props.datos.nombre);
          //navigate('/mayor');
          handleClickModal();
        } else {
          //se guardo en las exclusiones y puede seguir agregando más gente
          props.handleLimpiaAdvertencia();
          navigate('/datos/alguien-mas/true');
        }
        break;
      case 3:
        //am
        if (props.isTitular) {
          //sessionStorage.clear();
          //sessionStorage.setItem('nombre', props.datos.nombre);
          // navigate('/mayor');
          handleClickModal();
        } else {
          //se guardo en las exclusiones y puede seguir agregando más gente
          props.handleLimpiaAdvertencia();
          navigate('/datos/alguien-mas/agregar-mas/true');
        }
        break;
      default:
        break;
    }
  };

  const handleSiEmbarazada = () => {
    switch (tipoFlujo) {
      case 1:
        //para mi
        guardaInformacion();
        navigate('/cotizacion');
        break;
      case 2:
        //pm y am
        guardaInformacion();
        props.handleLimpiaAdvertencia();
        navigate('/datos/alguien-mas/true');
        break;
      case 3:
        //am
        guardaInformacion();
        props.handleLimpiaAdvertencia();
        navigate('/datos/alguien-mas/agregar-mas/true');
        break;
      default:
        break;
    }
  };

  const handleClickClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenFormModal(false);
    }
  };

  const handleClickModal = () => {
    handleClickOpen();
  }
  const handleClickOpen = () => {
    setOpenFormModal(true);
  };

  const guardaInformacion = () => {
    if (window.sessionStorage.getItem(INTER_CONTRATANTE)) {
      const contratante = JSON.parse(window.sessionStorage.getItem(INTER_CONTRATANTE));
      Api.actualizaContratante(contratante.id, contratante)
        .then(res => {
          Api.getContratante(contratante.folio)
            .then(res => {
              window.sessionStorage.setItem(INTER_CONTRATANTE, JSON.stringify(res.data.contratante));
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    setTipoFlujo(Number(window.sessionStorage.getItem(TIPO_FLUJO)));
    /* Especifica cómo sanear este efecto:
    return function cleanup() {
    };*/
  }, []);

  return (
    <>
      <Dialog open={openFormModal} onClose={(event, reason) => handleClickClose(event, reason)} disableEscapeKeyDown={true}
        maxWidth="sm" fullWidth={false}>
        <>
          {props.titulo && <DialogTitle>{props.titulo}</DialogTitle>}
          <DialogContent>
            <FormDialogContactoMayorEdad handleClose={handleClickClose} />
          </DialogContent>
        </>
      </Dialog>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} className="normalize-modal">
        {props.tipoAdvertencia === PERSONA_MAYOR &&
          <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100">
              <p className="hola-txt">{props.datos.nombre}</p><br />
              <p className="desc-txt">
                Nos duele decirte esto, por el momento no podemos cotizar un seguro m&eacute;dico  para personas mayores de 70 a&ntilde;os.<br /><br />
                No te desanimes, <b>¿quieres que te ayudemos a buscar otras opciones?</b></p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100 pt-24">
              <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} className="normalize-modal">
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="w-100">
                  <Button variant="outlined" disableElevation
                    className="boton-submit boton-form" onClick={props.handleSalir}>
                    A lo mejor despu&eacute;s
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className=" w-100">
                  <Button variant="outlined" disableElevation
                    className="boton-submit boton-form-seleccionado" onClick={handleSiMayor}>
                    S&iacute;, de una vez
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
        {props.tipoAdvertencia === EMBARAZO &&
          <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100">
              <p className="hola-txt">&iexcl;Prot&eacute;gete a ti y a tu beb&eacute;!</p><br />
              <p className="desc-txt">
                <b>Felicidades por esta gran noticia</b>. Aunque las <br />
                aseguradoras por el momento no pueden cubrir gastos de maternidad ni cualquier padecimiento de nacimiento del  beb&eacute;, <b>podemos formar parte de su futuro.</b><br /><br />
                <b>&iquest;Quieres asegurarte y asegurar a tu beb&eacute;?</b>
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100 pt-24">
              <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} className="normalize-modal">
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="w-100">
                  <Button variant="outlined" disableElevation
                    className="boton-submit boton-form" onClick={props.handleSalir}>
                    A lo mejor despu&eacute;s
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className=" w-100">
                  <Button variant="outlined" disableElevation
                    className="boton-submit boton-form-seleccionado" onClick={handleSiEmbarazada}>
                    S&iacute;, de una vez
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      </Grid>
    </>
  );
}