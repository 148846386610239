import * as react from "react"
import { Navigate } from "react-router-dom"
import Contratacion from "../../components/Contratacion/Contratacion"

const ContratacionPage = (props) => {
    const existDataSession =  sessionStorage.getItem("interContratante")? true : false
    return (
        existDataSession? <Contratacion isMobile={props.isMobile} /> : <Navigate to="/inicio?view=haveCotizacion" />
    )
}

export default ContratacionPage