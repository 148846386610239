import * as React from 'react';

///Material
import { Grid, Button } from "@mui/material";
import { Divider } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';

//CSS  Logos
import AxaSeguro from '../../assets/img/planesCotizacion/logo-axa.svg';
import GDSSeguro from '../../assets/img/planesCotizacion/logo-general-de-salud.svg';
import gnp from '../../assets/img/planesCotizacion/logo-gnp.svg';
import mapfre from '../../assets/img/planesCotizacion/logo-mapfre.svg';
import IconBack from '../../assets/img/planesCotizacion/Back.svg'
import Api from '../Api/Api';
import { useNavigate } from 'react-router-dom';

import './PlanesCotizacion.css'


export default function ComparacionPlanes(props) {
    const [dataObj, setdataObj] = React.useState(sessionStorage.getItem("comparacion"));
    const dataObjCon = JSON.parse(sessionStorage.getItem("interContratante"));
    const dataCotizacion = JSON.parse(sessionStorage.getItem("detalleCotizacion"));
    const [parsedItem, setparsedItem] = React.useState(JSON.parse(dataObj));
    const [detalles1, setdetalles1] = React.useState(parsedItem[0]['paquetes'][0]['detalles']);
    const [detalles2, setdetalles2] = React.useState(parsedItem[1]['paquetes'][0]['detalles']);
    const [sumaTotal1, setSumaTotal1] = React.useState(parsedItem[0].sumaTotal);
    const [sumaTotal2, setSumaTotal2] = React.useState(parsedItem[1].sumaTotal);
    const [aseguradora1, setAseguradora1] = React.useState(parsedItem[0].nombreAseguradora);
    const [aseguradora2, setAseguradora2] = React.useState(parsedItem[1].nombreAseguradora);
    const navigate = useNavigate();
    const cobertura = "";
    const [slctPLan, setSlctdPlan] = React.useState("");
    //const [cobertura, setCobertura] = React.useState('');
    const [planes, setPlanes] = React.useState(dataCotizacion.plan);
    const [dataPlanes, setDataPlanes] = React.useState([]);
    const [mostrarPersonalizar, setMostrarPersonalizar] = React.useState(false)

    const changeMostrarPersonalizar = () => {
        setMostrarPersonalizar(!mostrarPersonalizar)
    }

    function FormatNumber({ number }) {
        return (
            <span >
                {new Intl.NumberFormat("ES-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0
                }).format(number)}
            </span>
        );
    }

    const detalleSegunda = (nombre) => {
        const dataObj = sessionStorage.getItem("comparacion")
        let parsedItem = JSON.parse(dataObj)
        let detalles2 = parsedItem[1]['paquetes'][0]['detalles'];
        let c = '';
        c = detalles2.find(detalle => detalle.nombre === nombre);
        if (c !== undefined) {
            return formato(c.valor, nombre);
        } else {
            return 'N/A';
        }
    };
    const formato = (valor, nombre) => {
        if (nombre === "Coaseguro") {
            return ((Number.parseFloat(valor))*100) + '%';
        }
        if(nombre === "Beneficios Adicionales"){
            return valor
        }
        if (!isNaN(valor) && valor !== '') {
            return (
                new Intl.NumberFormat("ES-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0
                }).format(valor)
            );
        }
        if (typeof valor === 'string') {
            if (valor !== '') {
                return valor;
            } else {
                return "N/A";
            }

        }
    };

    const handleSelectPlanesChange = (event) => {
        setPlanes(event.target.value);
        getPlanesData(event.target.value);
    }
    var auxDataArr = [];

    const getPlanesData = async (nombrePlan) => {
        var response = await Api.getPaquetes(nombrePlan)
        auxDataArr = response.data
        const idContratante = dataObjCon.id
        var contadorElementos = 0;
        var auxArrComparar = [];
        auxDataArr.forEach((cadaAseguradora, idx) => {
            if (cadaAseguradora.paquetes.length > 0) {
                if (nombrePlan == 'Blindado' && cadaAseguradora.nombreAseguradora === 'GENERAL DE SEGUROS') {
                    auxDataArr.splice(idx, 1);
                }
            }

        });
        auxDataArr.forEach((cadaAseguradora, idx) => {
            if (cadaAseguradora.paquetes.length > 0) {
                const idAseguradora = cadaAseguradora.id
                const idPlan = cadaAseguradora.paquetes[0].id
                Api.getSumaAsegurada(idAseguradora, idPlan, idContratante)
                    .then(res2 => {
                        cadaAseguradora['sumaTotal'] = res2.data.total
                        contadorElementos++;
                        if (parsedItem[0].nombreAseguradora == cadaAseguradora.nombreAseguradora) {
                            auxArrComparar.push(cadaAseguradora);
                        }
                        if (parsedItem[1].nombreAseguradora == cadaAseguradora.nombreAseguradora) {
                            auxArrComparar.push(cadaAseguradora);
                        }
                        if (auxArrComparar.length > 1) {
                            let auxStr = "[" + JSON.stringify(auxArrComparar[0]) + "," + JSON.stringify(auxArrComparar[1]) + "]"
                            setdataObj(auxStr);
                            setparsedItem(JSON.parse(auxStr));
                            setdetalles1(auxArrComparar[0]['paquetes'][0]['detalles']);
                            setdetalles2(auxArrComparar[1]['paquetes'][0]['detalles']);
                            setSumaTotal1(auxArrComparar[0].sumaTotal);
                            setSumaTotal2(auxArrComparar[1].sumaTotal);
                            setAseguradora1(auxArrComparar[0].nombreAseguradora);
                            setAseguradora2(auxArrComparar[1].nombreAseguradora);
                        }
                        if (contadorElementos === auxDataArr.length) {
                            setDataPlanes(auxDataArr)
                        }

                    })
                    .catch(error => { console.error("Error on API getSumaAsegurada:", error.message) })
            }
        })
        //setTimeout(() => { seteoDatosArr(auxDataArr) }, 1000)
    }
    const handleClickLoQuiero = (data) => {
        let auxObj = {
            plan: planes,
            aseguradora: data.nombreAseguradora,
            idAseguradora: data.id,
            costo: data.sumaTotal,
            sumaAsegurada: data.paquetes[0].detalles.find(cadaDetalle => cadaDetalle.nombre === "Suma Asegurada") ? data.paquetes[0].detalles.find(cadaDetalle => cadaDetalle.nombre === "Suma Asegurada").valor : null,
            deducible: data.paquetes[0].detalles.find(cadaDetalle => cadaDetalle.nombre === "Deducible") ? data.paquetes[0].detalles.find(cadaDetalle => cadaDetalle.nombre === "Deducible").valor : null,
            coaseguro: data.paquetes[0].detalles.find(cadaDetalle => cadaDetalle.nombre === "Coaseguro") ? data.paquetes[0].detalles.find(cadaDetalle => cadaDetalle.nombre === "Coaseguro").valor : null,
            topeCoaseguro: data.paquetes[0].detalles.find(cadaDetalle => cadaDetalle.nombre === "Tope Coaseguro") ? data.paquetes[0].detalles.find(cadaDetalle => cadaDetalle.nombre === "Tope Coaseguro").valor : null
        }
        sessionStorage.setItem("detalleCotizacion", JSON.stringify(auxObj))
        navigate("/detalles-cotizacion")
    }

    React.useEffect(() => {
        setPlanes(planes);
    }, []);
    return (
        <>
            <div className="showOnlyOnMobile bg-comparacion-planes-mobile mb-4-mobile">
                <Grid className="pt-2">
                    <a href="/cotizacion" className=" regresar-opciones">
                        <img alt="icon-back" src={IconBack} className="img-goBack-mobile" />
                        Regresar al cotizador
                    </a>
                </Grid>
                <Grid container>
                    <Grid className="vl-amarilla-titulo-comparar">
                        <Divider orientation="vertical" flexItem />
                    </Grid>
                    <Grid item xs={9}>
                        <h4 className="titulo-compara-beneficios">Compara sus beneficios</h4>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid item xs={11} className="mb-4-mobile">
                            <FormControl sx={{ width: "100% " }} className="cont-inputs-comparar">
                                <TextField
                                    disabled
                                    value="Anual"
                                    label="Modalidad de pago"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={11} className="mb-4-mobile">
                            <FormControl sx={{ width: "100% " }} className="cont-inputs-comparar">
                                <InputLabel id="demo-simple-select-helper-label">Planes</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={planes}
                                    label="Age"
                                    onChange={handleSelectPlanesChange}
                                >
                                    <MenuItem value={"Esencial"}>Esencial</MenuItem>
                                    <MenuItem value={"Protegido"}>Protegido</MenuItem>
                                    {aseguradora1 !== 'GENERAL DE SEGUROS' && aseguradora2 !== 'GENERAL DE SEGUROS' &&
                                        <MenuItem value={"Blindado"}>Blindado</MenuItem>
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className="bg-white-comparacion-planes-mobile">
                    <Grid container justifyContent="center">
                        <Grid item xs={4} md={6}>
                            <Grid className="padding-img-aseguradora-comparacion">
                                <img alt="logo-aseguradora" src={aseguradora1 === 'MAPFRE' ? mapfre : aseguradora1 === 'AXA' ? AxaSeguro : aseguradora1 === 'GNP' ? gnp : GDSSeguro} className="img-aseguradoras" />
                            </Grid>
                            <Grid className="pl-5-mobile">
                                <a href="/cotizacion" className="color-azul-a">Cambiar</a>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            &nbsp;
                        </Grid>
                        <Grid item xs={4} md={6}>
                            <Grid className="padding-img-aseguradora-comparacion">
                                <img alt="logo-aseguradora" src={aseguradora2 === 'MAPFRE' ? mapfre : aseguradora2 === 'AXA' ? AxaSeguro : aseguradora2 === 'GNP' ? gnp : GDSSeguro} className="img-aseguradoras" />
                            </Grid>
                            <Grid className="pr-cambiar">
                                <a href="/cotizacion" className="color-azul-a">Cambiar</a>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider className="m-5-mobile" />
                    <Grid container>
                        <Grid item xs={5} md={0} className="pl-4-mobile">
                            <p className="txt-costo-anual-comparar">costo anual:</p>
                            <p className="txt-cifra-costo-anual-comparar"><FormatNumber number={sumaTotal1} /></p>
                            <Button className="bnt-loQuiero-comparar" onClick={() => handleClickLoQuiero(parsedItem[0])}>¡Lo quiero!</Button>
                        </Grid>
                        <Grid item xs={2} md={0}>
                            &nbsp;
                        </Grid>
                        <Grid item xs={5} md={0}>
                            <p className="txt-costo-anual-comparar pr-costo-n">costo anual:</p>
                            <p className="txt-cifra-costo-anual-comparar"><FormatNumber number={sumaTotal2} /></p>
                            <Button className="bnt-loQuiero-comparar" onClick={() => handleClickLoQuiero(parsedItem[1])}>¡Lo quiero!</Button>
                        </Grid>
                    </Grid>
                    <Divider className="m-5-mobile" />
                    {detalles1.map((eachContent, i) => (
                        <>
                            <Grid container key={eachContent.id}>
                                <Grid item xs={12}>
                                    <p className="titulo-comparar-beneficios-aeguradoras">{eachContent.nombre}</p>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <p className="txt-comparar-cifras-beneficios align-left">{formato(eachContent.valor, eachContent.nombre)}</p>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p className="p-comparar-beneficios-aseguradoras">{eachContent.descripcion}</p>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <p className="txt-comparar-cifras-beneficios align-right">{detalleSegunda(eachContent.nombre)}</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider />
                        </>
                    ))}
                </Grid>
            </div>
        </>
    )

}