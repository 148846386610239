import './App.css';
import { useEffect, useState } from 'react';
import useWindowSize from './components/Utils/useWindowSize';
import Header from './components/Commons/Header';
import Footer from './components/Commons/Footer';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Pages from './components/Config/RoutesConf';
import EventEmitter from './components/services/EventEmitter'

const theme = createTheme({
  palette: {
    primary: {
      light: '#0066ff',
      main: '#039ECC',
      contrastText: '#FFFFFF'
    },
    error: {
      main: '#CD0505',
    },
    warning: {
      main: '#FFD60E',
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: 'Montserrat',
    button: {
      fontWeight: 600
    },

  },
  // breakpoints: {
  //   values: {
  //     xs: 360,
  //     sm: 390,
  //     md: 425,
  //     lg: 768,
  //     xl: 1024,
  //   },
  // },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

function App() {
  const[isMobile, setIsMobile] = useState((window.innerWidth < 1025)? true : false);
  const size = useWindowSize();
  useEffect(() => {
    setIsMobile((size.width < 1023)? true : false);
  }, [size]);

  useEffect(() => {
    return () => {
      EventEmitter.removeAllListeners()
    }
  }, [])
  

  return (
    <div style={{ width: '100%', position: 'relative', overflow:'hidden' }}>
      <ThemeProvider theme={theme}>
          <Router>
            <Header isMobile={isMobile} isFromInicio={false}></Header>
            <Pages isMobile={isMobile}/>
            <Footer isMobile={isMobile}></Footer>
          </Router>

      </ThemeProvider>
    </div>
  );
}

export default App;
