const styles = {
    closeIcon: {
        position: 'absolute',
        right: 12,
        top: 10,
        color: (theme) => theme.palette.grey[400],
    },
    title: {
        fontWeight: 'bold'
    },
    contentQr: {
        textAlign: 'center',
        'img': { width: 200 }
    },
    btn: { width: '100%' }
}

export default styles