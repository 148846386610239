import Typography from '@mui/material/Typography';
import { Grid, Box, Paper, Divider, Button } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import IconAmbulancia from "../../assets/landing/icon-ambulancia.svg"
import IconAuxiliares from "../../assets/landing/icon-auxiliares_diagnostico.svg";
import IconHospitalizacion from "../../assets/landing/icon-hospitalizacion.svg";
import IconMedicamentos from "../../assets/landing/icon-medicamentos.svg";
import IconMedicos from "../../assets/landing/icon-medicos.svg";
import IconProtesis from "../../assets/landing/icon-protesis.svg"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useMediaQuery, useTheme } from '@mui/material';

const PlanDetailsGridLoader = (props) => {
    const { breakpoints } = useTheme();
    const isTablet = useMediaQuery(breakpoints.down('md'));
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const detailHospitalization = props.typeHospitalization === 'Esencial' ? 'básica.' : props.typeHospitalization === 'Protegido' ? 'intermedia.' : 'alta.'

    const borders = [1, 2, 4, 5]

    const gridCells = [
        { icon: IconHospitalizacion, titulo: "Hospitalización", detalle: 'Te incluye el costo de la habitación y los servicios médicos necesarios.', detalleBold: `En una red hospitalaria de tecnología ${detailHospitalization}` },
        { icon: IconMedicos, titulo: "Médicos", detalle: "Cubrirá los honorarios de los cirujanos, anestesistas, enfermeras, ayudantes, entre otros." },
        { icon: IconAuxiliares, titulo: "Auxiliares de diagnóstico", detalle: "Estudios de laboratorio, resonanicas magnéticas, estudios de sangre y todos aquellos que permitan identificar el accidente o la enfermedad." },
        { icon: IconMedicamentos, titulo: "Medicamentos", detalle: "Siempre y cuando sean para tratar tu accidente o enfermedad." },
        { icon: IconAmbulancia, titulo: "Ambulancia", detalle: "En caso de una emergencia médica, te brindará el apoyo para el traslado y atención." },
        { icon: IconProtesis, titulo: "Prótesis y aparatos ortopédicos", detalle: 'Sí a causa de una enfermedad o accidente necesitas uno, tu seguro lo cubre.' },
    ]

    return (
        <Box>
            <Grid container width={'90%'} sx={{margin: '0 auto'}}>
                {gridCells.map((eachCell, idx) => (
                    <Grid item sm={4} >
                        <Box 
                            height={ isMobile ? 'auto' : isTablet ? '275px' : '224px'}
                        >
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                flexDirection={'column'}
                                height={ isMobile ? 'auto' : '164px'}
                                borderLeft = {borders.includes(idx) && !isMobile ? '1px solid #C7C7C7' : ''}
                                marginTop={isMobile ? '24px' : '0px'}
                            >
                                <Box>
                                    <img width={"24px"} height={"21px"} src={eachCell.icon} alt="icon-hospitalizacion" />
                                </Box>
                                <Box sx={{textAlign:"center", display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center'}}>
                                    <Typography sx={{
                                        fontFamily: 'Montserrat',
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        fontSize: "14px",
                                        lineHeight: "24px",
                                        width: isTablet ? '80%' : '100%',
                                        textAlign: "center",
                                    }}>{eachCell.titulo}</Typography>
                                    <Typography sx={{ 
                                        padding: !isMobile && isTablet ? '10px' : "10px 15px", 
                                        textAlign: "center",
                                        fontFamily: 'Montserrat',
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                    }}
                                    >
                                        { eachCell.detalle }
                                        { eachCell.detalleBold ? <strong> {eachCell.detalleBold} </strong> : '' }
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                ))}
                    
                <Divider orientation="vertical" flexItem />

            </Grid>
        </Box>
    )
}

const SlidesLoader = (props) => {
    const { breakpoints } = useTheme();
    const isTablet = useMediaQuery(breakpoints.down('md'));
    const isMobile = useMediaQuery(breakpoints.down('sm'));

    return (
        <Paper 
            sx={{ 
                border: '1px solid #C0E6F2', 
                boxShadow: '4px 6px 16px rgba(33, 33, 33, 0.05)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                alignContent: 'center'
            }}
        >
            <Typography sx={{
                fontFamily: 'Montserrat',
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "22px",
                lineHeight: "28px",
                letterSpacing: "0.25px",
                color: "#212121",
                marginTop: "24px"
            }}>
                {props.item.plan}
            </Typography>
            <Typography sx={{
                fontFamily: 'Montserrat',
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "20px",
                textAlign: "center",
                padding: isMobile && "0px 5%"
            }}>
                <Box sx={{ marginBottom: "15px" }}>
                    Te incluye una suma asegurada de hasta
                    <br />
                    <b>{props.item.sumaAsegurada}</b>
                </Box>
            </Typography>
            {!isMobile && <Box sx={{ borderTop: '1px solid #C7C7C7', margin: '0px 0px 30px', width: '85%' }} /> }

            <PlanDetailsGridLoader
                typeHospitalization={props.item.plan}
            />

            <Button onClick={props.handleScrollToCotizacion} variant="contained" sx={{
                height: "48px",
                maxWidth: "382px",
                borderRadius: "10px",
                padding: isMobile ? "16px 55px" : isTablet ? '16px 75px' : '16px 100px',
                backgroundColor: "#039ECC",
                color: "#fff",
                marginBottom: '25px',
                marginTop: isMobile ? '13px' : isTablet ? '-55px' : '-30px'
            }}>
                Quiero cotizar
            </Button>
        </Paper>
    )
}

const PlansSlider = ({clickPlanesAction, handleScrollToCotizacion}) => {
    const { breakpoints } = useTheme();
    const isTablet = useMediaQuery(breakpoints.down('md'));
    const isMobile = useMediaQuery(breakpoints.down('sm'));

    const items = [
        {
            plan: "Esencial",
            sumaAsegurada: "$6,950,000",
        },
        {
            plan: "Protegido",
            sumaAsegurada: "$25,000,000",
        },
        {
            plan: "Blindado",
            sumaAsegurada: "$130,000,000",
        },
    ]

    return (
        <Paper sx={{ textAlign: "center", padding: isMobile ? '5%' : isTablet ? '8%' : ' 6% 10%' }}>
            <Typography variant="h1" sx={{
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "28px",
                lineHeight: "34px",
                textAlign: "center",
                color: "#212121"
            }}>¡Elige el plan que más te convenga!
            </Typography>
            <Carousel
                sx={{
                    height: 'fit-content',
                    padding: isMobile ? '5VH 6VW' : '5vh 4vw',
                }}
                autoPlay={false}
                indicators={false}
                navButtonsAlwaysVisible
                cycleNavigation={false}
                NextIcon={<ArrowForwardIosIcon onClick={isMobile && clickPlanesAction} />}
                PrevIcon={<ArrowBackIosNewIcon onClick={isMobile && clickPlanesAction} />}
                navButtonsProps={{
                    style: {
                        backgroundColor: 'transparent',
                        color: '#039ECC',
                        margin: isMobile ?  "0px -10.3px" : isTablet ? '0px -8px' : '0px 10px',
                        zIndex: 99
                    }
                }}
            >
                {items.map((item, i) => <SlidesLoader key={i} item={item} clickPlanesAction={clickPlanesAction} handleScrollToCotizacion={handleScrollToCotizacion} />)}
            </Carousel>
        </Paper>
    )
}

export default PlansSlider