import { Snackbar, Alert } from "@mui/material"

export const AlertSnackBar = (props) => {
    const { open, handleAlertClose, severity, message } = props;

    return (
        <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={5000}
        onClose={handleAlertClose}
        >
            <Alert severity={severity} onClose={handleAlertClose}>
                {message}
            </Alert>
        </Snackbar>
    )
}