import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import { useEffect } from "react";
import { Box } from '@mui/material';

export default function Bienvenida(props) {

  useEffect(() => {
    window.dataLayer.push({ 
     'event': "form_step_1_view"
    });
  }, []);

  return (
    <Box className='box-bienvenida'>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} className="normalize-modal">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100">
          <p className="hola-txt">&iexcl;Hola {props.datos.nombre}!</p><br/>
          <p className="desc-txt">
            Platicanos, <b>¿para quién es el seguro m&eacute;dico que estás buscando?</b>
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100 pt-24">
          <Button variant="outlined" disableElevation 
            className={clsx({"boton-form-seleccionado": (props.tipoFlujo === 1)}, {"boton-para w-100":true})}
            onClick={(event) => props.handleContinuar(1)}>
            Para m&iacute;
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100 pt-24">
          <Button variant="outlined" disableElevation
            className={clsx({"boton-form-seleccionado": (props.tipoFlujo === 2)}, {"boton-para w-100":true})}
            onClick={(event) => props.handleContinuar(2)}>
            Para m&iacute; y mi familia
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100 pt-24">
          <Button variant="outlined" disableElevation
            className={clsx({"boton-form-seleccionado": (props.tipoFlujo === 3)}, {"boton-para w-100":true})}
            onClick={(event) => props.handleContinuar(3)}>
            Para alguien m&aacute;s
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}