
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useEffect, useState, useRef } from 'react';
import Landing1 from '../../assets/landing/Landing-gmm1.png';
import Landing2 from '../../assets/landing/Landing-gmm2.png';
import Landing3 from '../../assets/landing/Landing-gmm3.png';
import Bienvenida from '../Perfilamiento/Bienvenida';
import { ALGUIEN_MAS, BIENVENIDA, DATOS, EMBARAZO, INTER_CONTRATANTE, PERSONA_MAYOR, SOLO_AM, SOLO_AM_AGREGAR, TIPO_FLUJO } from '../Utils/Constantes';
import Datos from '../Perfilamiento/Datos';
import Advertencias from '../Advertencias/Advertencias'
import { useNavigate } from 'react-router-dom';
import Inicio from '../AgregarMas/Inicio';
import SoloAM from '../SoloAlguienMas/SoloAM';
import { Divider, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function Wrapper(props) {
  const navigate = useNavigate();
  const [datos, setDatos] = useState({});
  const [tipoFlujo, setTipoFlujo] = useState(0);
  const [isAdvertencia, setAdvertencia] = useState(false);
  const [isTitular, setTitular] = useState(false);
  const [tipoAdvertencia, setTipoAdvertencia] = useState(null);
  const regresarRef = useRef();

  const handleContinuarBienvenida = (tipoFlujo) => {
    const contratanteCurrent = JSON.parse(sessionStorage.getItem(INTER_CONTRATANTE));
    setDatos(contratanteCurrent);
    contratanteCurrent.tipoFlujo = tipoFlujo;
    sessionStorage.setItem(INTER_CONTRATANTE, JSON.stringify(contratanteCurrent));
    setTipoFlujo(tipoFlujo);
    sessionStorage.setItem(TIPO_FLUJO, tipoFlujo);
    window.dataLayer.push ({
      'event': 'form_step_1_submit',
      'clic': tipoFlujo
    });
    window.dataLayer.push({
      event: "medico_seguro_cobertura",
      boton_cobertura: tipoFlujo === 1 ? "Seguro para mí" : tipoFlujo === 2 ? "Seguro para mí y mi familia" : "Para alguien más",
    })
    if (tipoFlujo === 3) {
      //am
      navigate('/datos/solo-alguien-mas');
    } else {
      navigate('/datos-personales');
    }
  };

  const handleRegresarDeDatos = () => {
    setTipoFlujo(0);
    sessionStorage.setItem(TIPO_FLUJO, 0);
    navigate('/bienvenida');
  };

  const handleRegresarDeAlguienMas = () => {
    let tipoFlujo = JSON.parse(sessionStorage.getItem(TIPO_FLUJO));
    if (tipoFlujo === 2) {
      navigate('/datos-personales');
    } else {
      navigate('/datos/solo-alguien-mas');
    }
  };

  const handleRegresarDeAlguienMasRef = () => {
    if (regresarRef.current) {
      regresarRef.current.click();
    } else {
      let tipoFlujo = JSON.parse(sessionStorage.getItem(TIPO_FLUJO));
      if (tipoFlujo === 2) {
        navigate('/datos-personales');
      } else {
        navigate('/datos/solo-alguien-mas');
      }
    }
  };

  const handleRegresarDeSoloAlguienMas = () => {
    setTipoFlujo(0);
    sessionStorage.setItem(TIPO_FLUJO, 0);
    navigate('/');
  };

  const handleRegresarDeSoloAlguienMasRef = () => {
    if (regresarRef.current) {
      regresarRef.current.click();
    } else {
      setTipoFlujo(0);
      sessionStorage.setItem(TIPO_FLUJO, 0);
      navigate('/');
    }
  };

  const handleAdvertencias = (tipo, titular) => {
    switch (tipo) {
      case 1:// H y M para mi persona mayor
        //setTipoFlujo(0);
        //sessionStorage.setItem(TIPO_FLUJO,0);
        setTitular(titular);
        setAdvertencia(true);
        setTipoAdvertencia(PERSONA_MAYOR);
        break;
      case 2:// M para mi embarazo
        //setTipoFlujo(0);
        //sessionStorage.setItem(TIPO_FLUJO,0);
        setTitular(titular);
        setAdvertencia(true);
        setTipoAdvertencia(EMBARAZO);
        break;

      default:
        break;
    }
  };

  const handleLimpiaAdvertencia = () => {
    setAdvertencia(false);
    setTipoAdvertencia(null);
  };

  const handleSalir = () => {
    sessionStorage.clear();
    navigate('/inicio');
  };

  useEffect(() => {
    if (window.sessionStorage.getItem(INTER_CONTRATANTE)) {
      const contratanteLocal = JSON.parse(sessionStorage.getItem(INTER_CONTRATANTE));
      setDatos(contratanteLocal);
      setTipoFlujo(contratanteLocal.tipoFlujo);
      sessionStorage.setItem(TIPO_FLUJO, contratanteLocal.tipoFlujo);
    } else {
      navigate('/inicio');
    }
  }, []);
  
  return (
    <div className="container-wrapper">
      <Container maxWidth="xl">
        <Box className='mX-30'>
          <Grid container direction="row" justifyContent="center" alignItems="center"
            sx={{ minHeight: '100%', borderRadius: '8px', backgroundColor: '#FFFFFF' }}>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5} >
              { props.isMobile &&
                <>
                  {( props.componente === DATOS && isAdvertencia === false) &&
                      <IconButton className="back-button" onClick={() => handleRegresarDeDatos()}>
                        <ArrowBackIcon fontSize='large'/>
                      </IconButton>
                  }
                  {(props.componente === ALGUIEN_MAS && isAdvertencia === false) &&
                      <IconButton className="back-button" onClick={() => handleRegresarDeAlguienMasRef()}>
                        <ArrowBackIcon fontSize='large'/>
                      </IconButton>
                  }
                  {(props.componente === SOLO_AM && isAdvertencia === false) &&
                      <IconButton className="back-button" onClick={() => handleRegresarDeSoloAlguienMasRef()}>
                        <ArrowBackIcon fontSize='large'/>
                      </IconButton>
                  }
                  {(props.componente === SOLO_AM_AGREGAR && isAdvertencia === false) &&
                      <IconButton className="back-button" onClick={() => handleRegresarDeAlguienMasRef()}>
                        <ArrowBackIcon fontSize='large'/>
                      </IconButton>
                  }
                </>
              }
              <div className="imagen-flujo">
                {props.componente === BIENVENIDA &&
                  <img src={Landing1} alt='' />
                }
                {(props.componente !== BIENVENIDA && !isAdvertencia) &&
                  <img src={Landing2} alt='' />
                }
                {isAdvertencia &&
                  <img src={Landing3} alt='' />
                }
              </div>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem className="vertical-divider"/>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
              <Box className='box-perfilamiento'>
                {(props.componente === BIENVENIDA && isAdvertencia === false) &&
                  <Bienvenida datos={datos} handleContinuar={handleContinuarBienvenida} tipoFlujo={tipoFlujo} 
                    isMobile={props.isMobile}/>
                }
                {(props.componente === DATOS && isAdvertencia === false) &&
                  <Datos datos={datos} handleAdvertencias={handleAdvertencias} handleSalir={handleSalir}
                    tipoFlujo={tipoFlujo} handleRegresar={handleRegresarDeDatos} isMobile={props.isMobile}/>
                }
                {(props.componente === ALGUIEN_MAS && isAdvertencia === false) &&
                  <Inicio datos={datos} handleAdvertencias={handleAdvertencias} handleSalir={handleSalir}
                    tipoFlujo={tipoFlujo} handleRegresar={handleRegresarDeAlguienMas} isMobile={props.isMobile}
                      regresarRef={regresarRef}/>
                }
                {(props.componente === SOLO_AM && isAdvertencia === false) &&
                  <SoloAM datos={datos} handleAdvertencias={handleAdvertencias} handleSalir={handleSalir}
                    tipoFlujo={tipoFlujo} handleRegresar={handleRegresarDeSoloAlguienMas} isMobile={props.isMobile}
                      regresarRef={regresarRef}/>
                }
                {(props.componente === SOLO_AM_AGREGAR && isAdvertencia === false) &&
                  <Inicio datos={datos} handleAdvertencias={handleAdvertencias} handleSalir={handleSalir}
                    tipoFlujo={tipoFlujo} handleRegresar={handleRegresarDeAlguienMas} isMobile={props.isMobile}
                      regresarRef={regresarRef}/>
                }
                {isAdvertencia &&
                  <Advertencias datos={datos} tipoAdvertencia={tipoAdvertencia} tipoFlujo={tipoFlujo}
                    isTitular={isTitular} handleLimpiaAdvertencia={handleLimpiaAdvertencia} handleSalir={handleSalir} 
                      isMobile={props.isMobile}/>
                }
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}