import { Box, Grid } from '@mui/material'
import MantenimientoImg from '../../assets/img/Mantenimiento-desk.png'

const styles = {
    center: {
        width: '100%',
        textAlign: 'center'
    },
    "mb-1": {
        margin: 0,
        marginBottom: '1rem'
    },
    "mb-2": {
        margin: 0,
        marginBottom: '2rem'
    },
    "mt-2": {
        margin: 0,
        marginTop: '2rem'
    },
    mantenimientoImage : {
        width: '100%',
        objectFit: 'cover'
    },
    subtitle: {
        fontWeight: '400'
    },
    subtitle2: {
        fontWeight: 'bold'
    },
    "p-1": {
        padding: '1rem'
    }
}

const Mantenimiento = () => {

    const title = "¡No eres tú, somos nosotros!";
    const subtitle = "El sitio está en mantenimiento, pero pronto estaremos de vuelta con una mejor experiencia.";
    const subtitle2 = "Si quieres información de Médico Seguro te podemos ayudar en este teléfono: 55442 46837";

    return(
        <Box style={{ ...styles['mt-2'], ...styles['p-1']}}>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <h1 style={{ ...styles.center, ...styles['mb-1'] }}>{title}</h1>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <h3 style={{ ...styles.center, ...styles['mb-2'], ...styles.subtitle }}>{subtitle}</h3>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <h3 style={{ ...styles.center, ...styles['mb-2'], ...styles.subtitle2 }}>{subtitle2}</h3>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles['mb-2']} >
                    <img src={MantenimientoImg} alt="mantenimiento" style={styles.mantenimientoImage} />
                </Grid>
            </Grid>
        </Box>
    );
}

export default Mantenimiento;