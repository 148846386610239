import * as React from 'react'

import '../PlanesCotizacion/PlanesCotizacion.css'
/*IMG*/
import IconNC from "../../assets/img/planesCotizacion/nueva-cotizacion.png";
import IconEC from "../../assets/img/planesCotizacion/sobreEC.svg";
import ArrowDownIcon from '../../assets/img/planesCotizacion/arrowDownIcon.svg';
import IconEditar from '../../assets/img/planesCotizacion/Lapiz-edicion.svg';
import TooltipIcon from '../../assets/img/planesCotizacion/TooltipIsolated.svg';
import DeleteIcon from '@mui/icons-material/Delete';

/*Mui Imports*/
import { Divider, Grid, Stack, Box, Button, Tooltip } from '@mui/material'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import CloseIcon from '@mui/icons-material/Close';

/*Components imports*/
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom';
import { INTER_CONTRATANTE } from '../Utils/Constantes';
import * as Utils from '../Utils/Utils'

import EditarPerfilCotizadorModal from './EditarPerfilCotizadorModal';

/*API*/
import Api from '../Api/Api';
import ApiBearer from '../Api/ApiWithBearerToken'


export default function CabeceraContratante(props) {
    /* Depuracion datos, titular o contratante */
    var dataObj = JSON.parse(sessionStorage.getItem("interContratante"))
    const dataTitular = dataObj.asegurados.find(asegurado => asegurado.titular === true);
    const nombreAusar = dataObj.tipoFlujo === 3 ? dataTitular.nombre : dataObj.nombre;
    var cadenaPreParseada = ""
    if (dataObj.tipoFlujo === 3) {
        cadenaPreParseada = dataTitular.fechanacimiento != null ? format(Date.parse(dataTitular.fechanacimiento), 'dd/MM/yyyy') : ""
    } else {
        cadenaPreParseada = dataObj.fechanacimiento != null ? format(Date.parse(dataObj.fechanacimiento), 'dd/MM/yyyy') : ""
    }
    const dateOfBirht = cadenaPreParseada;
    const gender = dataObj.tipoFlujo === 3 ? dataTitular.genero : dataObj.genero;

    /* Estados */
    const [name, setName] = React.useState(nombreAusar);
    const [phone, setPhone] = React.useState(dataObj.telefono);
    const [email, setEmail] = React.useState(dataObj.correo);
    const [selectedPlan, setSelectedPlan] = React.useState("Esencial");
    const [openModalNC, setOpenModalNC] = React.useState(false);
    const [openModalEC, setOpenModalEC] = React.useState(false)
    const [arrAsegurados, setArrAsegurados] = React.useState(dataObj.asegurados)
    const [mostrarAsegurados, setMostrarAsegurados] = React.useState(false)
    const [idxAseguradoEdit, setIdxAseguradoEdit] = React.useState(null)
    const [nombreAseguradoEdicionModal, setNombreAseguradoEdicionModal] = React.useState()
    const [openModalADC, setOpenModalADC] = React.useState(false);
    const [correoEnvioCotizacion, setCorreoEnvioCotizacion] = React.useState(dataObj.correo)

    const handleOpenModalEC = () => setOpenModalEC(true);
    const handleCloseModalEC = () => setOpenModalEC(false);
    const handleOpenModalNC = () => setOpenModalNC(true);
    const handleCloseModalNC = () => setOpenModalNC(false);
    const navigate = useNavigate();
    /* Const Funciones */
    const actualizarDatos = (update) => {
        setPhone(update.telefono);
        setEmail(update.correo)
        setName(update.nombre)
    }
    const handleSelectPlanesChange = (event) => {
        setSelectedPlan(event.target.value)
        props.cambiarPlan(event.target.value)
        //console.log(props.planesData)
    }
    const clickAceptarNuevaCotizacion = () => {
        sessionStorage.clear()
        navigate("/")
    }

    const handleClickDropdownAsegurados = () => {
        setMostrarAsegurados(!mostrarAsegurados)
    }
    const handleOpenModalADC = (idxAsegurado) => {
        setIdxAseguradoEdit(idxAsegurado)
        setNombreAseguradoEdicionModal(arrAsegurados[idxAsegurado].nombre)
        setOpenModalADC(true)

    };
    const handleDeleteAsegurado = (cadaAsegurado) => {
        Api.eliminarAsegurado(dataObj.id, cadaAsegurado.id)
            .then(res => {
                Api.getContratante(dataObj.folio)
                    .then(res => {
                        const contratanteResponse = res.data.contratante;
                        window.sessionStorage.setItem(INTER_CONTRATANTE, JSON.stringify(contratanteResponse));
                        setArrAsegurados(contratanteResponse.asegurados);
                        props.recargarDatos(selectedPlan)
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                console.log(error);
            });
    }

    const editAseguradoNombre = (event) => {
        setNombreAseguradoEdicionModal(event.target.value)
    }
    const handleCloseModalADC = () => setOpenModalADC(false);
    const handleGuardarVentanaEdicionASegurado = () => {
        setArrAsegurados([...arrAsegurados, arrAsegurados[idxAseguradoEdit].nombre = nombreAseguradoEdicionModal])
        handleCloseModalADC()
    }
    const handleChangeCorreoEnvioCotizacion = (e) => {
        setCorreoEnvioCotizacion(e.target.value)
    }

    const evaluacionCoberturas = (objeto, nombreProp, valorVacio, nombreAseguradora) => {
        if (nombreProp === "Coaseguro" && nombreAseguradora !== "GENERAL DE SEGUROS") {
            return objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp) ? (objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).valor * 100) + "% " + objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).etiquetavalor.toLowerCase() : valorVacio

            // }
            //  else if (nombreProp === "Coaseguro" && nombreAseguradora == "AXA") {
            //     return objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp) ? new Intl.NumberFormat("ES-MX", { style: "currency", currency: "MXN", minimumFractionDigits: 0 }).format(objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).valor) + " " + objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).etiquetavalor : valorVacio
        }else if (nombreProp === "Coaseguro" && nombreAseguradora === "GENERAL DE SEGUROS") {
            return objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp) ? (objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).valor * 100) + "% ": valorVacio

            // }
            //  else if (nombreProp === "Coaseguro" && nombreAseguradora == "AXA") {
            //     return objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp) ? new Intl.NumberFormat("ES-MX", { style: "currency", currency: "MXN", minimumFractionDigits: 0 }).format(objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).valor) + " " + objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).etiquetavalor : valorVacio
        } else {
            return objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp) ? new Intl.NumberFormat("ES-MX", { style: "currency", currency: "MXN", minimumFractionDigits: 0 }).format(objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).valor) : valorVacio
        }
    }
    const evaluacionBeneficiosAdicionales = (objeto, nombreAseguradora) => {
        let contadorBeneficios = 0;
        objeto.forEach(cadaDetalle => {
            if (cadaDetalle.agrupador === "PLUSADICIONAL") {
                contadorBeneficios++
            }

        })

        return contadorBeneficios
    }

    const enviarCotizacion = () => {
        let dateOfBirthParsed = new Date(dataObj.fechanacimiento);
        let cp = dataObj.cp;
        let dateString = new Date().toLocaleDateString("es-MX");

        if (dataObj.tipoFlujo === 3){
            dateOfBirthParsed = new Date(dataTitular.fechanacimiento);
            cp = dataTitular.cp;
        }

        let edad = Utils.calcularEdad(dateOfBirthParsed);

        let dataApi = {
            "from": correoEnvioCotizacion,
            "subject": "Cotizacion ",
            "body": "html",
            "template_id": "iquwehiqhjbwdiqhi234iuh23i4",
            "template_data": {
                "inter_whatsapp": "5544471110",
                "inter_phone": "5544471110",
                "inter_mail": correoEnvioCotizacion,
                "first_name": name,
                "quote_number": dataObj.folio,
                "quote_ends": dateString,
                "quote_holder": name,
                "quote_coverage": selectedPlan,
                "quote_url": "http://ekjemplo.com/pdf.pdf"
            },
            "data": {
                "firtname": name,
                "edad": edad,
                "gender": gender,
                "cod": cp,
                "secondname": " ",
                "firtlastname": " ",
                "secondlastname": " ",
                "adicionales": (arrAsegurados.length -1),
                "iniciovigencia": dateString,
                "finvigencia": dateString,
                "quote_id": dataObj.folio,
                "costoTotalgeneral": "",
                "sumageneral": "",
                "deduciblegeneral": "",
                "coasegurogeneral": "",
                "topecoasegurogeneral": "",
                "maternidadgeneral": "",
                "adicionalgeneral": "",
                "costoTotalgnp": "",
                "sumagnp": "",
                "deduciblegnp": "",
                "coasegurognp": "",
                "topecoasegurognp": "",
                "maternidadgnp": "",
                "adicionalgnp": "",
                "costoTotalAxxa": "",
                "sumaAxxa": "",
                "deducibleAxxa": "",
                "coaseguroAxxa": "",
                "topecoaseguroAxxa": "",
                "maternidadAxxa": "",
                "adicionalAxxa": "",
                "costoTotalMapfre": "",
                "sumaMapfre": "",
                "deducibleMapfre": "",
                "coaseguroMapfre": "",
                "topecoaseguroMapfre": "",
                "maternidadMapfre": "",
                "adicionalMapfre": ""
            }
        }
        let arrDataPlanes = props.planesData
        arrDataPlanes.forEach(cadaAseguradoraData => {
            let auxiliarContBeneficios = evaluacionBeneficiosAdicionales(cadaAseguradoraData.paquetes[0].detalles, cadaAseguradoraData.nombreAseguradora)
            switch (cadaAseguradoraData.nombreAseguradora) {
                case "GENERAL DE SEGUROS":
                    dataApi.data.costoTotalgeneral = new Intl.NumberFormat("ES-MX", { style: "currency", currency: "MXN", minimumFractionDigits: 0 }).format(cadaAseguradoraData.sumaTotal)
                    dataApi.data.sumageneral = evaluacionCoberturas(cadaAseguradoraData.paquetes[0].detalles, "Suma Asegurada", "No incluido")
                    dataApi.data.deduciblegeneral = evaluacionCoberturas(cadaAseguradoraData.paquetes[0].detalles, "Deducible", "Sin deducible")
                    dataApi.data.coasegurogeneral = evaluacionCoberturas(cadaAseguradoraData.paquetes[0].detalles, "Coaseguro", "No incluido", cadaAseguradoraData.nombreAseguradora)
                    dataApi.data.topecoasegurogeneral = evaluacionCoberturas(cadaAseguradoraData.paquetes[0].detalles, "Tope Coaseguro", "No incluido")
                    dataApi.data.maternidadgeneral = evaluacionCoberturas(cadaAseguradoraData.paquetes[0].detalles, "Maternidad", "No incluido")
                    dataApi.data.adicionalgeneral = auxiliarContBeneficios > 0 ? auxiliarContBeneficios :  "No incluido"
                    break;
                case "GNP":
                    dataApi.data.costoTotalgnp = new Intl.NumberFormat("ES-MX", { style: "currency", currency: "MXN", minimumFractionDigits: 0 }).format(cadaAseguradoraData.sumaTotal)
                    dataApi.data.sumagnp = evaluacionCoberturas(cadaAseguradoraData.paquetes[0].detalles, "Suma Asegurada", "No incluido")
                    dataApi.data.deduciblegnp = evaluacionCoberturas(cadaAseguradoraData.paquetes[0].detalles, "Deducible", "Sin deducible")
                    dataApi.data.coasegurognp = evaluacionCoberturas(cadaAseguradoraData.paquetes[0].detalles, "Coaseguro", "No incluido", cadaAseguradoraData.nombreAseguradora)
                    dataApi.data.topecoasegurognp = evaluacionCoberturas(cadaAseguradoraData.paquetes[0].detalles, "Tope Coaseguro", "No incluido")
                    dataApi.data.maternidadgnp = evaluacionCoberturas(cadaAseguradoraData.paquetes[0].detalles, "Maternidad", "No incluido")
                    dataApi.data.adicionalgnp = auxiliarContBeneficios > 0 ? auxiliarContBeneficios :  "No incluido"
                    break;
                case "AXA":
                    dataApi.data.costoTotalAxxa = new Intl.NumberFormat("ES-MX", { style: "currency", currency: "MXN", minimumFractionDigits: 0 }).format(cadaAseguradoraData.sumaTotal)
                    dataApi.data.sumaAxxa = evaluacionCoberturas(cadaAseguradoraData.paquetes[0].detalles, "Suma Asegurada", "No incluido")
                    dataApi.data.deducibleAxxa = evaluacionCoberturas(cadaAseguradoraData.paquetes[0].detalles, "Deducible", "Sin deducible")
                    dataApi.data.coaseguroAxxa = evaluacionCoberturas(cadaAseguradoraData.paquetes[0].detalles, "Coaseguro", "No incluido", cadaAseguradoraData.nombreAseguradora)
                    dataApi.data.topecoaseguroAxxa = evaluacionCoberturas(cadaAseguradoraData.paquetes[0].detalles, "Tope Coaseguro", "No incluido")
                    dataApi.data.maternidadAxxa = evaluacionCoberturas(cadaAseguradoraData.paquetes[0].detalles, "Maternidad", "No incluido")
                    dataApi.data.adicionalAxxa = auxiliarContBeneficios > 0 ? auxiliarContBeneficios :  "No incluido"
                    break;
                case "MAPFRE":
                    dataApi.data.costoTotalMapfre = new Intl.NumberFormat("ES-MX", { style: "currency", currency: "MXN", minimumFractionDigits: 0 }).format(cadaAseguradoraData.sumaTotal)
                    dataApi.data.sumaMapfre = evaluacionCoberturas(cadaAseguradoraData.paquetes[0].detalles, "Suma Asegurada", "No incluido")
                    dataApi.data.deducibleMapfre = evaluacionCoberturas(cadaAseguradoraData.paquetes[0].detalles, "Deducible", "Sin deducible")
                    dataApi.data.coaseguroMapfre = evaluacionCoberturas(cadaAseguradoraData.paquetes[0].detalles, "Coaseguro", "No incluido", cadaAseguradoraData.nombreAseguradora)
                    dataApi.data.topecoaseguroMapfre = evaluacionCoberturas(cadaAseguradoraData.paquetes[0].detalles, "Tope Coaseguro", "No incluido")
                    dataApi.data.maternidadMapfre = evaluacionCoberturas(cadaAseguradoraData.paquetes[0].detalles, "Maternidad", "No incluido")
                    dataApi.data.adicionalMapfre = auxiliarContBeneficios > 0 ? auxiliarContBeneficios :  "No incluido"
                    break;
                default:
                    console.error("Se encontro aseguradora sin referencias")
                    break;
            }
        })
        ApiBearer.enviarCotizacion(dataApi)
        handleCloseModalEC()
    }

    const styleTitulosCabecera = {
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "20px",
        display: "flex",
        alignItems: "center",
        color: "#212121",
    }

    const styleTagsCabecera = {
        fontWeight: "600",
        fontSize: "12px",
        lineHeight: "24px",
    }

    const styleDatosCabecera = {
        fontStyle: "italic",
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "20px",
    }
    const styleModalNC = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 364,
        height: 'fitContent',
        bgcolor: '#fff',
        border: 'none',
        borderRadius: '8px',
        boxShadow: '',
        p: 2,
    };

    return (
        <>
            <Box>
                <Grid container marginBottom={'24px'} justifyContent="end" className="bg-cabecera-mobile">
                    {/* //Boton Generar Cotizacion// */}
                    <Grid item lg={3}  md={5} sm={12} xs={12} className="btn-ec-mobile hideOnDesktop" sx={{ marginBottom: '24px' }}>
                        <Button onClick={handleOpenModalEC} sx={{ fontSize: '16px' }}> <img src={IconEC} alt="" /> &nbsp; Enviar cotización</Button>
                    </Grid>

                    <Grid item lg={4} md={5} sm={12} xs={12} className='flex-center-on-mobile flex-end-on-desktop'>
                        <Button onClick={handleOpenModalNC} sx={{
                            color: "#039ECC",
                            fontSize: '16px'
                        }}>
                            <img src={IconNC} alt="" /> Generar nueva cotización
                        </Button>

                        {/* Modal Nueva Cotizacion */}
                        <Modal
                            open={openModalNC}
                            onClose={handleCloseModalNC}
                        >

                            <Box sx={styleModalNC} >
                                <Box sx={{ margin: "5%" }}>
                                    <p className="titulo-modalNC">¿Seguro quieres una nueva cotización?</p>
                                    <p className="p-modalNC">Ten en cuenta que los datos que nos compartiste se eliminarán de manera permanente </p>
                                </Box>

                                <Grid container direction="row" justifyContent="center" className="m-btn-modalNC">

                                    <Grid item md={12} xs={12} xl={12}>
                                        <Stack direction="row" spacing={2}>
                                            <Button onClick={handleCloseModalNC} className="btn-cancelar-modalNC">Cancelar</Button>
                                            <Button className="btn-aceptar-modalNC" onClick={clickAceptarNuevaCotizacion}>Aceptar</Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>

                        </Modal>

                        {/* Modal Enviar Cotizacion */}
                        <Modal
                            open={openModalEC}
                            onClose={handleCloseModalEC}
                        >
                            <Box sx={styleModalNC} >
                                <Box sx={{display: "flex", justifyContent: "space-between", margin: "0 5%"}}>
                                    <p className="titulo-modalNC" style={{marginTop: "10px"}}>Recibe tu cotización</p>
                                    <CloseIcon sx={{color: "#8F8F8F", cursor: "pointer"}} onClick={handleCloseModalEC}></CloseIcon> 
                                </Box>
                                <Box sx={{ margin: "5%" }}>
                                    <p className="p-modalNC">Confirma el correo en donde deseas recibir tu cotización, prometemos cero spam 😊 </p>
                                </Box>

                                <Grid container direction="column" justifyContent="center" className="">
                                    <Grid item md={12} xs={12} xl={12} lg={12} sx={{m: "0 5%"}}>
                                        <TextField value={correoEnvioCotizacion} label="Correo" variant="outlined" sx={{ width: "100%" }} onChange={handleChangeCorreoEnvioCotizacion} />
                                    </Grid>
                                    <Grid item md={12} xs={12} xl={12} lg={12} sx={{m: "5%"}}>
                                        <Button className="btn-enviar-modalEC" onClick={enviarCotizacion}>Enviar</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Modal>

                    </Grid>
                    {/* //Boton Enviar Cotizacion// */}
                    <Grid item lg={3} md={8} sm={12} xs={12} className="hideOnMobile-2 hideOnTablet" sx={{ textAlign: "right", justifyContent:"end" }}>
                        <Button onClick={handleOpenModalEC} sx={{ textAlign: "right", justifyContent: "end" }} >
                            <img src={IconEC} alt="icon-envio-cotizacion" />  Enviar cotización</Button>
                    </Grid>
                </Grid>

                <Grid container className='quotation-details-container'>
                    <Grid item marginBottom={'24px'} lg={6} sm={12} xs={12}>
                        <Grid container>
                            <Grid item xs={12} marginBottom={'24px'} className="showOnlyOnMobile hideOnTablet">
                                <Box className='space-between-centered'>
                                    <Grid sx={styleTagsCabecera}>
                                        Número de cotización
                                    </Grid>
                                    <Grid>
                                        <Tooltip title="Con este número podrás completar más tarde tu proceso de contratación, para que no tengas que llenar otra vez todos tus datos">
                                            <img alt="ico-tooltip" src={TooltipIcon} className="ico-tooltip" />
                                        </Tooltip>
                                    </Grid>
                                </Box>
                                <Box sx={{
                                    backgroundColor: "#fff",
                                    borderRadius: "10px",
                                    height: "34px",
                                    padding: "4% 2% 0% 2%",
                                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                }} className="w-235-tablet">
                                    {dataObj.folio}
                                </Box>
                            </Grid>
                            <Grid item lg={12} xs={12} marginBottom={'24px'}>
                                <Grid container direction={'row'} display={'flex'} justifyContent={'space-between'}>
                                    <Grid item lg={10} display="flex" alignItems="center">
                                        <Box sx={styleTitulosCabecera} > Titular </Box>
                                    </Grid>
                                    <Grid item lg={2}>
                                        <EditarPerfilCotizadorModal cerraModal={actualizarDatos} />
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid container display={'flex'} rowGap={'31px'}>
                            <Grid item lg={4} sm={6} xs={6}>
                                <Box sx={styleTagsCabecera}>
                                    Nombre
                                </Box>
                                <Box sx={styleDatosCabecera}>
                                    {name}
                                </Box>
                            </Grid>
                            <Grid item lg={4} sm={6} xs={6}>
                                <Box sx={styleTagsCabecera}>
                                    Fecha de nacimiento
                                </Box>
                                <Box sx={styleDatosCabecera}>
                                    {dateOfBirht}
                                </Box>
                            </Grid>
                            <Grid item lg={4} sm={6} xs={6}>
                                <Box sx={styleTagsCabecera} >
                                    Sexo de nacimiento
                                </Box>
                                <Box sx={styleDatosCabecera} >
                                    {gender}
                                </Box>
                            </Grid>
                            <Grid item lg={4} sm={6} xs={6}>
                                <Box sx={styleTagsCabecera}>
                                    Tel&eacute;fono
                                </Box>
                                <Box sx={styleDatosCabecera} >
                                    {phone}
                                </Box>
                            </Grid>
                            <Grid item lg={4} sm={6} xs={6} >
                                <Box sx={styleTagsCabecera}>
                                    E-mail
                                </Box>
                                <Box sx={styleDatosCabecera}>
                                    {email}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Divider*/}
                    <Grid item marginRight={'30px'} lg={0} sm={0} xs={0} className="hideOnTablet">
                        <Divider orientation="vertical" />
                    </Grid>

                    <Grid item marginBottom={'24px'} lg={5} sm={12}>
                        <Grid container>
                            <Grid item lg={12} sm={12} xs={12} marginBottom={'24px'}>
                                <Box sx={styleTitulosCabecera}> Est&aacute;s viendo tus opciones en </Box>
                            </Grid>
                            <Grid container display={'flex'} rowGap={'24px'}>
                                <Grid item lg={6} sm={12} xs={12}>
                                    <Box sx={styleTagsCabecera}>
                                        Cobertura de
                                    </Box>
                                    <Box sx={styleDatosCabecera}>
                                        Gastos médicos mayores
                                    </Box>
                                </Grid>
                                <Grid item lg={6} sm={12} xs={12}>
                                    <Box sx={styleTagsCabecera}>
                                        Modalidad de pago
                                    </Box>
                                    <Box sx={styleDatosCabecera}>
                                        Anual
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} sx={{ height: 'fit-content' }}>
                                    <FormControl sx={{ width: "100% " }}>
                                        <InputLabel>Planes</InputLabel>
                                        <Select
                                            value={selectedPlan}
                                            label="Planes"
                                            onChange={handleSelectPlanesChange}

                                        >
                                            <MenuItem value={"Esencial"}>Esencial</MenuItem>
                                            <MenuItem value={"Protegido"}>Protegido</MenuItem>
                                            <MenuItem value={"Blindado"}>Blindado</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {arrAsegurados.length > 1 ? (
                    <>
                        <Grid className="aseguradoAdicionalesStyle">
                            <Stack direction="row" justifyContent="space-between" alignItems="center" >
                                <Box sx={{ paddingLeft: "15px" }} className="styleTextAseguradosAdicionales">Asegurado(s) Adicional(es)</Box>
                                <Box sx={{ paddingRight: "15px" }} ><div onClick={handleClickDropdownAsegurados}><img alt="icon-delete" src={ArrowDownIcon} /></div></Box>
                            </Stack>

                            {arrAsegurados.map((cadaAsegurado, idx) => (
                                cadaAsegurado.parentesco != null ?
                                    (mostrarAsegurados === true ? (
                                        <>
                                            <Grid item md={12} key={"cadaAsegurado" + idx}>
                                                <Grid container justifyContent="center">
                                                    <Grid item md={1} xs={0} className="hideOnMobile">
                                                        &nbsp;
                                                    </Grid>
                                                    <Grid item md={2} xs={5}>
                                                        <p className="styleTextAseguradosAdicionales txt-titulo-asegurados-adicionales">Nombre</p>
                                                        <div>
                                                            <span className="txt-contenido-asegurados-adicionales ">{cadaAsegurado.nombre}</span>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={3} xs={5}>
                                                        <p className="styleTextAseguradosAdicionales txt-titulo-asegurados-adicionales">Fecha de nacimiento</p>
                                                        <div>
                                                            <span className="txt-contenido-asegurados-adicionales">{
                                                                format(Date.parse(cadaAsegurado.fechanacimiento), 'dd/MM/yyy')
                                                            }</span>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={2} xs={4} className="mr-10-mobile">
                                                        <p className="styleTextAseguradosAdicionales txt-titulo-asegurados-adicionales">Genero</p>
                                                        <div>
                                                            <span className="txt-contenido-asegurados-adicionales">{cadaAsegurado.genero}</span>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={2} xs={4}>
                                                        <p className="styleTextAseguradosAdicionales txt-titulo-asegurados-adicionales">Parentesco</p>
                                                        <div>
                                                            <span className="txt-contenido-asegurados-adicionales">{cadaAsegurado.parentesco}</span>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={2} xs={1}>
                                                        <Button onClick={() => { handleOpenModalADC(idx) }}><img alt="ico-editar" src={IconEditar} /></Button>
                                                        <Button onClick={() => { handleDeleteAsegurado(cadaAsegurado) }}><DeleteIcon sx={{ color: '#333436', }} /></Button>
                                                    </Grid>
                                                    <Modal
                                                        open={openModalADC}
                                                        onClose={handleCloseModalADC}
                                                    >
                                                        <Box sx={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            width: {
                                                                xs: "80%",
                                                                sm: "80%",
                                                                md: "30%",
                                                                lg: "40%",
                                                                xl: "30%",
                                                            },
                                                            height: {
                                                                xs: "78%",
                                                                sm: "70%",
                                                                md: "550px",
                                                                lg: "550px",
                                                                xl: "550px",
                                                            },
                                                            bgcolor: 'background.paper',
                                                            border: 'none',
                                                            borderRadius: '8px',
                                                            boxShadow: '',
                                                            p: 3,
                                                            textAlign: "-webkit-center"
                                                        }}
                                                        >
                                                            <h2 className="txt-principal-modal">
                                                                ¿Quieres editar la informaci&oacute;n
                                                                del asegurado adicional?
                                                            </h2>
                                                            <TextField id="outlined-basic" value={nombreAseguradoEdicionModal ? nombreAseguradoEdicionModal : "Cargando..."}
                                                                onChange={(event) => editAseguradoNombre(event, idxAseguradoEdit)}
                                                                label="Nombre" variant="outlined" sx={{ m: 1, minWidth: { xs: "40%", sm: "40%", md: "70%", lg: "80%" } }} />
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    disabled
                                                                    label="Fecha de nacimiento"
                                                                    value={arrAsegurados[idxAseguradoEdit] ? arrAsegurados[idxAseguradoEdit].fechanacimiento : "Loading..."}
                                                                    renderInput={(params) => <TextField {...params} sx={{ m: 1, minWidth: { xs: "40%", sm: "40%", md: "70%", lg: "80%" } }} />}
                                                                />
                                                            </LocalizationProvider>
                                                            <FormControl sx={{ m: 1, minWidth: { xs: "40%", sm: "40%", md: "70%", lg: "80%" } }}>
                                                                <TextField
                                                                    disabled
                                                                    labelId="demo-simple-select-helper-label"
                                                                    id="demo-simple-select-helper"
                                                                    value={arrAsegurados[idxAseguradoEdit] ? arrAsegurados[idxAseguradoEdit].genero : "Loading..."}
                                                                    label="Genero"
                                                                />
                                                            </FormControl>
                                                            <TextField disabled id="outlined-basic" value={arrAsegurados[idxAseguradoEdit] ? arrAsegurados[idxAseguradoEdit].parentesco : "Loading..."} variant="outlined" sx={{ m: 1, minWidth: { xs: "40%", sm: "40%", md: "70%", lg: "80%" } }} />
                                                            <Stack direction="row" alignItems="center" spacing={3} justifyContent="center">
                                                                <Button onClick={handleCloseModalADC} className="btn-cancelar-modal">Cancelar</Button>
                                                                <Button onClick={() => handleGuardarVentanaEdicionASegurado()} className="btn-guardar-modal">GUARDAR</Button>
                                                            </Stack>

                                                        </Box>
                                                    </Modal>
                                                </Grid>
                                                <Divider sx={{ width: "80% " }} justifyContent="center" className="ml-12" />
                                            </Grid>

                                        </>
                                    ) : (<></>)
                                    ) : (<></>)
                            ))}
                        </Grid>

                    </>
                )
                    : (<></>)}



            </Box>
        </>
    )
}