import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Padecimiento from "./Padecimiento";
import { padecimientos } from "../../Utils/Cuestionario";
import CustomRadioGroup from "../../CustomFields/CustomRadioGroup";
import CustomRadio from "../../CustomFields/CustomRadio";
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { INTER_CONTRATANTE } from "../../Utils/Constantes";
import ApiCuestionario from '../../Api/Cuestionario'
import { validaResponse } from "../../Utils/Utils";
import Loading from '../../Commons/Loader';

export default function Hospitalizacion(props) {
  const [contratante, setContratante] = useState({});
  const [hospitalizaciones, setHospitalizaciones] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);

  const handleGuardarPadecimientos = (values) => {
    setOpenLoader(true);
    const item = {
      descripcion: values.descripcion,
      mes: values.mes,
      anio: values.anio,
      saludActual: values.saludActual,
      id: values.id,
      tipoPadecimiento: values.item,
    };
    const currentIndex = props.asegurado.hospitalizaciones.findIndex((padecimiento) => padecimiento[values.item] !== undefined);
    if(currentIndex > -1){
      let currentPadecimiento = props.asegurado.hospitalizaciones[currentIndex];
      if(item.id !== null && item.id !== undefined){      
        ApiCuestionario.updatePadecimiento(item, contratante.id, props.asegurado.id)
          .then(res => {
            setOpenLoader(false);
            if(validaResponse(res)){
              if(res.data.padecimiento !== null) {
                const indexItem = currentPadecimiento[values.item].findIndex( (currentItem) => currentItem.id === item.id);
                let padecimientoAux = null;
                currentPadecimiento[values.item].splice(indexItem, 1, res.data.padecimiento);
                padecimientoAux = currentPadecimiento[values.item];
                const currentPad = { 
                  [values.item] : padecimientoAux
                };
                props.asegurado.hospitalizaciones.splice(currentIndex, 1, currentPad);
                setHospitalizaciones((prevPad) => [...prevPad, props.asegurado.hospitalizaciones]);
                setHospitalizaciones(props.asegurado.hospitalizaciones);
                props.handleGuardarPadecimiento(props.asegurado);
              }
            } else {
              console.log("ERROR");
            }
          })
          .catch(error => {
            setOpenLoader(false);
            console.log(error);
          });
      } else {      
        ApiCuestionario.guardaPadecimiento(item, contratante.id, props.asegurado.id)
          .then(res => {
            setOpenLoader(false);
            if(validaResponse(res)){
              if(res.data.padecimiento !== null) {
                let padecimientoAux = null;
                padecimientoAux = currentPadecimiento[values.item].concat(res.data.padecimiento);
                const currentPad = { 
                  [values.item] : padecimientoAux
                };
                props.asegurado.hospitalizaciones.splice(currentIndex, 1, currentPad);
                setHospitalizaciones((prevPad) => [...prevPad, props.asegurado.hospitalizaciones]);
                setHospitalizaciones(props.asegurado.hospitalizaciones);
                props.handleGuardarPadecimiento(props.asegurado);
              }
            } else {
              console.log("ERROR");
            }
          })
          .catch(error => {
            setOpenLoader(false);
            console.log(error);
          });
      }
    } else {
      ApiCuestionario.guardaPadecimiento(item, contratante.id, props.asegurado.id)
        .then(res => {
          setOpenLoader(false);
          if(validaResponse(res)){
            if(res.data.padecimiento !== null) {
              const currentPad = { [values.item] : [ res.data.padecimiento,] };
              const padecimientosAux = props.asegurado.hospitalizaciones.concat(currentPad);
              props.asegurado.hospitalizaciones = padecimientosAux;
              setHospitalizaciones(props.asegurado.hospitalizaciones);
              props.handleGuardarPadecimiento(props.asegurado);
            }
          } else {
            console.log("ERROR");
          }
        })
        .catch(error => {
          setOpenLoader(false);
          console.log(error);
        });
    }
    props.handleGuardarPadecimiento(props.asegurado);
  };

  const handleRemovePadecimiento = (event, itemP, padecimientoNombre) => {
    event.stopPropagation();
    ApiCuestionario.deleteCongenito(contratante.id, props.asegurado.id, itemP.id)
    .then(res => {
      if(validaResponse(res)){

        const currentIndex = props.asegurado.hospitalizaciones.findIndex((padecimiento) => padecimiento[padecimientoNombre] !== undefined);
        let currentPadecimiento = props.asegurado.hospitalizaciones[currentIndex];
        const indexItem = currentPadecimiento[padecimientoNombre].findIndex( (currentItem) => currentItem.id === itemP.id);
        currentPadecimiento[padecimientoNombre].splice(indexItem, 1);
        props.asegurado.hospitalizaciones.splice(currentIndex, 1, currentPadecimiento);
        
        //setHospitalizaciones([...padecimientos, props.asegurado.hospitalizaciones]);
        setHospitalizaciones((prevPad) => [...prevPad, props.asegurado.hospitalizaciones]);
        setHospitalizaciones(props.asegurado.hospitalizaciones);
        props.handleGuardarPadecimiento(props.asegurado);
      } else {
        console.log("ERROR");
      }
    })
    .catch(error => {
      console.log(error);
    });
  };

  const buildPadecimientoByItem = (padecimiento, item) => {
    return {
      item: item,
      descripcion: padecimiento.descripcion,
      mes: padecimiento.mes,
      anio: padecimiento.anio,
      saludActual: padecimiento.saludActual,
      id: padecimiento.id,
      tipoPadecimiento: padecimiento.tipoPadecimiento,
    }
  };

  const validaNuevaLista = (padecimiento, hospitalizaciones) => {
    if(hospitalizaciones !== null && hospitalizaciones.length > 0){
      let hayRegistrado = false;
      hospitalizaciones.forEach(element => 
        {
          if(element[padecimiento.padecimiento] !== undefined){
            hayRegistrado = true;
          }
        }
      );
      if(hayRegistrado){
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if(window.sessionStorage.getItem(INTER_CONTRATANTE)){
      const contra = JSON.parse(window.sessionStorage.getItem(INTER_CONTRATANTE));
      setContratante(contra);
      const aseguradoLocal = contra.asegurados.find(asegurado => asegurado.id === props.asegurado.id);
      setHospitalizaciones(aseguradoLocal.hospitalizaciones);
    }
    /* Especifica cómo sanear este efecto:
    return function cleanup() {
    };*/
  },[props.asegurado]);

  
  return (
    <>
      {padecimientos.map((padecimiento, index) => (
        <React.Fragment key={index}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start"
            className="mt-16">
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <span className='text-preguntas'>{padecimiento.pregunta}</span>
              <br/><span className='text-desc'>{padecimiento.descripcion}</span>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <CustomRadioGroup row name={padecimiento.padecimiento}
                onChange={(event) =>
                  props.handleOnChangeValue(event, props.setFieldValue)
                }>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CustomRadio name={padecimiento.padecimiento} type="radio" value="1" label="Si"/>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CustomRadio name={padecimiento.padecimiento} type="radio" value="0" label="No"/>
                </Grid>
              </CustomRadioGroup>
            </Grid>
          </Grid>
          {(props.values[padecimiento.padecimiento] === "1") && 
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1} sx={{ mt: 2}}>
              { validaNuevaLista(padecimiento, hospitalizaciones) ? 
                  <>
                    {hospitalizaciones.map((padecimientos, jndex) =>
                      <React.Fragment key={jndex}>
                        {padecimientos[padecimiento.padecimiento] !== undefined && padecimientos[padecimiento.padecimiento].map((itemP, kndex) =>
                          <React.Fragment key={kndex}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Accordion className='background-acordeon'>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                  aria-controls={"padecimientos-content"+kndex} id={"padecimientos-header"+kndex}
                                  className="no-margin-accordeon">
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='d-flex-center'>
                                    <IconButton onClick={(event) => handleRemovePadecimiento(event, itemP, padecimiento.padecimiento)}
                                      onFocus={(event) => event.stopPropagation()}>
                                      <HighlightOffIcon />
                                    </IconButton >
                                    <Typography >{itemP.descripcion}</Typography>
                                  </Grid>
                                </AccordionSummary>
                                <AccordionDetails className='background-form-acordeon'>
                                  <Padecimiento handleGuardar={handleGuardarPadecimientos} padecimiento={buildPadecimientoByItem(itemP, padecimiento.padecimiento)} 
                                    add={padecimientos[padecimiento.padecimiento].length} padecimientoItem={padecimiento.padecimiento} lista={padecimiento.lista}
                                    asegurado={props.asegurado} tipoFlujo={contratante.tipoFlujo}/>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          </React.Fragment>
                        )}
                        {padecimientos[padecimiento.padecimiento] !== undefined && padecimientos[padecimiento.padecimiento].length < 5 &&
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 1, marginLeft: 1}}>
                            <Padecimiento handleGuardar={handleGuardarPadecimientos}  padecimiento={null} padecimientoItem={padecimiento.padecimiento} lista={padecimiento.lista}
                            asegurado={props.asegurado} tipoFlujo={contratante.tipoFlujo}/>
                          </Grid>
                        }
                      </React.Fragment>
                    )}
                  </>
                : 
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 1, marginLeft: 1}}>
                    <Padecimiento handleGuardar={handleGuardarPadecimientos}  padecimiento={null} padecimientoItem={padecimiento.padecimiento} lista={padecimiento.lista}
                    asegurado={props.asegurado} tipoFlujo={contratante.tipoFlujo}/>
                  </Grid>
              }
            </Grid>
          }
        </React.Fragment>
      ))}
      <Loading open={openLoader} onClose={() => setOpenLoader(false)}/>
    </>
  );
}
