import React from 'react';

const LeftRotateIcon = ({ className }) => {
  return (
    <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M8.16675 1.33325L1.50008 7.99992L8.16675 14.6666" stroke="#8F8F8F" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.5 8H12.1667C19.5307 8 25.5 13.9693 25.5 21.3333V22.6667" stroke="#8F8F8F" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default LeftRotateIcon;