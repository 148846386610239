const mapperFromDocumentsToDocumentsForAccordions = (documents) => {
    if (!documents || documents.length === 0) return 
    const documentsForAccordions = []

    const ine = {...encontrarDocumento(documents, "documentName", 'INE'), title: 'Identificación oficial'};
    if (Object.keys(ine).length > 1) documentsForAccordions.push(ine);  

    const domicilio = {...encontrarDocumento(documents, "documentName", 'DOMICILIO'), title: 'Comprobante de domicilio'};
    if (Object.keys(domicilio).length > 1) documentsForAccordions.push(domicilio);  

    const telefonoFromDocument = encontrarDocumento(documents, "documentName", 'TELEFONO');
    const correoFromDocument = encontrarDocumento(documents, "documentName", 'CORREO');

    const datosDeContacto = createDocumentDatosDeContacto(telefonoFromDocument, correoFromDocument);
    documentsForAccordions.push(datosDeContacto);

    const ordenDeTrabajoFromDocument = encontrarDocumento(documents, "documentName", 'OT');
    const ordenDeSolicitudFromDocument = encontrarDocumento(documents, "documentName", 'OS');

    const datosDeLaAseguradora = createDocumentDatosDeLaAseguradora(ordenDeTrabajoFromDocument, ordenDeSolicitudFromDocument);
    documentsForAccordions.push(datosDeLaAseguradora);

    return documentsForAccordions;
}

const encontrarDocumento = (arrayToLookUp, propertyName, keyword) => {
    return arrayToLookUp.find(currentElement => currentElement[propertyName] === keyword)
}

const createDocumentDatosDeContacto = (telefonoFromDocument, correoFromDocument) => {
    return {
        title: 'Datos de contacto',
        documentName: 'TELEFONO',
        estatus: telefonoFromDocument?.estatus !== 200 ? 500 : correoFromDocument?.estatus !== 200 ? 500 : 200,
        vigente: !telefonoFromDocument?.vigente ? false : !correoFromDocument.vigente ? false : true,
        etapas: [
            {
                docuemntName: 'Validación de teléfono',
                success: telefonoFromDocument?.estatus === 200 ? true : false
            },
            {
                docuemntName: 'Validación de correo electrónico',
                success: correoFromDocument?.estatus === 200 ? true : false
            }
        ]
    }
}

const createDocumentDatosDeLaAseguradora = (ordenDeTrabajoFromDocument, ordenDeSolicitudFromDocument) => {
    return {
        title: 'Documentos de la aseguradora',
        documentName: 'OT',
        estatus: ordenDeTrabajoFromDocument.estatus !== 200 ? 500 : ordenDeSolicitudFromDocument.estatus !== 200 ? 500 : 200,
        vigente: !ordenDeTrabajoFromDocument.vigente ? false : !ordenDeSolicitudFromDocument.vigente ? false : true,
        etapas: [
            {
                documentCode: 'OT',
                docuemntName: 'Orden de trabajo',
                success: ordenDeTrabajoFromDocument.estatus === 200 ? true : false,
            },
            {
                documentCode: 'OS',
                docuemntName: 'Solicitud médica firmada',
                success: ordenDeSolicitudFromDocument.estatus === 200 ? true : false,
            }
        ]
    }
}

export default mapperFromDocumentsToDocumentsForAccordions;