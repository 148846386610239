/**maneja el cambio del campo, valida la longitud y setea el valor del campo */
export  const handleMaxLength = (e, setFieldValue, field, max) => {
  let val = e.target.value;
  val = maxLength(val, max);
  setFieldValue(field, val);
};

export function maxLength(value, maxLength) {
  var res = String(value);
  if(res.length > maxLength){
    return res.substring(0,maxLength);
  } else {
    return res;
  }
}