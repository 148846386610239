
import { useField } from "formik";
import React from "react";
import RadioGroup from '@mui/material/RadioGroup';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  error: {
    color: "#f44336",
    fontSize: "0.75rem",
  },
}));

export default function CustomRadioGroup({ placeholder, ...props }) {
  const classes = useStyles();
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <>
      <RadioGroup {...field} {...props} />
      <div className={classes.error}>{errorText}</div>
    </>
  );
}
