
import React from "react";
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useField } from "formik";

export default function CustomRadio(props) {
  const [field] = useField(props);

  return (
    <FormControlLabel
      {...field}
      {...props}
      control={<Radio />}
      label={props.label}
    />
  );
}
