import * as yup from "yup";
import { CHECK_NECESARIO, CMB_NECESARIO, MSG_FALTA_INFO } from "../Utils/Constantes";

export const validationPasoUno = yup.object().shape({
	deporte: yup.string()
		.required(CHECK_NECESARIO)
    .nullable(),
	fumar: yup.string()
		.required(CHECK_NECESARIO)
		.nullable(),
	beber: yup.string()
		.required(CHECK_NECESARIO)
		.nullable(),
	drogarse: yup.string()
		.required(CHECK_NECESARIO)
		.nullable(),
	descrDeporteD: yup.string(), 
	cantidadF: yup.number(),
	frecuenciaF: yup.string(),
	dtDesdeCuandoF: yup.string(),
	cantidadB: yup.number(),
	frecuenciaB: yup.string(),
	dtDesdeCuandoB: yup.string(),
	cantidadDr: yup.number(),
	frecuenciaDr: yup.string(),
	dtDesdeCuandoDr: yup.string(),
});

export const validationPasoDos = yup.object().shape({
	peso: yup.number()
    .required(MSG_FALTA_INFO)
		.typeError(MSG_FALTA_INFO)
    .nullable(),
	estatura: yup.number()
    .required(MSG_FALTA_INFO)
		.typeError(MSG_FALTA_INFO)
    .nullable(),
});

export const validationPasoTres = yup.object().shape({
	congenita: yup.number()
    .required(CHECK_NECESARIO)
    .nullable(),
	hospitalizaciones: yup.number()
    .required(CHECK_NECESARIO)
    .nullable(),
});

export const validationSimple = yup.object().shape({
	revisionGral: yup.string()
		.required(CHECK_NECESARIO)
		.nullable(),
	resultadoG: yup.string(),
	FechaG: yup.string(),
});

export const validationHombreMayor = yup.object().shape({
	prostata: yup.string()
		.required(CHECK_NECESARIO)
    .nullable(),
	revisionGral: yup.string()
		.required(CHECK_NECESARIO)
		.nullable(),
	resultadoP: yup.string(), 
	FechaP: yup.string(),
	resultadoG: yup.string(),
	FechaG: yup.string(),
});

export const validationMujer = yup.object().shape({
	revisionMujer: yup.string()
		.required(CHECK_NECESARIO)
		.nullable(),
	revisionGral: yup.string()
		.required(CHECK_NECESARIO)
		.nullable(),
	resultadoM: yup.string(),
	FechaM: yup.string(),
	resultadoG: yup.string(),
	FechaG: yup.string(),
});

export const validationMujerMayor = yup.object().shape({
	revisionMujer: yup.string()
		.required(CHECK_NECESARIO)
		.nullable(),
	revisionMujer2: yup.string()
		.required(CHECK_NECESARIO)
		.nullable(),
	revisionGral: yup.string()
		.required(CHECK_NECESARIO)
		.nullable(),
	resultadoM: yup.string(),
	FechaM: yup.string(),
	resultadoMS: yup.string(),
	FechaMS: yup.string(),
	resultadoG: yup.string(),
	FechaG: yup.string(),
});


export const validationPadecimiento = yup.object().shape({
	descripcion: yup.string()
		.required(CMB_NECESARIO)
		.min(3,CMB_NECESARIO)
		.nullable(),
	mes: yup.number()
		.required(CMB_NECESARIO)
        .min(1,CMB_NECESARIO)
		.nullable(),
	anio: yup.number()
		.required(CMB_NECESARIO)
        .min(1,CMB_NECESARIO)
		.nullable(),
	saludActual: yup.string()
		.required(CHECK_NECESARIO)
		.nullable(),
});


