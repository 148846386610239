import React, { useContext, useEffect, useRef, useState } from "react"
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material"
import SignaturePad from "react-signature-pad-wrapper"
import SyncIcon from "@mui/icons-material/Sync"
import { INTER_CONTRATANTE } from "../Utils/Constantes"
import Api from "../../components/Api/Api"
import { useNavigate, useParams } from "react-router-dom"
import Snackbar from "@mui/material/Snackbar"
import { checkIfExists } from "../Utils/Utils"
import Loading from "../Commons/Loader"
import QrModal from "../Commons/QrModal"
import EventEmitter from "../services/EventEmitter"
import { Context as ContratacionContext } from "./Contratacion"
import { Context as FirmaMobileContext } from "./PasoFirmaMobile"

export default function PasoFirmatres(props) {
  const params = useParams()
  const { setIsSubmitting } = useContext(
    params?.folio ? FirmaMobileContext : ContratacionContext
  )
  const { isCtaQr = true, handleClick } = props
  const [openLoader, setOpenLoader] = useState(false)
  const padRef = useRef()
  const [checkedTerminos, setCheckedTerminos] = useState(false)
  const [contratante, setContratante] = useState({})
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  })
  const navigate = useNavigate()
  const [urlTerminos, setUrlTerminos] = useState("")
  const [showQrModal, setshowQrModal] = useState(false)
  const { vertical, horizontal, open } = state
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"), {
    noSsr: true,
  })
  const clear = () => {
    padRef.current.clear()
    window.dataLayer.push({
      event: "medico_seguro_datos_firmar_opciones",
      boton_firma: "Otra vez",
      cotizacion: contratante.folio
    })
    setCheckedTerminos(false)
  }

  const guardar = (newState) => () => {
    window.dataLayer.push({
      event: "form_step_11_submit",
    })
    if (checkedTerminos === false) {
      setState({ open: true, ...newState })
    } else {
      const data = padRef.current.toDataURL("image/svg+xml")
      setOpenLoader(true)
      if (handleClick) return handleClick(data)
      handleGuardaFirma(data)
    }
  }

  const handleClose = () => {
    setState({ ...state, open: false })
  }

  useEffect(() => {
    setIsSubmitting(!checkedTerminos);
    if (params.folio){
      Api.getContratante(params.folio)
          .then((res) => {
            window.sessionStorage.setItem(
              INTER_CONTRATANTE,
              JSON.stringify(res.data.contratante)
            );
            if (window.sessionStorage.getItem(INTER_CONTRATANTE)) {
              const contratanteSess = JSON.parse(
                window.sessionStorage.getItem(INTER_CONTRATANTE)
              )
              setContratante(contratanteSess)
              if (checkIfExists(contratanteSess.cotizaciones)) {
                if (checkIfExists(contratanteSess.cotizaciones[0])) {
                  switch (contratanteSess.cotizaciones[0].nombreAseguradora) {
                    case "GENERAL DE SEGUROS":
                      setUrlTerminos(
                        "https://storage.googleapis.com/inter-public-cloudstorage-prod/gmm/terminoscondiciones/GENERAL DE SALUD.pdf"
                      )
                      break
                    case "GNP":
                      setUrlTerminos(
                        "https://storage.googleapis.com/inter-public-cloudstorage-prod/gmm/terminoscondiciones/GNP.pdf"
                      )
                      break
                    case "MAPFRE":
                      setUrlTerminos(
                        "https://storage.googleapis.com/inter-public-cloudstorage-prod/gmm/terminoscondiciones/MAPFRE.pdf"
                      )
                      break
                    case "AXA":
                      setUrlTerminos(
                        "https://storage.googleapis.com/inter-public-cloudstorage-prod/gmm/terminoscondiciones/AXA.pdf"
                      )
                      break
                    default:
                      break
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error)
          })
    }
    EventEmitter.suscribe(
      "signature-event",
      guardar({
        vertical: "top",
        horizontal: "center",
      })
    )
    return () => {
      EventEmitter.unsubscribe("signature-event")
    }
  }, [checkedTerminos])

  const handleGuardaFirma = (data) => {
    contratante.firma = data
    contratante.ultimoPaso = 15
    Api.actualizaContratante(contratante.id, contratante)
      .then((res) => {
        Api.getContratante(contratante.folio)
          .then((res) => {
            setOpenLoader(false)
            window.sessionStorage.setItem(
              INTER_CONTRATANTE,
              JSON.stringify(res.data.contratante)
            )
            //AQUI PODRIA SEGUIR EL FLUJO
            //props.habilitaBoton(firma !== null && checkedTerminos);
            navigate("/terminamos-contratacion")
          })
          .catch((error) => {
            console.log(error)
          })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleChangeTerminos = (event) => {
    setCheckedTerminos(event.target.checked)
  }

  useEffect(() => {
    setIsSubmitting(true)
    window.dataLayer.push({
      event: "form_step_11_view",
    })
    if (window.sessionStorage.getItem(INTER_CONTRATANTE)) {
      const contratanteSess = JSON.parse(
        window.sessionStorage.getItem(INTER_CONTRATANTE)
      )
      setContratante(contratanteSess)
      if (checkIfExists(contratanteSess.cotizaciones)) {
        if (checkIfExists(contratanteSess.cotizaciones[0])) {
          switch (contratanteSess.cotizaciones[0].nombreAseguradora) {
            case "GENERAL DE SEGUROS":
              setUrlTerminos(
                "https://storage.googleapis.com/inter-public-cloudstorage-prod/gmm/terminoscondiciones/GENERAL DE SALUD.pdf"
              )
              break
            case "GNP":
              setUrlTerminos(
                "https://storage.googleapis.com/inter-public-cloudstorage-prod/gmm/terminoscondiciones/GNP.pdf"
              )
              break
            case "MAPFRE":
              setUrlTerminos(
                "https://storage.googleapis.com/inter-public-cloudstorage-prod/gmm/terminoscondiciones/MAPFRE.pdf"
              )
              break
            case "AXA":
              setUrlTerminos(
                "https://storage.googleapis.com/inter-public-cloudstorage-prod/gmm/terminoscondiciones/AXA.pdf"
              )
              break
            default:
              break
          }
        }
      }
    }

    /* Especifica cómo sanear este efecto:
    return function cleanup() {
    };*/
  }, [])
  const handleQrModal = (e) => {
    e.preventDefault()
    window.dataLayer.push({
      event: "medico_seguro_datos_firmar_opciones",
      boton_firma: "Problemas para firmar",
      cotizacion: contratante.folio
    })
    setshowQrModal(!showQrModal)
  }
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <span className="text-preguntas">
            &iexcl;Listo! Para terminar con el proceso de contrataci&oacute;n,
            ay&uacute;danos firmando en el siguiente recuadro. Con tu firma
            electr&oacute;nica confirmas que los datos que nos compartiste son
            correctos y que das consentimiento a tu solicitud.
          </span>
        </Grid>
        <div className="styles-canvas">
          <SignaturePad
            ref={padRef}
            redrawOnResize={true}
            options={{ minWidth: 0.5, maxWidth: 0.5, penColor: "#000000" }}
          />
        </div>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box sx={{ mt: 2 }}>
            <Stack direction="row" spacing={1}>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<SyncIcon />}
                onClick={clear}
              >
                Otra vez, no me sali&oacute;
              </Button>
              {/*  <Button fullWidth variant="contained" onClick={guardar({
                vertical: 'top',
                horizontal: 'center',
              })}>
                guardar
              </Button> */}
              <Snackbar
                autoHideDuration={2000}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                message="Se requiere aceptar Terminos y Condiciones"
                key={vertical + horizontal}
              />
            </Stack>
          </Box>
        </Grid>
        <div className="espacio w-100">
          {isCtaQr && !isMobile && (
            <Grid container direction="row" justifyContent="flex-end">
              ¿Tienes problemas para firmar? Da click{" "}
              <a
                className="href-modal"
                onClick={handleQrModal}
                rel="noreferrer"
              >
                {" "}
                aquí
              </a>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Checkbox
              name="terminos"
              checked={checkedTerminos}
              onChange={handleChangeTerminos}
              inputProps={{ "aria-label": "controlled" }}
            />
            Acepto
            <a
              className="href-modal"
              target="_blank"
              href={urlTerminos}
              rel="noreferrer"
            >
            Terminos y condiciones de la aseguradora
            </a>
          </Grid>
        </div>
      </Grid>
      {showQrModal && (
        <QrModal handleShow={handleQrModal} isVisible={showQrModal} />
      )}
      {/*<br />
      <img src={firma} alt="exported firma" />*/}
      {openLoader && (
        <Loading open={openLoader} onClose={() => setOpenLoader(false)} />
      )}
    </>
  )
}
