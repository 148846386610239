import React, { useEffect } from "react";
import { INTER_CONTRATANTE } from "../components/Utils/Constantes";
import DeviceDetector from "device-detector-js";
export function useScoreLead() {
  const device = React.useMemo(getDeviceDetection,[]);
  const [tipoSeguro, setTipoSeguro] = React.useState(getTipoFlujo());
  const scoreLead = React.useMemo(() => {
    const score = {
      1: {
        1 : 5,
        2 : 7,
        3 : 6
      },
      2: {
        1 : 8,
        2 : 10,
        3 : 9
      }
    }
    return score[device][tipoSeguro]
  }, [device, tipoSeguro]);

  function getTipoFlujo() {
    return JSON.parse(sessionStorage.getItem(INTER_CONTRATANTE))?.tipoFlujo
  } 
  

  function getDeviceDetection() {
    const deviceDetector = new DeviceDetector();
    const {device} = deviceDetector.parse(window.navigator.userAgent);
    return device.type !== 'desktop' ? '1' : '2' 
  }

  useEffect(() => {
    window.onstorage = () => {
      setTipoSeguro(getTipoFlujo())
    }
  },[])

  return {
    device,
    scoreLead,
    tipoSeguro,
  };
}
