import * as yup from "yup";
import { CMB_NECESARIO, FECHA_VALIDA, VALOR_NECESARIO } from "../Utils/Constantes";


export const validationForm = yup.object().shape({
  nombre: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(20,'Debe tener como máximo 20 caracteres'),
  fechanacimiento: yup
    .date()
    .required(VALOR_NECESARIO)
    .typeError(FECHA_VALIDA)
    .test(
      "fechaValida",
      FECHA_VALIDA,
      (fecha) => {
        if(fecha instanceof Date && !isNaN(fecha)){
          return true;
        } else {
          return false;
        }
      }
    ),
  genero: yup
    .number()
    .required(CMB_NECESARIO)
    .min(0, CMB_NECESARIO),
  cp: yup
    .number()
    .required(CMB_NECESARIO),
});

export const validationEmbarazoForm = yup.object().shape({
  nombre: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(20,'Debe tener como máximo 20 caracteres'),
  fechanacimiento: yup
    .date()
    .required(VALOR_NECESARIO)
    .typeError(FECHA_VALIDA)
    .test(
      "fechaValida",
      FECHA_VALIDA,
      (fecha) => {
        if(fecha instanceof Date && !isNaN(fecha)){
          return true;
        } else {
          return false;
        }
      }
    ),
  genero: yup
    .number()
    .required(CMB_NECESARIO)
    .min(0, CMB_NECESARIO),
  embarazo: yup
    .boolean()
    .nullable()
    .required(CMB_NECESARIO),
  cp: yup
    .number()
    .required(CMB_NECESARIO),
});
