import {
  Dialog,
  DialogTitle,
  Box,
  IconButton,
  DialogContent,
  Grid,
  Button,
  ListItem,
  ListItemText,
  List
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Api from "../../Api/Api";
import { useParams } from "react-router-dom";
import { STATUS } from "../../Utils/Constantes";

const UpdateDocumentsButton = (props) => {
  const { documentWithNewStatus, setAlertOptions, setOpen } = props;
  const { folio } = useParams();
  const [documentsToBeRejected, setDocumentsToBeRejected] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [documentsToAddToListToBeUpdated, setDocumentsToAddToListToBeUpdated] = useState([]);

  useEffect(() => {
    if (validateObjects(documentWithNewStatus)) {
      if (documentsToAddToListToBeUpdated.length <= 0) {
        setDocumentsToAddToListToBeUpdated([documentWithNewStatus])
      } else {
        if (documentsToAddToListToBeUpdated.find(documentInTheList => documentInTheList.id === documentWithNewStatus.id && documentInTheList.newStatus !== documentWithNewStatus.newStatus)) {
          const documentsWithoutRepeatedStatus = documentsToAddToListToBeUpdated.filter(documentInTheList => documentInTheList.id !== documentWithNewStatus.id);
          documentsWithoutRepeatedStatus.push(documentWithNewStatus);
          setDocumentsToAddToListToBeUpdated(documentsWithoutRepeatedStatus);
        } else {
          setDocumentsToAddToListToBeUpdated([...documentsToAddToListToBeUpdated, documentWithNewStatus]);
        }
      }
    }
  }, [documentWithNewStatus]);

  const validateObjects = (object) => {
    if (Object.keys(object).length <= 0) return false
    if (typeof object !== 'object') return false

    return true
  }

  const handleOpenModal = () => {
    setDocumentsToBeRejected(documentsToAddToListToBeUpdated.filter(documentToAdd => Number(documentToAdd.newStatus) === STATUS.REJECTED));
    const documentsToBeAccepted = (documentsToAddToListToBeUpdated.filter(documentToAdd => Number(documentToAdd.newStatus) !== STATUS.REJECTED));

    if (documentsToBeAccepted.length > 0 && documentsToBeRejected.length <= 0) {
      handleUpdateDocumentsStatus(documentsToBeAccepted);
      return
    }
    if (documentsToBeAccepted.length > 0 && documentsToBeRejected.length > 0) handleUpdateDocumentsStatus(documentsToBeAccepted);

    setModalOpen(true);
  }

  const handleCloseModal = (event, reason) => {
    if (reason !== 'backdropClick') {
      setModalOpen(false);
    }
  };

  const handleUpdateDocumentsStatus = (documentsToBeUpdated) => {
    documentsToBeUpdated.forEach((documentToBeUpdated => {
      Api.setVerificacionDocumento(documentToBeUpdated.documentName, folio, documentToBeUpdated.newStatus, documentToBeUpdated.id)
      .then(() => {
        setOpen(true);
        setAlertOptions({ message: '¡La operación se completó con éxito!', severity: 'success'});
      })
      .catch((err) => {
          console.error(err.message);
          setOpen(true);
          setAlertOptions({ message: err.message, severity: 'error'});
      })
    }));
  }

  return (
    <>
      <Dialog
        open={isModalOpen}
        onClose={(event, reason) => handleCloseModal(event, reason)}
        disableEscapeKeyDown={true}
        maxWidth="sm"
        fullWidth={false}
      >
        <DialogTitle>
          <Box>
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                p: 2,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row">
            <Grid item lg={12} xl={12}>
              {documentsToBeRejected.length > 1 && (
                <p className="titulo-modal">
                  Confirma la solicitud de actualizaci&oacute;n de los
                  siguientes documentos
                </p>
              )}
              {documentsToBeRejected.length === 1 && (
                <p className="titulo-modal">
                  Confirma la solicitud de actualizaci&oacute;n del siguiente
                  documento
                </p>
              )}
              {documentsToBeRejected.length <= 0 && (
                <p className="titulo-modal">No hay documentos por actualizar</p>
              )}
            </Grid>
            <Grid item lg={12} xl={12}>
              <Box className="center-list">
                <List dense={true}>
                  {documentsToBeRejected.map((documentoActualizar, i) => (
                    <ListItem key={i} className="disc-style">
                      <ListItemText
                        className="lista-modal"
                        primary={documentoActualizar.title}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item lg={12} xl={12}>
              {documentsToBeRejected.length >= 1 && (
                <Button
                  variant="contained"
                  disableElevation
                  className="boton-solicitar"
                  onClick={() => {
                    handleCloseModal()
                    handleUpdateDocumentsStatus(documentsToBeRejected)
                  }}
                >
                  SOLICITAR
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} direction="row" className="p-absolute bottom-0">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mY-17">
          <Button
            variant="contained"
            className="center-botton solicitar-autorizacion"
            onClick={handleOpenModal}
          >
            <p className="text-transform-none w-text solicitar-act">
              Solicitar actualizaci&oacute;n de documentos
            </p>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateDocumentsButton;
