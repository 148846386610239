import * as yup from "yup";
import { TELEFONO_INVALIDO, VALOR_NECESARIO, VALOR_NUMERO } from "../Utils/Constantes";

export const validationFormContactoMayorEdad = yup.object().shape({
  telefono: yup
    .string()
    .required(VALOR_NECESARIO)
    .typeError(VALOR_NUMERO)
    .length(10, TELEFONO_INVALIDO)
    .test(
      "formato",
      TELEFONO_INVALIDO,
      (val) => {
        var regex = /^\D*\d{10}$/;
        return regex.test(val + "");
      }
    ),
});