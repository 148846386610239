import React, { useEffect, useState } from 'react';
import { Grid, Box, Stack, Button, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Form, Formik } from 'formik';
import * as yup from "yup";
import Api from '../../Api/Api';

const NotesViewer = (props) => {
    const [notasForm, setNotasForm] = useState(false);
    const [notas, setNotas] = useState([]);
    const [contratante, setContratante] = useState({});

    useEffect(() => {
        const contratante = JSON.parse(sessionStorage.getItem("interContratante")); 
        setContratante(contratante);
        obtenerNotas(contratante);
    }, []);

    const obtenerNotas = (contratante) => {
      Api.getNotesByDocument(props.documento, contratante.folio)
      .then((res) => {
          setNotas(res.data);
      })
      .catch((err) => {
          return err;
      })
    }

    const handleCloseNotas = () => {
        props.onClose();
    }

    const openAgregaNotas = () => {
        setNotasForm(true);
    };

    const guardaNotas = (values) => {
        Api.setNoteByDocument(props.documento, values.nota, ' ', contratante.folio)
        .then((res) => {
          closeAgregaNotas();
          obtenerNotas(contratante);
        })
        .catch((err) => {
            console.error(err.message);
        })
    };
    
    const validationNotas = yup.object().shape({
      nota: yup
        .string()
        .required("Se debe registrar una nota para guardar")
    });

    const closeAgregaNotas = () => {
        setNotasForm(false);
    };

    return (
      <Box className="pdf-container">
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
          <Stack className="close-stack">
            <IconButton size="small" 
              onClick={handleCloseNotas}>
              <CloseIcon className="gray-icon"/>
            </IconButton>
            <p className="cerrar-notas-text">Cerrar</p>
          </Stack>
        </Grid>
        <Grid container direction="row">
          <Grid item lg={12} xl={12} className="padding-box-agregar">
            <Button variant="standard" size="small" startIcon={<AddCircleOutlineIcon className="blue-icon"/>}
              onClick={openAgregaNotas}>
              <p className="blue-icon texto-add text-transform-none">Agregar nota</p>
            </Button>
          </Grid>
        </Grid>
        { notasForm && 
          <Formik
            validateOnChange={true}
            initialValues={{
              nota: '',
            }}
            validationSchema={validationNotas}
            onSubmit={(values, { setSubmitting }) => {
              guardaNotas(values);
              setSubmitting(false);
            }}>
            {({ handleChange, touched, errors, isSubmitting  }) => (
              <Form>
                <Grid container direction="row" className="padding-box-datos">
                  <Grid item lg={12} xl={12} className="notas-textfield">
                    <TextField variant="standard" fullWidth InputProps={{ disableUnderline: true }}
                      id="nota"
                      name="nota"
                      label="Ingresa tus comentarios"
                      multiline
                      rows={8}
                      placeholder="Ingresa tus comentarios"
                      onChange={handleChange}
                      helperText={errors.nota && touched.nota ? errors.nota : ""}
                      error={!!(errors.nota && touched.nota ? errors.nota : "")}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" className="p-botones-form" justifyContent="flex-end" alignItems="flex-start">
                  <Button variant="outlined" className="boton-notas-form" onClick={closeAgregaNotas}>
                    <p className="blue-icon texto-add text-transform-none">Cancelar</p>
                  </Button>
                  <Button variant="contained" className="boton-notas-form ml-24" type="submit" disabled={isSubmitting}>
                    <p className="blue-icon texto-add text-transform-none w-text">Guardar</p>
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>

        }
        { notas.length > 0 ? 
          notas.map((nota, i) => (
            <Grid container direction="row" key={i}>
              <Grid item lg={12} xl={12} className="padding-box-datos">
                <Box className="notas-box">
                  <Grid container direction="row" className="header-notas" justifyContent="space-between">
                    <Stack>{nota.ejecutivoRegistra}</Stack>
                    <Stack>{nota.fechaRegistro}</Stack>
                  </Grid>
                    {nota.descripcion}
                </Box>
              </Grid>
            </Grid>
          ))
          :
          <Grid container direction="row">
            <Grid item lg={12} xl={12} className="center-content">
              <Box className="center-content">
                <p className="sin-notas-text">A&uacute;n no tenemos notas agregadas a esta solicitud</p>
              </Box>
            </Grid>
          </Grid>

        }
        
      </Box>
    );
  };

  export default NotesViewer;