import * as React from "react"
import Grid from "@mui/material/Grid"
import Switch from "@mui/material/Switch"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { Box, FormControl, MenuItem } from "@mui/material"
import { Form, Formik } from "formik"
import {
  validationFormPasoUno,
  validationFormPasoUnoF3,
} from "./ValidationForm"
import CustomTextField from "../CustomFields/CustomTextField"
import CustomSelect from "../CustomFields/CustomSelect"
import {
  CMB_MENOS_UNO,
  INTER_CONTRATANTE,
  FECHA_VALIDA,
  MAYOR_EDAD,
  CP_INVALIDO,
  MAX_LENGTH_CP,
  paises,
  ocupaciones,
} from "../Utils/Constantes"
import { handleMaxLength } from "../Utils/FieldValidationFunctions"
import { maxLength, validaMayorEdad, validaResponse } from "../Utils/Utils"
import CustomDatePickerLab from "../CustomFields/CustomDatePickerLab"
import CustomTextArea from "../CustomFields/CustomTextArea"
import Api from "../../components/Api/Api"
import { styled } from "@mui/material/styles"
import { useEffect } from "react"
import Loading from "../Commons/Loader"
import TextField from "@mui/material/TextField"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useField } from "formik"
import esLocale from "date-fns/locale/es"

function CustomDatePickerPasoUno(props) {
  const [field, meta] = useField(props)
  const errorText = meta.error ? meta.error : ""
  const color = "#039ECC"
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
        <DesktopDatePicker
          inputFormat="dd/MM/yyyy"
          //format="dd/MM/yyyy"
          {...field}
          {...props}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                sx={{
                  svg: { color },
                }}
                className="w-100"
                helperText={errorText}
                error={!!errorText}
              />
            )
          }}
        />
      </LocalizationProvider>
    </>
  )
}

export default function PasoUno(props) {
  const dataContratante = JSON.parse(sessionStorage.getItem("interContratante"));
  const [checked, setChecked] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);
  const [colonias, setColonias] = React.useState([]);
  const [validFN, setValidFN] = React.useState(true);
  const [contratante, setContratante] = React.useState({});
  const [isLoading, setLoading] = React.useState(true);
  const [openLoader, setOpenLoader] = React.useState(false);

  const handleChanges = (event) => {
    setChecked(event.target.checked)
  }
  const handleChanges2 = (event) => {
    setChecked2(event.target.checked)
  }

  const handleOnChangePais = (event, setFieldValue) => {
    console.log(dataContratante.cp);
    const {
      target: { value, name },
    } = event
    setFieldValue(name, value)
    if (dataContratante.cp) {
      Api.consultaCP(dataContratante.cp)
        .then((res) => {
          setColonias(res.data)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const handleChangeFechaNacimiento = (fecha, setFieldValue, setFieldError) => {
    if (fecha instanceof Date && !isNaN(fecha)) {
      setFieldValue("fechanacimiento", fecha)
      if (validaMayorEdad(fecha)) {
      } else {
        setFieldError("fechanacimiento", MAYOR_EDAD)
      }
    } else {
      setFieldError("fechanacimiento", FECHA_VALIDA)
    }
  }

  const handleChangeCP = (event, setFieldValue, setFieldError) => {
    const cp = maxLength(event.target.value, MAX_LENGTH_CP);
    dataContratante.cp = cp;
    setFieldValue("cp", cp)
    if (cp.length === MAX_LENGTH_CP) {
      window.sessionStorage.setItem(
        INTER_CONTRATANTE,
        JSON.stringify(dataContratante)
      )
      Api.validaCP(cp)
        .then((res) => {
          if (validaResponse(res) && res.data.existe) {
            Api.consultaCP(cp)
              .then((res) => {
                setColonias(res.data)
              })
              .catch((error) => {
                console.log(error)
              })
          } else {
            setFieldError("cp", CP_INVALIDO)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
    }
  }

  /* const handleChangeUno = (event) => {
     setUno(event.target.value);
   };*/
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }))

  const handleOnChangeGenero = (event, setFieldValue) => {
    const { target: { value, name } } = event;
    setFieldValue(name, value);
  };


  const handleContinuar = (values, setSubmitting) => {
    if (values.genero == -1){
      window.dataLayer.push({
        event : "medico_seguro_datos_contratante",
        no_cotizacion: dataContratante.folio,   
        telefono : values.cel,
        email : values.email,     
        nacionalidad: values.nacionalidad,
        pais: values.pais,
        cp: values.cp,
        servidor_publico: values.puesto ? 'Si' : 'No',
        servidor_publico_puesto: values.puesto,
        actividad_peligrosa: '',
        boton_contratante:'Continuar'
      })
    } else {
      window.dataLayer.push({
        event : "medico_seguro_datos_contratante",
        no_cotizacion: dataContratante.folio,   
        telefono : values.cel,
        email : values.email,     
        nacionalidad: values.nacionalidad,
        pais: values.pais,
        cp: dataContratante.cp,
        servidor_publico: values.servPublico ? 'Si' : 'No',
        servidor_publico_puesto: values.servPublico,
        actividad_peligrosa: values.decripMaquinariaPesada ? 'Si' : 'No',
        boton_contratante:'Continuar'
      })
    }
    window.dataLayer.push({
      event: "form_step_9_submit",
      cp: contratante.cp,
      rfc: values.rfc,
      curp: values.curp,
      nombre_completo:
        values.nombre + " " + values.apellidop + " " + values.apellidom,
    })
    const currentTitular = contratante.asegurados.find(
      (asegurado) => asegurado.titular === true
    )
    contratante.nombre = values.nombre
    contratante.aPaterno = values.apellidop
    contratante.aMaterno = values.apellidom
    contratante.fechanacimiento = values.fechanacimiento
    contratante.pais = values.pais
    contratante.nacionalidad = values.nacionalidad
    contratante.rfc = values.rfc
    contratante.curp = values.curp
    contratante.cel = values.cel
    contratante.email = values.email

    contratante.cp = values.cp
    contratante.colonia = values.colonia
    contratante.municipio = values.municipio
    contratante.estado = values.estado

    contratante.calle = values.calle
    contratante.noInt = values.numin
    contratante.noExt = values.numex

    contratante.puesto = values.puesto
    contratante.periodoIni = values.periodoIni
    contratante.periodoFin = values.periodoFin
    contratante.ultimoPaso = 9
    if (contratante.tipoFlujo === 3) {
      //aqui se guardar primero el contratante y en el paso 2 el titular
      Api.actualizaContratante(contratante.id, contratante)
        .then((res) => {
          Api.getContratante(contratante.folio)
            .then((res) => {
              setOpenLoader(false)
              window.sessionStorage.setItem(
                INTER_CONTRATANTE,
                JSON.stringify(res.data.contratante)
              )
              setSubmitting(false)
              props.handleContinuar()
            })
            .catch((error) => {
              setOpenLoader(false)
              setSubmitting(false)
              console.log(error)
            })
        })
        .catch((error) => {
          setOpenLoader(false)
          setSubmitting(false)
          console.log(error)
        })
    } else {
      currentTitular.nombre = values.nombre
      currentTitular.apaterno = values.apellidop
      currentTitular.amaterno = values.apellidom
      currentTitular.fechanacimiento = values.fechanacimiento
      currentTitular.genero = values.genero
      currentTitular.pais = values.pais
      currentTitular.nacionalidad = values.nacionalidad
      currentTitular.rfc = values.rfc
      currentTitular.curp = values.curp
      currentTitular.direccion = values.calle
      currentTitular.colonia = values.colonia
      currentTitular.municipio = values.municipio
      currentTitular.estado = values.estado
      currentTitular.noInt = values.numin
      currentTitular.noExt = values.numex
      currentTitular.ocupacion = values.ocupacion
      currentTitular.puesto = values.puesto
      currentTitular.giroEmpresa = values.giroEmpresa
      currentTitular.decripMaquinariaPesada = values.decripMaquinariaPesada
      currentTitular.puestoServPublico = values.servPublico
      currentTitular.periodoIni = values.periodoIni
      currentTitular.periodoFin = values.periodoFin
      //aqui se guarda contra y titular con la misma info
      Api.actualizaContratante(contratante.id, contratante)
        .then((res) => {
          Api.actualizaAsegurado(contratante.id, currentTitular)
            .then((res) => {
              Api.getContratante(contratante.folio)
                .then((res) => {
                  setOpenLoader(false)
                  window.sessionStorage.setItem(
                    INTER_CONTRATANTE,
                    JSON.stringify(res.data.contratante)
                  )
                  setSubmitting(false)
                  props.handleContinuar()
                })
                .catch((error) => {
                  setOpenLoader(false)
                  setSubmitting(false)
                  console.log(error)
                })
            })
            .catch((error) => {
              setOpenLoader(false)
              setSubmitting(false)
              console.log(error)
            })
        })
        .catch((error) => {
          setOpenLoader(false)
          setSubmitting(false)
          console.log(error)
        })
    }
  }

  const getOcupacionTitular = (currentContratante) => {
    const currentTitular = currentContratante.asegurados.find(
      (asegurado) => asegurado.titular === true
    )
    return currentTitular.ocupacion
  }

  const getPuestoTitular = (currentContratante) => {
    const currentTitular = currentContratante.asegurados.find(
      (asegurado) => asegurado.titular === true
    )
    return currentTitular.puesto
  }

  const getGiroEmpresaTitular = (currentContratante) => {
    const currentTitular = currentContratante.asegurados.find(
      (asegurado) => asegurado.titular === true
    )
    return currentTitular.giroEmpresa
  }

  function handleChangeCPSubmit(values, setFieldError, setSubmitting) {
    const cp = maxLength(values.cp, MAX_LENGTH_CP)
    if (cp.length === MAX_LENGTH_CP) {
      Api.validaCP(cp)
        .then((res) => {
          if (validaResponse(res) && res.data.existe) {
            handleContinuar(values, setSubmitting)
          } else {
            setFieldError("cp", CP_INVALIDO)
            setSubmitting(false)
            setOpenLoader(false)
          }
        })
        .catch((error) => {
          setFieldError("cp", CP_INVALIDO)
          setSubmitting(false)
          setOpenLoader(false)
          console.log(error)
        })
    } else {
      setFieldError("cp", CP_INVALIDO)
      setSubmitting(false)
      setOpenLoader(false)
    }
  }

  useEffect(() => {
    window.dataLayer.push({
      event: "form_step_9_view",
    })
    if (window.sessionStorage.getItem(INTER_CONTRATANTE)) {
      const contratanteSess = JSON.parse(
        window.sessionStorage.getItem(INTER_CONTRATANTE)
      )
      setContratante(contratanteSess)
      if (contratanteSess.fechanacimiento && contratanteSess.tipoFlujo === 3) {
        setValidFN(false)
      }
      if (contratanteSess.cp !== null) {
        Api.consultaCP(contratanteSess.cp)
          .then((res) => {
            setColonias(res.data)
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)
            console.log(error)
          })
      } else {
        setLoading(false)
      }
    }
    /* Especifica cómo sanear este efecto:
    return function cleanup() {
    };*/
  }, [])

  return (
    <>
      {!isLoading && (
        <Formik
          validateOnChange={true}
          innerRef={props.formRef}
          initialValues={{
            nombre: dataContratante.nombre,
            apellidop:
              dataContratante.aPaterno !== null ? dataContratante.aPaterno : "",
            apellidom:
              dataContratante.aMaterno !== null ? dataContratante.aMaterno : "",
            fechanacimiento:
              dataContratante.fechanacimiento !== null
                ? dataContratante.fechanacimiento
                : new Date(),
            genero:
              dataContratante.genero !== null ? dataContratante.genero : -1,
            pais: dataContratante.pais !== null ? dataContratante.pais : -1,
            municipio:
              dataContratante.municipio !== null
                ? dataContratante.municipio
                : -1,
            colonia:
              dataContratante.colonia !== null ? dataContratante.colonia : -1,
            estado:
              dataContratante.estado !== null ? dataContratante.estado : -1,
            ocupacion:
              dataContratante.tipoFlujo !== 3 &&
              getOcupacionTitular(dataContratante)
                ? getOcupacionTitular(dataContratante)
                : -1,
            nacionalidad:
              dataContratante.nacionalidad !== null
                ? dataContratante.nacionalidad
                : "",
            rfc: dataContratante.rfc !== null ? dataContratante.rfc : "",
            curp: dataContratante.curp !== null ? dataContratante.curp : "",
            cel: dataContratante.telefono,
            email: dataContratante.correo,
            cp: dataContratante.cp,
            calle: dataContratante.calle !== null ? dataContratante.calle : "",
            numin: dataContratante.noInt !== null ? dataContratante.noInt : "",
            numex: dataContratante.noExt !== null ? dataContratante.noExt : "",
            puesto:
              dataContratante.tipoFlujo !== 3 &&
              getPuestoTitular(dataContratante)
                ? getPuestoTitular(dataContratante)
                : "",
            giroEmpresa:
              dataContratante.tipoFlujo !== 3 &&
              getGiroEmpresaTitular(dataContratante)
                ? getGiroEmpresaTitular(dataContratante)
                : "",
          }}
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            setSubmitting(true)
            setOpenLoader(true)
            handleChangeCPSubmit(values, setFieldError, setSubmitting)
          }}
          validationSchema={
            dataContratante.tipoFlujo !== 3
              ? validationFormPasoUno
              : validationFormPasoUnoF3
          }
        >
          {({
            values,
            submitForm,
            setFieldValue,
            handleChange,
            errors,
            isSubmitting,
            setFieldError,
            touched,
            setFieldTouched,
          }) => (
            <Form>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Box sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <span className="text-preguntas">
                      {dataContratante.tipoFlujo !== 3
                        ? "¿Nos ayudas con esta info?"
                        : "¿Nos ayudas con la info del contratante?"}
                    </span>
                  </Grid>

                  {dataContratante.tipoFlujo !== 3 && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Stack
                            direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                            spacing={1}
                          >
                            <CustomTextField
                              id="nombre"
                              name="nombre"
                              type="text"
                              placeholder="Nombre"
                            />
                            <CustomTextField
                              id="apellidop"
                              name="apellidop"
                              type="text"
                              placeholder="Primer apellido"
                            />
                          </Stack>
                        </Grid>
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Stack
                            direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                            spacing={1}
                          >
                            <CustomTextField
                              id="apellidom"
                              name="apellidom"
                              type="text"
                              placeholder="Segundo apellido"
                            />
                            <FormControl fullWidth>
                              <CustomDatePickerLab
                                id="fechanacimiento"
                                name="fechanacimiento"
                                type="text"
                                disabled={validFN}
                                placeholder="Fecha Nacimiento"
                                className="grid-item-input w-100"
                                disableFuture
                                onChange={(newValue) =>
                                  handleChangeFechaNacimiento(
                                    newValue,
                                    setFieldValue,
                                    setFieldError
                                  )
                                }
                              />
                            </FormControl>
                          </Stack>
                        </Grid>
                      </Box>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box sx={{ mt: 2 }}>
                          <Stack
                            direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                            spacing={1}
                          >
                            <FormControl fullWidth>
                              <CustomSelect
                                id="genero"
                                name="genero"
                                disabled
                                onChange={(event) =>
                                  handleOnChangeGenero(event, setFieldValue)
                                }
                              >
                                <MenuItem value={CMB_MENOS_UNO}>
                                  Sexo de nacimiento
                                </MenuItem>
                                <MenuItem value={"Masculino"}>Hombre</MenuItem>
                                <MenuItem value={"Femenino"}>Mujer</MenuItem>
                              </CustomSelect>
                            </FormControl>
                            <FormControl fullWidth>
                              <CustomSelect
                                id="pais"
                                name="pais"
                                onChange={(event) =>
                                  handleOnChangePais(event, setFieldValue)
                                }
                              >
                                <MenuItem value={CMB_MENOS_UNO}>
                                  Pa&iacute;s de nacimiento
                                </MenuItem>
                                {paises.map((pais, i) => (
                                  <MenuItem key={i} value={pais}>
                                    {pais}
                                  </MenuItem>
                                ))}
                              </CustomSelect>
                            </FormControl>
                          </Stack>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack
                              direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                              spacing={1}
                            >
                              <CustomTextField
                                id="nacionalidad"
                                name="nacionalidad"
                                type="text"
                                placeholder="Nacionalidad"
                              />
                              <CustomTextField
                                id="rfc"
                                name="rfc"
                                type="text"
                                placeholder="RFC"
                              />
                            </Stack>
                          </Grid>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack
                              direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                              spacing={1}
                            >
                              <CustomTextField
                                id="curp"
                                name="curp"
                                type="text"
                                placeholder="CURP"
                              />
                              <CustomTextField
                                id="cel"
                                name="cel"
                                type="text"
                                placeholder="Celular"
                                onChange={(event) =>
                                  handleMaxLength(
                                    event,
                                    setFieldValue,
                                    "cel",
                                    10
                                  )
                                }
                              />
                            </Stack>
                          </Grid>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Stack
                              direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                              spacing={1}
                            >
                              <CustomTextField
                                id="email"
                                name="email"
                                type="text"
                                placeholder="Correo electr&oacute;nico"
                              />
                            </Stack>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  )}

                  {dataContratante.tipoFlujo === 3 && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Stack
                            direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                            spacing={1}
                          >
                            <CustomTextField
                              id="nombre"
                              name="nombre"
                              type="text"
                              placeholder="Nombre"
                            />
                            <CustomTextField
                              id="apellidop"
                              name="apellidop"
                              type="text"
                              placeholder="Primer apellido"
                            />
                          </Stack>
                        </Grid>
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Stack
                            direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                            spacing={1}
                          >
                            <CustomTextField
                              id="apellidom"
                              name="apellidom"
                              type="text"
                              placeholder="Segundo apellido"
                            />
                            <FormControl fullWidth>
                              <CustomDatePickerPasoUno
                                id="fechanacimiento"
                                name="fechanacimiento"
                                type="text"
                                placeholder="Fecha Nacimiento"
                                className="grid-item-input w-100"
                                disableFuture
                                onChange={(newValue) =>
                                  handleChangeFechaNacimiento(
                                    newValue,
                                    setFieldValue,
                                    setFieldError
                                  )
                                }
                              />
                            </FormControl>
                          </Stack>
                        </Grid>
                      </Box>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box sx={{ mt: 2 }}>
                          <Stack
                            direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                            spacing={1}
                          >
                            <FormControl fullWidth>
                              <CustomSelect
                                id="pais"
                                name="pais"
                                onChange={(event) =>
                                  handleOnChangePais(event, setFieldValue)
                                }
                              >
                                <MenuItem value={CMB_MENOS_UNO}>
                                  Pa&iacute;s de nacimiento
                                </MenuItem>
                                {paises.map((pais, i) => (
                                  <MenuItem key={i} value={pais}>
                                    {pais}
                                  </MenuItem>
                                ))}
                              </CustomSelect>
                            </FormControl>
                            <CustomTextField
                              id="nacionalidad"
                              name="nacionalidad"
                              type="text"
                              placeholder="Nacionalidad"
                            />
                          </Stack>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack
                              direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                              spacing={1}
                            >
                              <CustomTextField
                                id="rfc"
                                name="rfc"
                                type="text"
                                placeholder="RFC"
                              />
                              <CustomTextField
                                id="curp"
                                name="curp"
                                type="text"
                                placeholder="CURP"
                              />
                            </Stack>
                          </Grid>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack
                              direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                              spacing={1}
                            >
                              <CustomTextField
                                id="cel"
                                name="cel"
                                type="text"
                                placeholder="Celular"
                                onChange={(event) =>
                                  handleMaxLength(
                                    event,
                                    setFieldValue,
                                    "cel",
                                    10
                                  )
                                }
                              />
                              <CustomTextField
                                id="email"
                                name="email"
                                type="text"
                                placeholder="Correo electr&oacute;nico"
                              />
                            </Stack>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  )}

                  <Box sx={{ mt: 4 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <span className="text-preguntas">
                        {dataContratante.tipoFlujo !== 3
                          ? "¿Cúal es tu domicilio fiscal?"
                          : "¿Cúal es el domicilio fiscal del contratante?"}
                      </span>
                    </Grid>

                    <Box sx={{ mt: 2 }}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Stack
                          direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                          spacing={1}
                        >
                          <CustomTextField
                            id="cp"
                            name="cp"
                            type="text"
                            placeholder="C.P."
                            className="grid-item-input"
                            onChange={(event) =>
                              handleChangeCP(
                                event,
                                setFieldValue,
                                setFieldError
                              )
                            }
                          />
                          <FormControl fullWidth>
                            <CustomSelect
                              id="colonia"
                              name="colonia"
                              onChange={(event) =>
                                handleOnChangePais(event, setFieldValue)
                              }
                            >
                              <MenuItem value={CMB_MENOS_UNO}>Colonia</MenuItem>
                              {colonias.map((colonia, i) => (
                                <MenuItem key={i} value={colonia.d_asenta}>
                                  {colonia.d_asenta}
                                </MenuItem>
                              ))}
                            </CustomSelect>
                          </FormControl>
                        </Stack>
                      </Grid>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Stack
                          direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                          spacing={1}
                        >
                          <FormControl fullWidth>
                            <CustomSelect
                              id="municipio"
                              name="municipio"
                              onChange={(event) =>
                                handleOnChangePais(event, setFieldValue)
                              }
                            >
                              <MenuItem value={CMB_MENOS_UNO}>
                                Municipio
                              </MenuItem>
                              {colonias.length > 0 && (
                                <MenuItem key={0} value={colonias[0].d_mnpio}>
                                  {colonias[0].d_mnpio}
                                </MenuItem>
                              )}
                            </CustomSelect>
                          </FormControl>
                          <FormControl fullWidth>
                            <CustomSelect
                              id="estado"
                              name="estado"
                              onChange={(event) =>
                                handleOnChangePais(event, setFieldValue)
                              }
                            >
                              <MenuItem value={CMB_MENOS_UNO}>Estado</MenuItem>
                              {colonias.length > 0 && (
                                <MenuItem key={0} value={colonias[0].d_estado}>
                                  {colonias[0].d_estado}
                                </MenuItem>
                              )}
                            </CustomSelect>
                          </FormControl>
                        </Stack>
                      </Grid>
                    </Box>

                    <Box sx={{ mt: 1 }}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomTextField
                          id="calle"
                          name="calle"
                          type="text"
                          placeholder="Calle"
                        />
                      </Grid>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Stack
                          direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                          spacing={1}
                        >
                          <CustomTextField
                            id="numex"
                            name="numex"
                            type="text"
                            placeholder="Número Exterior"
                          />
                          <CustomTextField
                            id="numin"
                            name="numin"
                            type="text"
                            placeholder="Número Interior"
                          />
                        </Stack>
                      </Grid>
                    </Box>
                    {/*Seccion que se agrega en titular y contratatnte misma persona*/}
                    {dataContratante.tipoFlujo !== 3 && (
                      <Box sx={{ mt: 4 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <span className="text-preguntas">
                            &iquest;A qu&eacute; te dedicas?
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Stack
                            direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                            spacing={1}
                          >
                            <FormControl fullWidth>
                              <CustomSelect
                                id="ocupacion"
                                name="ocupacion"
                                onChange={(event) =>
                                  handleOnChangePais(event, setFieldValue)
                                }
                              >
                                <MenuItem value={CMB_MENOS_UNO}>
                                  Ocupación
                                </MenuItem>
                                {ocupaciones.map((ocupacion, i) => (
                                  <MenuItem key={i} value={ocupacion.valor}>
                                    {ocupacion.valor}
                                  </MenuItem>
                                ))}
                              </CustomSelect>
                            </FormControl>
                          </Stack>
                        </Grid>
                        <Box sx={{ mt: 2 }}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack
                              direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                              spacing={1}
                            >
                              <CustomTextField
                                id="puesto"
                                name="puesto"
                                type="text"
                                placeholder="Puesto que desempeña"
                              />
                              <CustomTextField
                                id="giroEmpresa"
                                name="giroEmpresa"
                                type="text"
                                placeholder="Giro de la empresa"
                              />
                            </Stack>
                          </Grid>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <span className="text-preguntas">
                              Oye, &iquest;y trabajas con maquinaria pesada,
                              herramientas de riesgo o sustancias peligrosas?
                            </span>
                          </Grid>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography>NO</Typography>
                            <AntSwitch
                              checked={checked2}
                              onChange={handleChanges2}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                            <Typography>SI</Typography>
                          </Stack>
                          {checked2 === true && (
                            <Box sx={{ mt: 2 }}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Stack
                                  direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                                  spacing={1}
                                >
                                  <CustomTextArea
                                    minRows={6}
                                    id="decripMaquinariaPesada"
                                    name="decripMaquinariaPesada"
                                    type="text"
                                    placeholder="Danos un poco m&aacute;s de informaci&oacute;n"
                                  />
                                </Stack>
                              </Grid>
                            </Box>
                          )}
                          <Box sx={{ mt: 4 }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <span className="text-preguntas">
                                &iquest;Has chambeado como servidor
                                p&uacute;blico?
                              </span>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <Typography>NO</Typography>
                                <AntSwitch
                                  checked={checked}
                                  onChange={handleChanges}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                                <Typography>SI</Typography>
                              </Stack>
                            </Grid>

                            {checked === true && (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Box sx={{ mt: 4 }}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                  >
                                    <span className="text-preguntas">
                                      &iquest;En qu&eacute; puesto?
                                    </span>
                                  </Grid>
                                  <Box sx={{ mt: 2 }}>
                                    <Stack
                                      direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                                      spacing={1}
                                    >
                                      <CustomTextField
                                        id="servPublico"
                                        name="servPublico"
                                        type="text"
                                        placeholder="Puesto "
                                      />
                                    </Stack>
                                  </Box>
                                </Box>
                                <Box sx={{ mt: 2 }}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                  >
                                    <span className="text-preguntas">
                                      &iquest;Cu&aacute;ndo fue?
                                    </span>
                                  </Grid>
                                  <Box sx={{ mt: 2 }}>
                                    <Stack
                                      direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                                      spacing={1}
                                    >
                                      <CustomTextField
                                        id="periodoIni"
                                        name="periodoIni"
                                        type="text"
                                        placeholder="Desde"
                                      />
                                      <CustomTextField
                                        id="periodoFin"
                                        name="periodoFin"
                                        type="text"
                                        placeholder="Hasta"
                                      />
                                    </Stack>
                                  </Box>
                                </Box>
                              </Grid>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    )}

                    {dataContratante.tipoFlujo === 3 && (
                      <Box sx={{ mt: 4 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <span className="text-preguntas">
                            &iquest;El contratante ha chambeado como servidor
                            p&uacute;blico?
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography>NO</Typography>
                            <AntSwitch
                              checked={checked}
                              onChange={handleChanges}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                            <Typography>SI</Typography>
                          </Stack>
                        </Grid>

                        {checked === true && (
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{ mt: 4 }}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <span className="text-preguntas">
                                  &iquest;Que puesto desempe&ntilde;as?
                                </span>
                              </Grid>
                              <Box sx={{ mt: 2 }}>
                                <Stack
                                  direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                                  spacing={1}
                                >
                                  <CustomTextField
                                    id="puesto"
                                    name="puesto"
                                    type="text"
                                    placeholder="Puesto "
                                  />
                                </Stack>
                              </Box>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <span className="text-preguntas">
                                  &iquest;En que periodo de tiempo?
                                </span>
                              </Grid>
                              <Box sx={{ mt: 2 }}>
                                <Stack
                                  direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                                  spacing={1}
                                >
                                  <CustomTextField
                                    id="periodoIni"
                                    name="periodoIni"
                                    type="text"
                                    placeholder="Desde"
                                  />
                                  <CustomTextField
                                    id="periodoFin"
                                    name="periodoFin"
                                    type="text"
                                    placeholder="Hasta"
                                  />
                                </Stack>
                              </Box>
                            </Box>
                          </Grid>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
             {/*  <div className="espacio w-100">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Button
                    className="w-100"
                    variant="contained"
                    id="next"
                    onClick={submitForm}
                    disabled={isSubmitting}
                  >
                    Continuar
                  </Button>
                </Grid>
              </div> */}
            </Form>
          )}
        </Formik>
      )}
      <Loading open={openLoader} onClose={() => setOpenLoader(false)} />
    </>
  )
}
