import { Grid, Box } from '@mui/material';
import Logo from '../../../assets/landing/Logo.svg'

const styles = {
  footer: {
    color: 'white',
    minHeight: '60px',
    backgroundColor: '#041431',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10vw'
  },
  mobileFooter: {
    backgroundColor: '#041431',
    height: '60px',
    width: '100vw',
    alignItems: 'center',
    color: '#FFF',
  },
  copyrigth: {
    fontSize: '10px'
  },
  logoInterWhite: {
    height: '32px'
  },
  flex: {
    display: 'flex',
  },
  end: {
    display: 'flex',
    justifyContent: 'end'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  }
}

const CrmFooter = (props) => {
  const { isMobile } = props;
  return (
    <Box style={isMobile ? styles.mobileFooter : styles.footer}>
      <Grid container>
        <Grid item xs="12" sm="12" md="6" lg="6" xl="6" style={isMobile ? styles.center : styles.flex}>
          <img src={Logo} alt="" style={styles.logoInterWhite}/> 
        </Grid>
        <Grid item xs="12" sm="12" md="6" lg="6" xl="6" style={isMobile ? styles.center : styles.end}>
          <p style={styles.copyrigth}>Copyrigth 2021 @ interprotección</p>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CrmFooter;