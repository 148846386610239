import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import LogoInter from "../../assets/img/footer/LogoInter-w.svg";
import LogoApple from "../../assets/img/footer/App_Store.svg";
import LogoGoogle from "../../assets/img/footer/Google_play.svg";
import LFace from "../../assets/img/footer/FaceBook.svg";
import LInst from "../../assets/img/footer/Instagram.svg";
import LIn from "../../assets/img/footer/LinkedIn.svg";
import LYou from "../../assets/img/footer/YouTube.svg";
import LTwi from "../../assets/img/footer/X.svg";
import { useLocation } from "react-router-dom";
import { UtmURls } from "./../../components/Utils/Utils";

export default function Footer(props) {
  const location = useLocation();
  const [isCRM, setIsCRM] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("/crm")) {
      setIsCRM(true);
    } else {
      setIsCRM(false);
    }
  }, [location.pathname]);

  return (
    <>
      {!isCRM && (
        <div className="footer">
          <Grid
            maxWidth={"xl"}
            sx={{
              pt: "24px",
              pb: "24px",
              pl: { xs: "24px", sm: "16px", md: "24px", lg: "24px" },
              pr: { xs: "24px", sm: "16px", md: "24px", lg: "24px" },
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={4} md={3} lg={3}
                sx={{ pr: { xs: "0px", sm: "24px", md: "40px", lg: "40px" } }}
              >
                <Box
                  component={"img"}
                  src={LogoInter}
                  sx={{
                    width: { xs: 180, md: 160 },
                    maxWidth: { xs: 180, md: 180 },
                  }}
                  alt={"Inter, seguro, seguro"}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}
                sx={{
                  pl: { xs: "0px", sm: "0px", md: "24px", lg: "0px" },
                  pr: { xs: "0px", sm: "24px", md: "24px", lg: "40px" },
                  pt: {xs:'24px',sm:'0px',md:'0px',lg:'0px'},pb: 0,
                }}
              >
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography
                    /* variant={'subtitle1'} */
                    className="header-footer"
                    color={"white"}
                    fontWeight={"bold"}
                  >
                    {"Productos"}
                  </Typography>
                  <Box pb={3} />
                  <Link
                    href={UtmURls("https://cocheseguro.inter.mx/inicio")}
                    target="_blank"
                    /* variant={'subtitle1'} */
                    className="body-footer"
                    style={{
                      cursor: "pointer",
                      color: "white",
                      textDecoration: "none",
                    }}
                    onClick={() =>
                      window.dataLayer.push({
                        event: "ev_btn_footer",
                        option: "Coche Seguro",
                      })
                    }
                  >
                    Coche Seguro
                  </Link>
                  <Link
                    href={UtmURls("https://mascotasegura.inter.mx/")}
                    target="_blank"
                    /* variant={'subtitle1'} */
                    className="body-footer"
                    style={{
                      cursor: "pointer",
                      color: "white",
                      textDecoration: "none",
                    }}
                    onClick={() =>
                      window.dataLayer.push({
                        event: "ev_btn_footer",
                        option: "Mascota Segura",
                      })
                    }
                  >
                    Mascota Segura
                  </Link>
                  <Link
                    href={UtmURls("https://motoseguro.inter.mx/")}
                    target="_blank"
                    /* variant={'subtitle1'} */
                    className="body-footer"
                    style={{
                      cursor: "pointer",
                      color: "white",
                      textDecoration: "none",
                    }}
                    onClick={() =>
                      window.dataLayer.push({
                        event: "ev_btn_footer",
                        option: "Moto Segura",
                      })
                    }
                  >
                    Moto Segura
                  </Link>
                </Box>
                <Box marginBottom={4} />
                <Typography
                  /* variant={'subtitle1'} */
                  className="header-footer"
                  color={"white"}
                  fontWeight={"bold"}
                >
                  {"Clientes"}
                </Typography>
                <Box pb={3} />
                <Link
                  href={"https://inter.mx/aviso-de-privacidad"}
                  target={"_blank"}
                  style={{ textDecoration: "none" }}
                  onClick={() =>
                    window.dataLayer.push({
                      event: "ev_btn_footer",
                      option: "Aviso de privacidad",
                    })
                  }
                >
                  <Typography
                    /* variant={'subtitle1'} */
                    className="body-footer"
                    color={"white"}
                  >
                    Aviso de privacidad
                  </Typography>
                </Link>
                <Link
                  href={"https://www.inter.mx/terminos-condiciones"}
                  target={"_blank"}
                  style={{ textDecoration: "none" }}
                  onClick={() =>
                    window.dataLayer.push({
                      event: "ev_btn_footer",
                      option: "Terminos y condiciones",
                    })
                  }
                >
                  <Typography
                    /* variant={'subtitle1'} */
                    className="body-footer"
                    color={"white"}
                  >
                    Términos y condiciones
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4} md={3}
                sx={{ pr: { xs: "0px", sm: "0px", md: "40px", lg: "40px" },
                      pt:{xs:'24px',sm:'0px'}}}
              >
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography
                    /* variant={'subtitle1'} */
                    className="header-footer"
                    color={"white"}
                    fontWeight={"bold"}
                    style={{ cursor: "pointer" }}
                  >
                    {"Contáctanos"}
                  </Typography>
                  <Box pb={3} />
                  <Link
                    href={`tel:55 442 46837`}
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                    onClick={() =>
                      window.dataLayer.push({
                        event: "ev_btn_footer",
                        option: "Telefono",
                      })
                    }
                  >
                    <Typography
                      /* variant={'subtitle1'} */
                      className="body-footer"
                      color={"white"}
                      style={{ cursor: "pointer" }}
                    >
                      55 442 46837
                    </Typography>
                  </Link>
                  <Link
                    href={`mailto:hola@inter.mx`}
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                    onClick={() =>
                      window.dataLayer.push({
                        event: "ev_btn_footer",
                        option: "Email",
                      })
                    }
                  >
                    <Typography
                      /* variant={'subtitle1'} */
                      className="body-footer"
                      color={"white"}
                      style={{ cursor: "pointer" }}
                    >
                      hola@inter.mx
                    </Typography>
                  </Link>
                  <Box className="body-footer pt-6" sx={{ pt: "24px" }}>
                    <p
                      href=""
                      target="_blank"
                      rel="noreferrer"
                      style={{ lineHeight: "19.5px", margin: 0 }}
                    >
                      Miguel de Cervantes Saavedra No. 301, Torre Sur Piso 17,
                      Colonia Granada, Delegación Miguel Hidalgo, C.P. 11520,
                      Ciudad de México, México
                    </p>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}
                sx={{
                  display: "flex",
                  flexDirection: {xs: "column", sm: "row", md: "column", lg: "column"},
                  justifyContent: {xs: "flex-start", sm: "flex-end", md: "flex-end"},
                }}
              >
                <Grid item xs={12} sm={4} md={12}>
                  <Box sx={{pr:{xs:'0px',sm:'24px',md:'0px',lg:'0px'}}}>
                    <Box 
                      sx={{
                        pt:{xs:'24px',sm:'40px',md:0,lg:0},
                        pb:{xs:'24px',sm:'16px',md:'24px',lg:'24px'}
                      }}>
                      <Typography
                        /* variant={'subtitle1'} */
                        className="header-footer"
                        color={"white"}
                        fontWeight={"bold"}
                      >
                        {"Descarga nuestra app"}
                      </Typography>
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"start"}
                      justifyContent={"space-between"}
                      alignSelf={"stretch"}
                    >
                      <Link
                        href="https://apps.apple.com/mx/app/intermx/id1548367767?l=en"
                        target={"_blank"}
                        style={{ textDecoration: "none" }}
                        onClick={() =>
                          window.dataLayer.push({
                            event: "ev_btn_footer",
                            option: "App Store",
                          })
                        }
                      >
                        <img src={LogoApple} alt="LogoApple" />
                      </Link>
                      <Link
                        href="https://play.google.com/store/apps/details?id=grut.inter.mx"
                        target={"_blank"}
                        style={{ textDecoration: "none" }}
                        onClick={() =>
                          window.dataLayer.push({
                            event: "ev_btn_footer",
                            option: "Google Play",
                          })
                        }
                      >
                        <img src={LogoGoogle} alt="LogoGoogle" />
                      </Link>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={12}>
                  <Box sx={{
                    pt:{xs:'24px',sm:'40px',md:'24px',lg:'24px'}
                    }} />
                    <Typography
                      /* variant={'subtitle1'} */
                      className="header-footer"
                      fontWeight={"bold"}
                      color={"white"}
                  >
                    {"Síguenos en redes sociales"}
                  </Typography>
                  <Box
                    pt={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      href="https://www.facebook.com/intermx"
                      target={"_blank"}
                      onClick={() =>
                        window.dataLayer.push({
                          event: "ev_social_networks",
                          option: "Facebook",
                        })
                      }
                    >
                      <img
                        src={LFace}
                        alt="Facebook"
                        style={{
                          margin:'auto',
                          cursor: "pointer",
                        }}
                      />
                    </Link>
                    <Link
                      href="https://www.instagram.com/intermx/"
                      target={"_blank"}
                      onClick={() =>
                        window.dataLayer.push({
                          event: "ev_social_networks",
                          option: "Instagram",
                        })
                      }
                    >
                      <img
                        src={LInst}
                        alt="Instagram"
                        style={{
                          margin:'auto',
                          cursor: "pointer",
                        }}
                      />
                    </Link>
                    <Link
                      href="https://www.linkedin.com/company/interproteccion/"
                      target={"_blank"}
                      onClick={() => {
                        window.dataLayer.push({
                          event: "ev_social_networks",
                          option: "LinkedIn",
                        });
                      }}
                    >
                      <img
                        src={LIn}
                        alt="Linkedin"
                        style={{
                          margin:'auto',
                          cursor: "pointer",
                        }}
                      />
                    </Link>
                    <Link
                      href="https://www.youtube.com/channel/UCZ8wH2JLzDYvvrN-JiG3roA/videos"
                      target={"_blank"}
                      onClick={() => {
                        window.dataLayer.push({
                          event: "ev_social_networks",
                          option: "Youtube",
                        });
                      }}
                    >
                      <img
                        src={LYou}
                        alt="Youtube"
                        style={{
                          margin:'auto',
                          cursor: "pointer",
                        }}
                      />
                    </Link>
                    <Link
                      href="https://twitter.com/intermx"
                      target={"_blank"}
                      onClick={() => {
                        window.dataLayer.push({
                          event: "ev_social_networks",
                          option: "Twitter",
                        });
                      }}
                    >
                      <img
                        src={LTwi}
                        alt="Twitter"
                        style={{
                          margin:'auto',
                          cursor: "pointer",
                        }}
                      />
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container columns={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                sx={{ textAlign: { xs: "start", sm: "center", md: "center",lg:"center" }}}
              >
                <Box className="footer-footer" 
                  sx={{ padding: "24px",
                  pt:{xs:'24px',sm:'40px',md:'40px',lg:'40px'},
                  pl:{xs:'0px',sm:'16px',md:'24px',lg:'24px'},pb:0,pr:0,
                  fontSize:{xs:'12px',sm:'10px',md:'10px',lg:'10px'},
                  lineHeight:{xs:'normal',sm:'20.04px',md:'20.04px',lg:'20.04px'},
                  fontWeight:{xs:400,sm:300,md:300,lg:300}
                }}>
                  Copyright {/* {new Date().getFullYear()} */}2023 ® Interprotección
              </Box>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}
