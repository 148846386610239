import * as yup from "yup";
import { CORREO_INVALIDO, CP_INVALIDO, FECHA_VALIDA, MAYOR_EDAD, TELEFONO_INVALIDO, VALOR_NUMERO } from "../Utils/Constantes";
import { validaMayorEdad } from "../Utils/Utils";

export const validationFormPasoUno = yup.object().shape({
  nombre: yup
    .string()
    .required("Ingrese el nombre")
    .min(1, "Ingrese el nombre")
    .max(20, "Debe tener como máximo 20 caracteres"),

  apellidop: yup
    .string()
    .required("Ingrese el apellido paterno")
    .min(1, "Ingrese el apellido paterno")
    .max(20, "Debe tener como máximo 20 caracteres"),

  apellidom: yup
    .string()
    .required("Ingrese el apellido materno")
    .min(1, "Ingrese el apellido materno")
    .max(20, "Debe tener como máximo 20 caracteres"),

  pais: yup
    .string()
    .required("Seleccione un país de nacimiento")
    .min(3, "Seleccione un país de nacimiento"),

  nacionalidad: yup
    .string()
    .required("Ingrese la nacionalidad")
    .min(1, "Ingrese la nacionalidad")
    .max(20, "Debe tener como máximo 20 caracteres"),

  colonia: yup
    .string()
    .required("Seleccione la colonia")
    .min(3, "Seleccione la colonia"),

  municipio: yup
    .string()
    .required("Seleccione el municipio")
    .min(3, "Seleccione el municipio"),

  estado: yup
    .string()
    .required("Seleccione el estado")
    .min(3, "Seleccione el estado"),

  rfc: yup
    .string()
    .required("Ingrese el rfc")
    .min(10, "Ingrese el rfc")
    .max(13, "Debe tener como máximo 13 caracteres"),

  curp: yup
    .string()
    .required("Ingrese el curp")
    .min(18, "Ingrese el curp")
    .max(18, "Debe tener como máximo 18 caracteres"),

  email: yup
    .string()
    .required("Ingrese el correo electrónico")
    .email(CORREO_INVALIDO)
    .min(1, "Ingrese el correo electrónico")
    .max(80, "Debe tener como máximo 80 caracteres"),

  cel: yup
    .string()
    .required("Ingrese el celular")
    .typeError("Ingrese el celular")
    .length(10, TELEFONO_INVALIDO)
    .test("formato", TELEFONO_INVALIDO, (val) => {
      var regex = /^\D*\d{10}$/
      return regex.test(val + "")
    }),

  calle: yup.string().required("Ingrese la calle").min(1, "Ingrese la calle"),

  numex: yup
    .string()
    .required("Ingrese el número exterior")
    .min(1, "Ingrese el número exterior")
    .test("formato", VALOR_NUMERO, (val) => {
      var regex = /^[0-9]+$/
      return regex.test(val + "")
    }),

  numin: yup.number().typeError("Ingrese el número interior"),

  ocupacion: yup
    .string()
    .required("Seleccione la ocupación")
    .nullable("Seleccione la ocupación")
    .min(3, "Seleccione la ocupación"),

  cp: yup
    .string()
    .required("Ingrese el código postal")
    .typeError(CP_INVALIDO)
    .length(5, CP_INVALIDO)
    .test("formato", CP_INVALIDO, (val) => {
      var regex = /^\D*\d{5}$/
      return regex.test(val)
    }),

  puesto: yup
    .string()
    .required("Ingrese el puesto")
    .min(1, "Ingrese el puesto"),

  giroEmpresa: yup
    .string()
    .required("Ingrese el giro de la empresa")
    .min(1, "Ingrese el giro de la empresa"),
})

export const validationFormPasoUnoF3 = yup.object().shape({
  nombre: yup
    .string()
    .required("Ingrese el nombre")
    .min(1, "Ingrese el nombre")
    .max(20, "Debe tener como máximo 20 caracteres"),

  apellidop: yup
    .string()
    .required("Ingrese el apellido paterno")
    .min(1, "Ingrese el apellido paterno")
    .max(20, "Debe tener como máximo 20 caracteres"),

  apellidom: yup
    .string()
    .required("Ingrese el apellido materno")
    .min(1, "Ingrese el apellido materno")
    .max(20, "Debe tener como máximo 20 caracteres"),

  fechanacimiento: yup
    .date()
    .required("Seleccione la fecha de nacimiento")
    .typeError(FECHA_VALIDA)
    .test("fechaValida", FECHA_VALIDA, (fecha) => {
      if (fecha instanceof Date && !isNaN(fecha)) {
        return true
      } else {
        return false
      }
    })
    .test("mayorEdad", MAYOR_EDAD, (val) => {
      if (validaMayorEdad(val)) {
        return true
      } else {
        return false
      }
    }),

  pais: yup
    .string()
    .required("Seleccione un país de nacimiento")
    .min(3, "Seleccione un país de nacimiento"),

  nacionalidad: yup
    .string()
    .required("Ingrese la nacionalidad")
    .min(1, "Ingrese la nacionalidad")
    .max(20, "Debe tener como máximo 20 caracteres"),

  cp: yup
    .string()
    .required("Ingrese el código postal")
    .typeError(CP_INVALIDO)
    .length(5, CP_INVALIDO)
    .test("formato", CP_INVALIDO, (val) => {
      var regex = /^\D*\d{5}$/
      return regex.test(val)
    }),

  colonia: yup
    .string()
    .required("Seleccione la colonia")
    .min(3, "Seleccione la colonia"),

  municipio: yup
    .string()
    .required("Seleccione el municipio")
    .min(3, "Seleccione el municipio"),

  estado: yup
    .string()
    .required("Seleccione el estado")
    .min(3, "Seleccione el estado"),

  rfc: yup
    .string()
    .required("Ingrese el rfc")
    .min(10, "Ingrese el rfc")
    .max(13, "Debe tener como máximo 13 caracteres"),

  curp: yup
    .string()
    .required("Ingrese el curp")
    .min(18, "Ingrese el curp")
    .max(18, "Debe tener como máximo 18 caracteres"),

  email: yup
    .string()
    .required("Ingrese el correo electrónico")
    .email(CORREO_INVALIDO)
    .min(1, "Ingrese el correo electrónico")
    .max(80, "Debe tener como máximo 80 caracteres"),

  cel: yup
    .string()
    .required("Ingrese el celular")
    .typeError("Ingrese el celular")
    .length(10, TELEFONO_INVALIDO)
    .test("formato", TELEFONO_INVALIDO, (val) => {
      var regex = /^\D*\d{10}$/
      return regex.test(val + "")
    }),

  calle: yup.string().required("Ingrese la calle").min(1, "Ingrese la calle"),

  numex: yup
    .string()
    .required("Ingrese el número exterior")
    .min(1, "Ingrese el número exterior")
    .test("formato", VALOR_NUMERO, (val) => {
      var regex = /^[0-9]+$/
      return regex.test(val + "")
    }),
  numin: yup.number().typeError("Ingrese el número interior"),
})

export const validationFormPasoTres = yup.object().shape({
  nombre: yup
    .string()
    .required("Ingrese el nombre")
    .min(1, "Ingrese el nombre")
    .max(20, "Debe tener como máximo 20 caracteres"),

  apellidop: yup
    .string()
    .required("Ingrese el apellido paterno")
    .nullable("Ingrese el apellido paterno")
    .min(1, "Ingrese el apellido paterno")
    .max(20, "Debe tener como máximo 20 caracteres"),

  apellidom: yup
    .string()
    .required("Ingrese el apellido materno")
    .nullable("Ingrese el apellido materno")
    .min(1, "Ingrese el apellido materno")
    .max(20, "Debe tener como máximo 20 caracteres"),

  parentesco: yup
    .string()
    .required("Seleccione el parentesco")
    .min(3, "Seleccione el parentesco"),

  rfc: yup
    .string()
    .required("Ingrese el rfc")
    .nullable("Ingrese el rfc")
    .min(10, "Ingrese el rfc")
    .max(13, "Debe tener como máximo 13 caracteres"),

  ocupacion: yup
    .string()
    .required("Seleccione la ocupación")
    .min(3, "Seleccione la ocupación"),

  telefono: yup
    .string()
    .required("Ingrese el celular")
    .typeError("Ingrese el celular")
    .length(10, TELEFONO_INVALIDO)
    .test("formato", TELEFONO_INVALIDO, (val) => {
      var regex = /^\D*\d{10}$/
      return regex.test(val + "")
    }),
  correo: yup
    .string()
    .required("Ingrese el correo electrónico")
    .email(CORREO_INVALIDO)
    .min(1, "Ingrese el correo electrónico")
    .max(80, "Debe tener como máximo 80 caracteres"),
})

export const validationFormPasoTresMenor = yup.object().shape({
  nombre: yup
    .string()
    .required("Ingrese el nombre")
    .min(1, "Ingrese el nombre")
    .max(20, "Debe tener como máximo 20 caracteres"),

  apellidop: yup
    .string()
    .required("Ingrese el apellido paterno")
    .nullable("Ingrese el apellido paterno")
    .min(1, "Ingrese el apellido paterno")
    .max(20, "Debe tener como máximo 20 caracteres"),

  apellidom: yup
    .string()
    .required("Ingrese el apellido materno")
    .nullable("Ingrese el apellido materno")
    .min(1, "Ingrese el apellido materno")
    .max(20, "Debe tener como máximo 20 caracteres"),

  parentesco: yup
    .string()
    .required("Seleccione el parentesco")
    .min(3, "Seleccione el parentesco"),
})

export const validationFormPasoDos = yup.object().shape({
  nombre: yup
    .string()
    .required("Ingrese el nombre")
    .min(1, "Ingrese el nombre")
    .max(20, "Debe tener como máximo 20 caracteres"),

  apellidop: yup
    .string()
    .required("Ingrese el apellido paterno")
    .min(1, "Ingrese el apellido paterno")
    .max(20, "Debe tener como máximo 20 caracteres"),

  apellidom: yup
    .string()
    .required("Ingrese el apellido materno")
    .min(1, "Ingrese el apellido materno")
    .max(20, "Debe tener como máximo 20 caracteres"),

  pais: yup
    .string()
    .required("Seleccione un país de nacimiento")
    .min(3, "Seleccione un país de nacimiento"),

  nacionalidad: yup
    .string()
    .required("Ingrese la nacionalidad")
    .min(1, "Ingrese la nacionalidad"),

  rfc: yup
    .string()
    .required("Ingrese el rfc")
    .min(10, "Ingrese el rfc")
    .max(13, "Debe tener como máximo 13 caracteres"),

  curp: yup
    .string()
    .required("Ingrese el curp")
    .min(18, "Ingrese el curp")
    .max(18, "Debe tener como máximo 18 caracteres"),

  cel: yup
    .string()
    .required("Ingrese el celular")
    .typeError("Ingrese el celular")
    .length(10, TELEFONO_INVALIDO)
    .test("formato", TELEFONO_INVALIDO, (val) => {
      var regex = /^\D*\d{10}$/
      return regex.test(val + "")
    }),
  email: yup
    .string()
    .required("Ingrese el correo electrónico")
    .email(CORREO_INVALIDO)
    .min(1, "Ingrese el correo electrónico")
    .max(80, "Debe tener como máximo 80 caracteres"),

  cp: yup
    .string()
    .required("Ingrese el código postal")
    .typeError(CP_INVALIDO)
    .length(5, CP_INVALIDO)
    .test("formato", CP_INVALIDO, (val) => {
      var regex = /^\D*\d{5}$/
      return regex.test(val)
    }),

  colonia: yup
    .string()
    .required("Seleccione la colonia")
    .min(3, "Seleccione la colonia"),

  municipio: yup
    .string()
    .required("Seleccione el municipio")
    .min(3, "Seleccione el municipio"),

  estado: yup
    .string()
    .required("Seleccione el estado")
    .min(3, "Seleccione el estado"),

  calle: yup.string().required("Ingrese el calle").min(1, "Ingrese el calle"),

  numex: yup
    .string()
    .required("Ingrese el número exterior")
    .min(1, "Ingrese el número exterior")
    .test("formato", VALOR_NUMERO, (val) => {
      var regex = /^[0-9]+$/
      return regex.test(val + "")
    }),

  numin: yup.number().typeError("Ingrese el número interior"),

  puesto: yup
    .string()
    .required("Ingrese el puesto")
    .min(1, "Ingrese el puesto"),

  giro: yup
    .string()
    .required("Ingrese el giro de la empresa")
    .min(1, "Ingrese el giro de la empresa"),

  ocupacion: yup
    .string()
    .required("Seleccione la ocupación")
    .min(3, "Seleccione la ocupación"),
})

export const validationFormPasoDosEstudiante = yup.object().shape({
  nombre: yup
    .string()
    .required("Ingrese el nombre")
    .min(1, "Ingrese el nombre")
    .max(20, "Debe tener como máximo 20 caracteres"),

  apellidop: yup
    .string()
    .required("Ingrese el apellido paterno")
    .min(1, "Ingrese el apellido paterno")
    .max(20, "Debe tener como máximo 20 caracteres"),

  apellidom: yup
    .string()
    .required("Ingrese el apellido materno")
    .min(1, "Ingrese el apellido materno")
    .max(20, "Debe tener como máximo 20 caracteres"),

  pais: yup
    .string()
    .required("Seleccione un país de nacimiento")
    .min(3, "Seleccione un país de nacimiento"),

  nacionalidad: yup
    .string()
    .required("Ingrese la nacionalidad")
    .min(1, "Ingrese la nacionalidad"),

  rfc: yup
    .string()
    .required("Ingrese el rfc")
    .min(10, "Ingrese el rfc")
    .max(13, "Debe tener como máximo 13 caracteres"),

  curp: yup
    .string()
    .required("Ingrese el curp")
    .min(18, "Ingrese el curp")
    .max(18, "Debe tener como máximo 18 caracteres"),

  cel: yup
    .string()
    .required("Ingrese el celular")
    .typeError("Ingrese el celular")
    .length(10, TELEFONO_INVALIDO)
    .test("formato", TELEFONO_INVALIDO, (val) => {
      var regex = /^\D*\d{10}$/
      return regex.test(val + "")
    }),
  email: yup
    .string()
    .required("Ingrese el correo electrónico")
    .email(CORREO_INVALIDO)
    .min(1, "Ingrese el correo electrónico")
    .max(80, "Debe tener como máximo 80 caracteres"),

  cp: yup
    .string()
    .required("Ingrese el código postal")
    .typeError(CP_INVALIDO)
    .length(5, CP_INVALIDO)
    .test("formato", CP_INVALIDO, (val) => {
      var regex = /^\D*\d{5}$/
      return regex.test(val)
    }),

  colonia: yup
    .string()
    .required("Seleccione la colonia")
    .min(3, "Seleccione la colonia"),

  municipio: yup
    .string()
    .required("Seleccione el municipio")
    .min(3, "Seleccione el municipio"),

  estado: yup
    .string()
    .required("Seleccione el estado")
    .min(3, "Seleccione el estado"),

  calle: yup.string().required("Ingrese el calle").min(1, "Ingrese el calle"),

  numex: yup
    .string()
    .required("Ingrese el número exterior")
    .min(1, "Ingrese el número exterior")
    .test("formato", VALOR_NUMERO, (val) => {
      var regex = /^[0-9]+$/
      return regex.test(val + "")
    }),

  numin: yup.number().typeError("Ingrese el número interior"),

  puesto: yup.string().min(1, "Ingrese el puesto"),

  giro: yup.string().min(1, "Ingrese el giro de la empresa"),

  ocupacion: yup
    .string()
    .required("Seleccione la ocupación")
    .min(3, "Seleccione la ocupación"),
})
