import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useField } from "formik";
import React from "react";
import CustomErrorMessage from "./CustomErrorMessage";

export default function CustomTextArea({ placeholder, ...props }) {
  const [field] = useField(props);
  return (
      <>
    <TextareaAutosize
      placeholder={placeholder}
      {...field}
      {...props}
    />
    <CustomErrorMessage name={field.name}/>
    </>
  );
}