import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Stack, Button } from '@mui/material';
import './Advertencias.css';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { validationFormContactoMayorEdad } from './ValidationFormMayorEdad';
import CustomTextField from '../CustomFields/CustomTextField';
import { handleMaxLength } from '../Utils/FieldValidationFunctions';
import Api from '../Api/Api';

export default function FormDialogContactoMayorEdad(props) {
  const navigate = useNavigate();
  // const[contratante, setContratante] = React.useState({});
  const dataContratante = JSON.parse(sessionStorage.getItem("interContratante"))

  const handleClickModal = () => {
    props.handleClose();
  }

  const handleEnviar = (values) => {
    dataContratante.telefono = values.telefono;
    sessionStorage.setItem("interContratante", JSON.stringify(dataContratante));
    Api.actualizaContratante(dataContratante.id, dataContratante)
      .then(res => {
        sessionStorage.clear();
        navigate('/inicio');
      })
      .catch(error => {
        console.log(error);
      });


  };

  return (
    <>
      <Formik
        validateOnChange={true}
        initialValues={{
          telefono: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          handleEnviar(values, setSubmitting);
        }}
        validationSchema={validationFormContactoMayorEdad}>
        {({ values, submitForm, setFieldValue, handleChange, errors, isSubmitting, setFieldError, touched, setFieldTouched, }) => (
          <Form>
            <IconButton
              aria-label="close"
              onClick={handleClickModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 0,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Grid container direction="column" justifyContent="center" alignItems="flex-start">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <br />
                  <span className='pregunta-text'>&iquest;Buscas otras opciones? </span>
                  <span className='contenido-text'>Comp&aacute;rtenos tu tel&eacute;fono y un ejecutivo te contactar&aacute; para ofrecerte
                    otras alternativas que puedan ajustarse a tus necesidades. </span>

                  <CustomTextField id="telefono" name="telefono" type="text" placeholder="Tel&eacute;fono"
                    onChange={(event) => handleMaxLength(event, setFieldValue, "telefono", 10)}
                  />
                  <Button
                    fullWidth
                    onClick={submitForm}
                    disabled={isSubmitting}
                    variant="contained">
                    Enviar
                  </Button>
                </Stack>
              </Grid>

            </Grid>
          </Form>
        )}
      </Formik>
    </>

  );
}