import { Alert, AlertTitle, Box, Collapse } from "@mui/material";
import { useState } from "react";
import IconAntiFraudInfo from "../../assets/AntifraudeImagen/iconAntiFraudInfo.svg";

const AlertAntiFraudClose = ({ customStyles, ...props }) => {
  const [open, setOpen] = useState(true);
  return (
    <>
      {open && (
        <Box sx={customStyles}>
          <Collapse in={open}>
            <Alert
              sx={{ color: "black" }}
              icon={
                <Box
                  component={"img"}
                  src={IconAntiFraudInfo}
                  width={"23px"}
                  height={"33px"}
                ></Box>
              }
              severity="info"
              onClose={() => {
                setOpen(false);
              }}
            >
              <AlertTitle
                sx={{
                  fontWeight: 700,
                  color: "black",
                  fontSize: 12,
                  fontFamily: "Montserrat",
                }}
              >
                Aviso
              </AlertTitle>
              <span
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  fontFamily: "Montserrat",
                }}
              >
                Recuerda que en inter.mx nunca te pediremos pagos a cuentas de
                terceros ni transferencias.
              </span>
            </Alert>
          </Collapse>
        </Box>
      )}
    </>
  );
};
export default AlertAntiFraudClose;
