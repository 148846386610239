
import * as yup from "yup";
import { CORREO_INVALIDO, REQUERIDO, TELEFONO_INVALIDO, VALOR_NECESARIO, VALOR_NUMERO } from "../Utils/Constantes";


export const validationForm = yup.object().shape({
  nombre: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(20, 'Debe tener como máximo 20 caracteres'),
  correo: yup
    .string()
    .required(VALOR_NECESARIO)
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, CORREO_INVALIDO)
    .min(3, 'El correo debe tener más de 3 letras')
    .max(80, 'Debe tener como máximo 80 caracteres'),
  telefono: yup
    .string()
    .required(VALOR_NECESARIO)
    .matches(/^([1-9])(\d{9})$/g, TELEFONO_INVALIDO)
    .typeError(TELEFONO_INVALIDO)
    .length(10, "¡Oops! Necesitamos los 10 dígitos de tu celular."),
  terminos: yup
    .boolean()
    .required(REQUERIDO)
    .test(
      "terminos",
      REQUERIDO,
      (valor) => {
        return valor === true;
      }
    )
});



export const validationFormTerm = yup.object().shape({
  correo: yup
    .string()
    .required(VALOR_NECESARIO)
    .email(CORREO_INVALIDO)
    .min(1, VALOR_NECESARIO)
    .max(80, 'Debe tener como máximo 80 caracteres'),
  telefono: yup
    .string()
    .required(VALOR_NECESARIO)
    .typeError(VALOR_NUMERO)
    .length(10, TELEFONO_INVALIDO)
    .test(
      "formato",
      TELEFONO_INVALIDO,
      (val) => {
        var regex = /^\D*\d{10}$/;
        return regex.test(val + "");
      }
    ),
  terminos: yup
    .boolean()
    .required(REQUERIDO)
    .test(
      "terminos",
      REQUERIDO,
      (valor) => {
        return valor === true;
      }
    )
});

export const validationFormDos = yup.object().shape({
  correo: yup
    .string()
    .required(VALOR_NECESARIO)
    .email(CORREO_INVALIDO)
    .min(1, VALOR_NECESARIO)
    .max(80, 'Debe tener como máximo 80 caracteres'),

  num: yup
    .string()
    .required(VALOR_NECESARIO)
    .typeError(VALOR_NUMERO)

});