import * as React from 'react';
///Components Imports
import './PlanesCotizacion.css'
import { useNavigate } from 'react-router-dom';
import { calcularEdad } from '../Utils/Utils';

///Material
import { Grid, Button, Box } from "@mui/material";
import Stack from '@mui/material/Stack';
import { Paper, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';

//CSS  Logos
import AxaSeguro from '../../assets/img/planesCotizacion/logo-axa.svg';
import GDSSeguro from '../../assets/img/planesCotizacion/logo-general-de-salud.svg';
import gnp from '../../assets/img/planesCotizacion/logo-gnp.svg';
import mapfre from '../../assets/img/planesCotizacion/logo-mapfre.svg';
import IconDone from '../../assets/img/planesCotizacion/done.svg';
import ArrowDownIcon from '../../assets/img/planesCotizacion/arrowDownIcon.svg';
import CompararIcon from '../../assets/img/planesCotizacion/CompararIcon.svg';
import LoQuieroIcon from '../../assets/img/planesCotizacion/LoQuieroIcon.png';



export default function TarjetasPlanesMobileTablet(props) {

    const navigate = useNavigate();
    const dataObj = JSON.parse(sessionStorage.getItem("interContratante"))
    const dataTitular = dataObj.asegurados.find(asegurado => asegurado.titular === true);
    const edad = calcularEdad(new Date(dataObj.tipoFlujo === 3 ? dataTitular.fechanacimiento : dataObj.fechanacimiento));

    //Estados//
    const [elementosComparar, setElementosComparar] = React.useState([])
    const slctPLan = "Esencial"
    const [phone] = React.useState(dataObj.telefono);
    const [mostrarDetalleMovil, setmostrarDetalleMovil] = React.useState(null)

    //Const Funciones//


    function FormatNumber({ number }) {
        return (
            <span >
                {new Intl.NumberFormat("ES-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0
                }).format(number)}
            </span>
        );
    }

    const clickBtnComparar = (e, cadaContenido) => {
        if (document.getElementById(e.target.id).innerHTML.toString().includes("Comparar")) {
            agregarElementoComparar(cadaContenido)
            document.getElementById(e.target.id).innerHTML = "<img  src=" + IconDone + " />";
        } else {
            vaciarArrComparacion()
            document.getElementById(e.target.id).innerHTML = "Comparar";
        }
    }

    const vaciarArrComparacion = () => {
        setElementosComparar([])
    }

    const agregarElementoComparar = (elemento) => {
        let arrDetallesBase = elemento.paquetes[0].detalles
        console.log("previus:", elemento.paquetes[0])


        let arrDetallesDepured = []
        for (let i = 0; i < arrDetallesBase.length; i++) {
            switch (arrDetallesBase[i].nombre) {
                case "Coaseguro": case "Suma Asegurada": case "Tope Coaseguro": case "Deducible":
                    arrDetallesDepured.push(arrDetallesBase[i])
                    break;
                default:
                    break;
            }
        }
        if (arrDetallesBase.find(x => x.nombre === 'Deducible') === undefined) {
            arrDetallesDepured.push({
                agrupador: "DETALLES",
                nombre: "Deducible",
                descripcion: "Es el monto inicial fijo que debes cubrir para poder utilizar tu seguro en cada accidente o enfermedad.",
                valor: "Sin Deducible",
                etiquetavalor: "",
                descrtooltip: "Es el monto inicial fijo que debes cubrir para poder utilizar tu seguro en cada accidente o enfermedad.",
                incluido: 0,
                secuencia: 0,
                id: "",
                ultimaActualizacion: null
            })
        };
        //evaluacionBeneficiosAdicionales(elemento.paquetes[0].detalles, "")
       
        arrDetallesDepured.push({
            agrupador: "DETALLES",
            descripcion: "Es la cantidad de beneficios adicionales que son otorgados",
            descrtooltip: "Es la cantidad de beneficios adicionales que son otorgados",
            etiquetavalor: "",
            id: "",
            incluido: 0,
            nombre: "Beneficios Adicionales",
            secuencia: 0,
            ultimaActualizacion: null,
            valor: evaluacionBeneficiosAdicionales(elemento.paquetes[0].detalles, ""),
        })


        elemento.paquetes[0].detalles = arrDetallesDepured
        console.log("arrDetallesDepured", arrDetallesDepured)
        let auxArrComparar = [...elementosComparar]
        auxArrComparar.push(elemento);
        setElementosComparar(auxArrComparar);
        if (auxArrComparar.length > 1) {
            let auxStr = "[" + JSON.stringify(auxArrComparar[0]) + "," + JSON.stringify(auxArrComparar[1]) + "]"
            window.sessionStorage.setItem('comparacion', auxStr)
            let auxObj = { plan: slctPLan }
            sessionStorage.setItem("detalleCotizacion", JSON.stringify(auxObj))
            navigate('/comparacion-planes');
        }
    }

    const evaluacionBeneficiosAdicionales = (objeto, nombreAseguradora) => {
        let contadorBeneficios = 0;
        objeto.forEach(cadaDetalle => {
            if (cadaDetalle.agrupador === "PLUSADICIONAL") {
                contadorBeneficios++
            }
        })

        return contadorBeneficios
    }

    const handleClickLoQuiero = (data) => {
        let auxObj = {
            plan: slctPLan,
            aseguradora: data.nombreAseguradora,
            idAseguradora: data.id,
            costo: data.sumaTotal,
            sumaAsegurada: data.paquetes[0].detalles.find(cadaDetalle => cadaDetalle.nombre === "Suma Asegurada") ? data.paquetes[0].detalles.find(cadaDetalle => cadaDetalle.nombre === "Suma Asegurada").valor : null,
            deducible: data.paquetes[0].detalles.find(cadaDetalle => cadaDetalle.nombre === "Deducible") ? data.paquetes[0].detalles.find(cadaDetalle => cadaDetalle.nombre === "Deducible").valor : null,
            coaseguro: data.paquetes[0].detalles.find(cadaDetalle => cadaDetalle.nombre === "Coaseguro") ? data.paquetes[0].detalles.find(cadaDetalle => cadaDetalle.nombre === "Coaseguro").valor : null,
            topeCoaseguro: data.paquetes[0].detalles.find(cadaDetalle => cadaDetalle.nombre === "Tope Coaseguro") ? data.paquetes[0].detalles.find(cadaDetalle => cadaDetalle.nombre === "Tope Coaseguro").valor : null
        }

        window.dataLayer.push({
            'event': 'form_step_3_submit',
            'plane': slctPLan,
            'modalidad_pago': 'Anual',
            'seguro': data.nombreAseguradora,
            //'nombre': name,//
            'teléfono': phone

        });
        window.dataLayer.push({
            event : "medico_seguro_cotizacion_seleccion",
            no_cotizacion : dataObj.folio,
            plan: props.plan ? props.plan : 'Esencial',
            telefono : dataObj.telefono,
            email : dataObj.correo,     
            items:[                                                                  
                {
                    aseguradora: data.nombreAseguradora,
                    costo_aseguradora_anual: data.sumaTotal,
                },
            ]
        })
        sessionStorage.setItem("detalleCotizacion", JSON.stringify(auxObj))
        navigate("/detalles-cotizacion")
    }

    const handleClickShowDetalleMovil = (idx) => {
        setmostrarDetalleMovil((mostrarDetalleMovil !== idx ? idx : null))
    }

    const evaluacionCoberturas = (objeto, nombreProp, valorVacio, nombreAseguradora) => {
        if (nombreProp === "Coaseguro" && nombreAseguradora !== "GENERAL DE SEGUROS") {
            return objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp) ? (objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).valor * 100) + "% " + objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).etiquetavalor.toLowerCase() : valorVacio

            // } else if (nombreProp === "Coaseguro" && nombreAseguradora === "AXA") {
            //     return objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp) ? new Intl.NumberFormat("ES-MX", { style: "currency", currency: "MXN", minimumFractionDigits: 0 }).format(objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).valor) + " " + objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).etiquetavalor : valorVacio
        } else if (nombreProp === "Coaseguro" && nombreAseguradora === "GENERAL DE SEGUROS") {
            return objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp) ? (objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).valor * 100) + "% " : valorVacio

            // } else if (nombreProp === "Coaseguro" && nombreAseguradora === "AXA") {
            //     return objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp) ? new Intl.NumberFormat("ES-MX", { style: "currency", currency: "MXN", minimumFractionDigits: 0 }).format(objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).valor) + " " + objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).etiquetavalor : valorVacio
        } else {

            return objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp) ? new Intl.NumberFormat("ES-MX", { style: "currency", currency: "MXN", minimumFractionDigits: 0 }).format(objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).valor) : valorVacio
        }
    }

    //Styles//
    const margin2 = {
        margin: "2% 0%",
    }

    const fontPlanes = {
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px",
        letterSpacing: "0em",
        textAlign: "left",
        margin: "2% 9%",
    }

    const txtCantidadPlanes = {
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "15px",
        letterSpacing: "0em",
        textAlign: "right",
        margin: "2% 9%",
    }

    const txtCostoAnual = {
        lineHeight: "14px",
        color: "#8F8F8F",
        fontSize: "12px",

    }

    return (
        <Grid container className="showOnlyOnMobile">
            {props.planesData.map((eachContent, idx) => (
                <Grid item lg={0} sm={12} xs={12} md={6}  marginBottom={'1rem'}>
                    <Paper sx={{
                        padding: "1rem 0.5rem"
                    }} className="with-332-paper" >
                        <Grid container justifyContent="space-between" marginBottom={'16px'}>
                            <Grid item xs={5} className="flex-center">
                                <img alt="logo-aseguradora"
                                    src={eachContent.nombreAseguradora === 'MAPFRE' ? mapfre : eachContent.nombreAseguradora === 'AXA' ? AxaSeguro : eachContent.nombreAseguradora === 'GNP' ? gnp : GDSSeguro}
                                    className="img-aseguradoras" />
                            </Grid>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Grid item xs={6} className="flex-center">
                                <Box>
                                    <Typography sx={txtCostoAnual}>Costo anual</Typography>
                                    <Typography sx={txtCostoAnual} className="displayNoneTablet">por todos tus asegurados</Typography>
                                    <Typography sx={{
                                        fontSize: "20px",
                                        textAlign: "left",
                                        color: "#212121",
                                        fontWeight: "600",
                                    }}>
                                        <FormatNumber number={eachContent.sumaTotal} />
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container marginBottom={'16px'}>
                            <Grid item xs={6} md={6} sm={6} className="displayNoneTablet" display={'flex'} justifyContent={'center'}>
                                <Button id={"cadaBtnCompararId" + idx} onClick={(event) => clickBtnComparar(event, eachContent)} sx={{
                                    width: "136px",
                                    height: "56px",
                                    borderRadius: "8px",
                                    color: "#039ecc",
                                    border: "1px solid #039ecc",
                                }}>
                                    <img src={CompararIcon} alt="icon-comparar" className="marginRight-8px" />
                                    Comparar
                                </Button>
                            </Grid>
                            <Grid item xs={6} md={6} sm={6} display={'flex'} justifyContent={'center'}>
                                <Button onClick={() => handleClickLoQuiero(eachContent)} sx={{
                                    width: "136px",
                                    height: "56px",
                                    borderRadius: "8px",
                                    backgroundColor: "#039ecc",
                                    border: "none",
                                    color: "#fff",
                                }}>
                                    ¡Lo quiero!
                                    <img src={LoQuieroIcon} alt="lo-quiero-icon" className="marginLeft-8px" />
                                </Button>
                            </Grid>
                        </Grid>
                        {edad >= 50 ?
                            <>
                                {eachContent.nombreAseguradora === 'GENERAL DE SEGUROS' ?
                                    <Stack justifyContent="center" alignItems="center" className="espaciadoAvisos">
                                        <div className="contenedorAvisosImportantes">
                                            <div className="tituloAvisosImportantes">Aviso importante</div>
                                            <br />

                                            <div className="contenidoAvisosImportantes">Para asegurados con 50 años o más, se realizará examen médico en la red sin costo para el usuario</div>
                                        </div>
                                    </Stack>

                                    : eachContent.nombreAseguradora === "MAPFRE" && edad >= 58 ?
                                        <Stack justifyContent="center" alignItems="center" className="espaciadoAvisos">
                                            <div className="contenedorAvisosImportantes">
                                                <div className="tituloAvisosImportantes">Aviso importante</div>
                                                <br />
                                                <div className="contenidoAvisosImportantes">Para asegurados con 58 años o más, se solicitarán exámenes médicos con costo para el usuario</div>
                                            </div>
                                        </Stack>

                                        : <></>}

                            </>
                            :
                            <></>}
                        <Grid container justifyContent="center">
                            <Grid item xs={12}>
                                <Typography onClick={() => handleClickShowDetalleMovil(idx)} sx={{
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0px",
                                    textAlign: "center",
                                }}>
                                    Conoce más sobre los planes
                                    <img alt="icon-delete" src={ArrowDownIcon} style={{ marginLeft: '4px'}} />
                                </Typography>
                            </Grid>
                        </Grid>
                        {mostrarDetalleMovil === idx ?
                            (<Grid container sx={{ width: "100% " }}>
                                <Grid container sx={margin2}>
                                    <Grid item xs={6} >
                                        <Typography sx={fontPlanes}>Suma Asegurada</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={txtCantidadPlanes}>
                                            {evaluacionCoberturas(eachContent.paquetes[0].detalles, "Suma Asegurada", "No incluido")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container csx={margin2}>
                                    <Grid item xs={6}>
                                        <Typography sx={fontPlanes}>Deducible</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={txtCantidadPlanes}>
                                            {evaluacionCoberturas(eachContent.paquetes[0].detalles, "Deducible", "Sin Deducible")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={margin2}>
                                    <Grid item xs={6}>
                                        <Typography sx={fontPlanes}>Coaseguro</Typography>
                                    </Grid>
                                    <Grid item xs={6}>

                                        <Typography sx={txtCantidadPlanes}>

                                            {evaluacionCoberturas(eachContent.paquetes[0].detalles, "Coaseguro", "No incluido", eachContent.nombreAseguradora)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={margin2}>
                                    <Grid item xs={6}>
                                        <Typography sx={fontPlanes}>Tope coaseguro</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={txtCantidadPlanes}>
                                            {evaluacionCoberturas(eachContent.paquetes[0].detalles, "Tope Coaseguro", "No incluido")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={margin2}>
                                    <Grid item xs={5}>
                                        <Typography sx={fontPlanes}>Beneficios</Typography>
                                    </Grid>
                                    <Grid item xs={7} className="txt-coberturas text-center font-weight-700">{evaluacionBeneficiosAdicionales(eachContent.paquetes[0].detalles, eachContent.nombreAseguradora) > 0 ?
                                        <Box sx={{ height: "50px" }}>

                                            <Stack className="etiqueta-beneficios-adicionales">
                                                <Typography sx={{
                                                    fontSize: "15px",
                                                    fontStyle: "normal",
                                                    fontWeight: "700",
                                                    lineHeight: "20px",
                                                    letterSpacing: "0em",
                                                    textAlign: "center",
                                                }}>
                                                    {evaluacionBeneficiosAdicionales(eachContent.paquetes[0].detalles, eachContent.nombreAseguradora)}  Incluidos
                                                </Typography>

                                            </Stack>


                                        </Box> :
                                        <Box sx={{ height: "50px" }}>
                                            No incluidos
                                        </Box>

                                    }
                                    </Grid>
                                </Grid>


                            </Grid>)
                            :
                            (<></>)}
                    </Paper>
                </Grid >
            ))
            }
        </Grid >
    )

}