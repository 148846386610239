import axios from "axios"
import { CAT, ASEG, PATH } from "../Utils/Constantes"

// Create instance called instance
const instance = axios.create({
  baseURL: PATH,
})

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  postSurfly: () =>
    instance({
      method: "POST",
      url: "https://surfly.com/v2/sessions/?api_key=f879c043385c4a5ea08f6dd54f7030df",
      data: {
        agent_can_end_session: false,
        agent_can_request_control: false,
        agent_can_take_control: false,
        allow_control_switching: true,
        allow_localhost_requests: false,
        allow_original_file_download: false,
        allow_pause: false,
        auto_restore: true,
        auto_start: false,
        autohide_button: false,
        automatic_screenshots_enabled: false,
        blacklist: "[]",
        block_until_agent_joins: true,
        branding_logo_link: "",
        branding_logo_url: "",
        branding_primary_color: "#e54747",
        branding_primary_fg_color: "#ffffff",
        branding_secondary_color: "#6e2222",
        branding_secondary_fg_color: "#ffffff",
        chat_integration: "disabled",
        confirm_session_start: false,
        cookie_transfer_enabled: true,
        cookie_transfer_proxying: false,
        cookie_transfer_scopes: [],
        cookie_transfer_urls: [],
        cookies: [
          {
            name: "_gcl_au",
            path: "/",
            value: "1.1.652699558.1644891442",
          },
          {
            name: "_uetsid",
            path: "/",
            value: "699a15f08e0511ecad49a3967461da3f",
          },
          {
            name: "_uetvid",
            path: "/",
            value: "699a3f008e0511ec82c89d8ca368f315",
          },
          {
            name: "_ga",
            path: "/",
            value: "GA1.1.272956283.1644891444",
          },
          {
            name: "_ga_3W26F9833K",
            path: "/",
            value: "GS1.1.1644891442.1.1.1644891443.0",
          },
          {
            name: "blueID",
            path: "/",
            value: "089658aa-2f26-415f-b15b-358e54196989",
          },
        ],
        dashboard_background_url: "",
        disable_end_redirect: true,
        disable_session_invitations: false,
        docked_only: false,
        document_editing: true,
        drawing_color: "default",
        drawing_enabled: true,
        drawing_mode: "temporary",
        drawing_timeout: 3,
        drawing_width: 15,
        enable_admission: false,
        enable_native_dialogs: false,
        enable_notifications: true,
        enable_screensharing: false,
        enable_sounds: true,
        enable_video_archiving: false,
        end_of_session_popup_url: "",
        filesharing: true,
        follower_drawing_selector: "",
        follower_redirect_url: "",
        format_session_id: false,
        headless: false,
        hidden: false,
        hide_selector: "",
        hide_until_agent_joins: false,
        language: "es",
        leader_connect_timeout: 2,
        leader_redirect_url: "",
        meta: "{}",
        native_selects: true,
        newurl: true,
        notification_position: "bottom-right",
        only_embedded_sessions: false,
        orphan_redirect_url: "",
        position: "bottomleft",
        preferred_server_id: "",
        private_session: true,
        proxy_only: false,
        region: "",
        require_password: false,
        s3_log_bucket: "",
        s3_screenshots_bucket: "",
        screenshot_image_size: 800,
        screenshot_interval: 15,
        selected_regions: [],
        shake_to_start: false,
        share_downloads: true,
        sharing_button: true,
        show_cursors: true,
        show_loading_screen: false,
        soft_end_filter: [],
        soft_session_end: false,
        splash: false,
        start_docked: true,
        start_muted: false,
        stealth_mode: true,
        store_chat_logs: false,
        store_session_logs: false,
        theme_font_background: "#cc0000",
        theme_font_color: "#FFFFFF",
        theme_font_size: 14,
        trigger_screenshot_on_annotation: false,
        ui_off: false,
        url: window.location.href,
        url_mangling: false,
        use_webrtc_for_screenshots: false,
        verbose_console: false,
        videochat: true,
        videochat_autostart: false,
        videochat_p2p_enabled: true,
        videochat_prompt: true,
        videochat_start_fullscreen: false,
      },
    }),
  getSurfly: (url) =>
    instance({
      method: "GET",
      url: url,
    }),
  getParentescos: () =>
    instance({
      method: "GET",
      url: CAT + "Parentesco",
    }),
  genero: () =>
    instance({
      method: "GET",
      url: CAT + "Genero",
    }),
  padecimiento: () =>
    instance({
      method: "GET",
      url: CAT + "padecimiento",
    }),
  medioContacto: () =>
    instance({
      method: "GET",
      url: CAT + "MedioContacto",
    }),

  //Aseguradoras
  getPaquetes: (detallePaquete) =>
    instance({
      method: "GET",
      url: ASEG + "Paquetes/" + detallePaquete,
    }),

  getPlanAseguradora: (aseg, plan) =>
    instance({
      method: "GET",
      url: ASEG + "Paquetes/" + aseg + "/" + plan,
    }),
  getSumaAsegurada: (idAseguradora, idPlan, idContratante) =>
    instance({
      method: "GET",
      url:
        ASEG + "Primas/" + idAseguradora + "/" + idPlan + "/" + idContratante,
    }),
  getDetallePaquete: (aseguradora, plan) =>
    instance({
      method: "GET",
      url: ASEG + "Paquetes/" + aseguradora + "/" + plan,
    }),

  //Lead
  getContratante: (folio) =>
    instance({
      method: "GET",
      url: "Contratante/" + folio,
    }),
  getAsegurado: (idContratante) =>
    instance({
      method: "GET",
      url: "asegurado/" + idContratante,
    }),
  padecimientos: (idContratante, idAsegurado) =>
    instance({
      method: "GET",
      url: "Padecimiento/" + idContratante + "/" + idAsegurado,
    }),
  //cp
  consultaCP: (cp) =>
    instance({
      method: "GET",
      url: "CP/" + cp,
    }),
  validaCP: (cp) =>
    instance({
      method: "GET",
      url: "CP/Existe/" + cp,
    }),
  //Lead put
  guardaContratante: (contratante) =>
    instance({
      method: "PUT",
      url: "Contratante/Save/",
      data: contratante,
    }),
  actualizaContratante: (idContratante, contratante) =>
    instance({
      method: "PUT",
      url: "Contratante/Save/"+idContratante,
      data: contratante,
    }),
  guardaAsegurado: (idContratante, asegurado) =>
    instance({
      method: "PUT",
      url: "Asegurado/Save/" + idContratante,
      data: asegurado,
    }),
  actualizaAsegurado: (idContratante, asegurado) =>
    instance({
      method: "PUT",
      url: "Asegurado/Save/" + idContratante + "/" + asegurado.id,
      data: asegurado,
    }),

  ////Cotizaciones
  guardarCotizacion: (contratanteId, aseguradoId, dataObj) =>
    instance({
      method: "POST",
      url: "Cotizaciones/Save/" + contratanteId,
      data: dataObj,
    }),
  eliminarAsegurado: (contratanteId, aseguradoId) =>
    instance({
      method: "DELETE",
      url: "Asegurado/Delete/" + contratanteId + "/" + aseguradoId,
    }),

  // Mati flujo
  getFlujoMati: (idLead) =>
    instance({
      method: "GET",
      url: `Contratante/Verificacion/${idLead}`,
    }),
  editarFlujoMati: (idLead, document, status) => {
    const data = {
      estatus: status
    }
    return instance({
      method: "PUT",
      url: `Contratante/Verificacion/${idLead}/${document}`,
      data
    })
  },
  setStatus: (status, folio) => {
    instance({
      method: "PUT",
      url: `Contratante/Solicitud/Estaus/${folio}`,
      data: { estatus: status }
    })},

  //crm
  //Verificacion Documentos
  getVerificacionDocs: (idLead) =>
    instance({
      method: "GET",
      url: `Contratante/Verificacion/Documentos/${idLead}`,
    }),

  //Verificación estatus documentos
  setVerificacionDocumento: (documento, folio, estatus, id) =>
    instance({
      method: "PUT",
      url: `Contratante/Verificacion/Estatus/Actualizar/${documento}/${folio}`,
      data: { estatus, id }
    }),
  
  //notes
  //Obtener notas de documento
  getNotesByDocument: (document, folio) => 
    instance({
      method: "GET",
      url: `Notes/Contratante/Verificacion/${document}/${folio}`
    }),
  
  //Agregar una nota
  setNoteByDocument: (document, description, ejecutivoRegistra, folio) =>
    instance({
      method: "POST",
      url: `Notes/Contratante/Verificacion/${folio}`,
      data: {docuemnto: document, descripcion: description, ejecutivoRegistra }
    })
}
