import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Padecimiento from "./Padecimiento";
import { congenita } from "../../Utils/Cuestionario";

export default function Congenito(props) {

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
      {props.congenitos.map((padecimiento, kndex) =>
        <React.Fragment key={kndex}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Accordion className='background-acordeon'>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}
                aria-controls={"padecimientos-content"+kndex} id={"padecimientos-header"+kndex}
                className="no-margin-accordeon">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='d-flex-center'>
                  <IconButton onClick={(event) => props.handleRemovePadecimiento(event, padecimiento, props.setFieldValue)}
                    onFocus={(event) => event.stopPropagation()}>
                    <HighlightOffIcon />
                  </IconButton >
                  <Typography >{padecimiento.descripcion}</Typography>
                </Grid>
              </AccordionSummary>
              <AccordionDetails className='background-form-acordeon'>
                <Padecimiento handleGuardar={props.handleGuardarPadecimiento} padecimiento={padecimiento} 
                  add={props.length} lista={congenita} asegurado={props.asegurado} tipoFlujo={props.tipoFlujo}/>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </React.Fragment>
      )}
      {props.length < 5 &&
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 1, marginLeft: 1}}>
          <Padecimiento handleGuardar={props.handleGuardarPadecimiento}  padecimiento={null}  lista={congenita}
          asegurado={props.asegurado} tipoFlujo={props.tipoFlujo}/>
        </Grid>
      }
    </Grid>
  );
}