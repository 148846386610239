import { Box, Grid } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const styles = {
  header: {
    color: 'white',
    minHeight: '60px',
    backgroundColor: '#041431',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10vw'
  },
  itemContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  oportunidad: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: '400'
  },
  oportunidadTexto: {
    fontWeight: '600',
    paddingRight: '0.5rem'
  }
}

export default function CrmHeader(props) {
  const { cotizacion } = props;
  return (
    <Box style={styles.header}>
      <Grid container style={styles.itemContainer}>
        <Grid item style={styles.oportunidad}>
        <ChevronLeftIcon /><p style={styles.oportunidadTexto}>Regresar</p>
        </Grid>
        <Grid item style={styles.oportunidad}>
          <p style={styles.oportunidadTexto}>Número de oportunidad:</p> {cotizacion}
        </Grid>
      </Grid>
    </Box>
  );
}