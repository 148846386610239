import React from 'react';
import { Box, Grid, RadioGroup, FormControlLabel, Radio } from '@mui/material'

const EstatusDocumento = (props) => {
  const [status, setStatus] = React.useState(props.document.estatus);

  const handleStatusChange = ({ target }) => {
    setStatus(target.value);
    props.onEstatusChange(props.document)({ target });
  }

    return (
        <Box sx={{ backgroundColor: "#F4F4F4" }}>
        <Grid container sx={{ p: 0, m: 0 }}>
          <Grid item>
            <p style={{ fontSize: '12px', fontWeight: '700', padding: '10px 16px' }}>Estatus del documento</p>
          </Grid>
          <Grid item sx={{ p: "0 0 10px 16px", m: 0 }}>
            <RadioGroup row onChange={handleStatusChange} value={status}>
              <FormControlLabel value="200" control={<Radio />} label="Aprobar" />
              <FormControlLabel value="500" control={<Radio />} label="Rechazar" />
            </RadioGroup>
          </Grid>
        </Grid>
      </Box>
    )
}

export default EstatusDocumento;