import { frecuencias, resultadoRevision, resultadoRevisionAdicional, ultimaVez } from "./Constantes";
import { fillCantidad, fillDesdeCuando } from "./Utils";

const deportes = [
    "AEROBICS AMATEUR Y PROFESIONAL",
"ALPINISMO AMATEUR Y PROFESIONAL",
"ARTES MARCIALES AMATEUR Y KRAV MAGÁ",
"ARTES MARCIALES PROFESIONAL Y KRAV MAGÁ",
"ATLETISMO AMATEUR",
"ATLETISMO PROFESIONAL",
"AUTOMOVILISMO-PILOTO AUTOMOTOR DE CARRERAS AMATEUR Y PROFESIONAL",
"AVIACIÓN DEPORTIVA AMATEUR Y PROFESIONAL",
"BALLET AMATEUR",
"BALLET PROFESIONAL",
"BASQUETBOL AMATEUR",
"BASQUETBOL PROFESIONAL",
"BEISBOL AMATEUR",
"BEISBOL PROFESIONAL",
"BOLICHE AMATEUR Y PROFESIONAL",
"BOXEO AMATEUR",
"BOXEO PROFESIONAL",
"BUCEO AMATEUR",
"BUCEO PROFESIONAL",
"BUNGEE AMATEUR Y PROFESIONAL",
"CABALGATA (más de 4 días al mes)",
"CANOTAJE AMATEUR",
"CANOTAJE PROFESIONAL",
"CAZA AMATEUR",
"CAZA PROFESIONAL",
"CHARRERÍA AMATEUR",
"CHARRERÍA PROFESIONAL",
"CICLISMO ACROBÁTICO",
"CICLISMO AMATEUR",
"CICLISMO AMATEUR (de 1 a 3 días a la semana)",
"CICLISMO DE MONTAÑA AMATEUR",
"CICLISMO DE MONTAÑA PROFESIONAL",
"CICLISMO PROFESIONAL",
"CROSSFIT",
"DANZA AEREA AMATEUR",
"EQUITACIÓN AMATEUR",
"EQUITACIÓN PROFESIONAL",
"ESCALADA AMATEUR",
"ESCALADA PROFESIONAL",
"ESGRIMA AMATEUR",
"ESGRIMA PROFESIONAL",
"ESPELEOLOGÍA AMATEUR",
"ESPELEOLOGÍA PROFESIONAL",
"ESQUÍ (SKY) AMATEUR",
"ESQUÍ (SKY) PROFESIONAL",
"FRONTÓN AMATEUR",
"FRONTÓN PROFESIONAL",
"FUTBOL AMERICANO AMATEUR",
"FUTBOL AMERICANO PROFESIONAL",
"FUTBOL SOCCER AMATEUR",
"FUTBOL SOCCER PROFESIONAL",
"GIMNASIA AMATEUR",
"GIMNASIA PROFESIONAL",
"GOLF AMATEUR",
"GOLF PROFESIONAL",
"HALTEROFILIA  (PESAS) PROFESIONAL",
"HALTEROFILIA (PESAS) AMATEUR",
"HANDBALL / BALONMANO AMATEUR",
"HOCKEY AMATEUR",
"HOCKEY PROFESIONAL",
"JOGGING (TROTAR) AMATEUR Y PROFESIONAL",
"JUDO AMATEUR",
"JUDO PROFESIONAL",
"KARATE AMATEUR",
"KARATE PROFESIONAL",
"KARTING PROFESIONAL, AMATEUR Y RECREATIVO",
"KITESURF",
"LUCHA GRECOROMANA AMATEUR Y PROFESIONAL",
"LUCHA LIBRE AMATEUR",
"LUCHA LIBRE PROFESIONAL",
"MARATONISTA AMATEUR",
"MARATONISTA PROFESIONAL",
"MARCHISTA AMATEUR",
"MARCHISTA PROFESIONAL",
"MONTAÑISMO AMATEUR",
"MONTAÑISMO PROFESIONAL",
"MOTO CROSS",
"MOTOCICLISMO AFICIONADO (CARAVANAS)",
"MOTOCICLISMO MEDIO DE TRANSPORTE",
"MOTOCICLISMO PROFESIONAL",
"MOTOCICLISMO RECREATIVO (OCASIONAL)",
"MOTOCICLISMO TRANSPORTE Y AFICIONADO (CARAVANAS)",
"NATACIÓN AMATEUR",
"NATACIÓN PROFESIONAL",
"NAVEGACIÓN DE RÍOS RÁPIDOS  PROFESIONAL",
"PARACAIDISMO AMATEUR",
"PARACAIDISMO PROFESIONAL",
"PARAPENTE AMATEUR",
"PARAPENTE PROFESIONAL",
"PESCA AMATEUR",
"PESCA AMATEUR (de 1 a 3 días a la semana)",
"PESCA PROFESIONAL",
"PILATES AMATEUR Y PROFESIONAL",
"RAFFTING AMATEUR",
"RAFFTING AMATEUR (de 1 a 3 días a la semana)",
"RAFFTING PROFESIONAL",
"RAPPEL AMATEUR",
"RAPPEL AMATEUR (de 1 a 3 días a la semana)",
"RAPPEL PROFESIONAL",
"RUGBY AMATEUR",
"RUGBY PROFESIONAL",
"SPINNING AMATEUR",
"SPINNING PROFESIONAL",
"SQUASH AMATEUR",
"SQUASH PROFESIONAL",
"TAE BO PROFESIONAL",
"TAE KWON DO",
"TAE KWON DO PROFESIONAL",
"TAUROMAQUÍA AMATEUR",
"TAUROMAQUIA PROFESIONAL",
"TENIS AMATEUR",
"TENIS DE MESA AMATEUR Y PROFESIONAL",
"TENIS PROFESIONAL",
"TIRO CON ARCO AMATEUR",
"TIRO CON ARCO PROFESIONAL",
"TIRO CON PISTOLA AMATEUR",
"TIRO CON PISTOLA PROFESIONAL",
"TOCHITO BANDERA",
"TRIATLÓN AMATEUR",
"TRIATLÓN PROFESIONAL",
"VIAJE EN GLOBO PROFESIONAL",
"VOLIBOL AMATEUR",
"VOLIBOL PROFESIONAL",
"YOGA AMATEUR Y PROFESIONAL",
];

export const pasoUno = 
[
    {
        campo: "deporte",
        pregunta:"¿Prácticas algún deporte de manera profesional?",
        preguntaAdicional:"¿Practica algún deporte de manera profesional?",
        respuestas:[
            {respuesta:"Sí, orgullosamente",valor:"1",respuestaAdicional:"Sí, orgullosamente"},
            {respuesta:"Para nada",valor:"0",respuestaAdicional:"Para nada"},
        ],
        tipoRespuesta:"combo",
        preguntasDetalle:[
            {pregunta:"¿Qué deporte?",nombre:"descrDeporte",valores: deportes}
        ],
        identificador:"D",
    },
    {
        campo: "fumar",
        pregunta:"Oye, ¿fumas?",
        preguntaAdicional:"Oye, ¿fuma?",
        respuestas:[
            {respuesta:"Sí!",valor:"1",respuestaAdicional:"Sí!"},
            {respuesta:"Para nada",valor:"0",respuestaAdicional:"Para nada"},
        ],
        tipoRespuesta:"combo",
        preguntasDetalle:[
            {pregunta:"¿Cuántos cigarros?",nombre:"cantidad",valores:fillCantidad()},
            {pregunta:"¿Cada cuánto?",nombre:"frecuencia",valores:frecuencias},
            {pregunta:"¿Desde cuándo?",nombre:"dtDesdeCuando",valores:fillDesdeCuando()}
        ],
        identificador:"F",
    },
    {
        campo: "beber",
        pregunta:"Y, ¿tú tomas bebidas con alcohol?",
        preguntaAdicional:"Y, ¿toma bebidas con alcohol?",
        respuestas:[
            {respuesta:"¡Oh, sí!",valor:"1",respuestaAdicional:"¡Oh, sí!"},
            {respuesta:"¡No, yo pura agüita!",valor:"0",respuestaAdicional:"¡No, pura agüita!"},
        ],
        tipoRespuesta:"combo",
        preguntasDetalle:[
            {pregunta:"¿Cuántas copas?",nombre:"cantidad",valores:fillCantidad()},
            {pregunta:"¿Cada cuánto?",nombre:"frecuencia",valores:frecuencias},
            {pregunta:"¿Desde cuándo?",nombre:"dtDesdeCuando",valores:fillDesdeCuando()}
        ],
        identificador:"B",
    },
    {
        campo: "drogarse",
        pregunta:"¿Qué tal drogas y/o estimulantes? ¿Consumes?",
        preguntaAdicional:"¿Qué tal drogas y/o estimulantes? ¿Consume?",
        respuestas:[
            {respuesta:"¡Claro!",valor:"1",respuestaAdicional:"¡Claro!"},
            {respuesta:"¡Para nada!",valor:"0",respuestaAdicional:"¡Para nada!"},
        ],
        tipoRespuesta:"combo",
        preguntasDetalle:[
            {pregunta:"¿Cuántas dosis?",nombre:"cantidad",valores:fillCantidad()},
            {pregunta:"¿Cada cuánto?",nombre:"frecuencia",valores:frecuencias},
            {pregunta:"¿Desde cuándo?",nombre:"dtDesdeCuando",valores:fillDesdeCuando()}
        ],
        identificador:"D",
    },
];

export const pasoDos = 
[
    {
        campo: "peso",
        pregunta:"¿Cuánto pesas? (¡Sin pena! Nadie es perfect@.)",
        preguntaAdicional:"¿Cuánto pesa? (¡Sin pena! Nadie es perfect@.)",
        placeholder:"Peso (Kgs)",
        tipoRespuesta:"input",
    },
    {
        campo: "estatura",
        pregunta:"Y, ¿cuánto mides?",
        preguntaAdicional:"Y, ¿cuánto mide?",
        placeholder:"Estatura (Cms)",
        tipoRespuesta:"input",
    },
];

export const pasoTres = 
[
    {
        campo: "congenita",
        pregunta:"Oye, ¿al nacer tuviste algún problemita que necesitara cirugía u hospitalización prolongada? ¿Sufres de alguna enfermedad congénita?",
        preguntaAdicional:"Oye, ¿al nacer tuvo algún problemita que necesitara cirugía u hospitalización prolongada? ¿Sufre de alguna enfermedad congénita?",
        respuestas:[
            {respuesta:"Sí, ni modo",valor:"1",emoticon:null,respuestaAdicional:"Sí, ni modo"},
            {respuesta:"No, tuve suerte",valor:"0",emoticon:null,respuestaAdicional:"No, tuvo suerte"},
        ],
        tipoRespuesta:"form",
    },
    {
        campo: "hospitalizaciones",
        pregunta:"¿Te han hospitalizado alguna vez? ",
        preguntaAdicional:"¿L@ han hospitalizado alguna vez?",
        respuestas:[
            {respuesta:"Sí",valor:"1",emoticon:0,respuestaAdicional:"Sí"},
            {respuesta:"No, me he salvado",valor:"0",emoticon:1,respuestaAdicional:"No, se ha salvado"},
        ],
        tipoRespuesta:"listaPreguntas",
    },
];

export const pasoCuatro = 
[
    {
        campo: "prostata",
        pregunta:"Psst psst… ¿Te has hecho exámenes de próstata?",
        preguntaAdicional:"Psst psst… ¿Se ha hecho exámenes de próstata?",
        respuestas:[
            {respuesta:"Claro",valor:"1"},
            {respuesta:"Aún no",valor:"0"},
        ],
        tipoRespuesta:"combo",
        preguntasDetalle:[
					{pregunta:"¿Cuándo fue la última vez?",nombre:"Fecha",valores:ultimaVez,preguntaAdicional:"¿Cuándo fue la última vez?",valoresAdicional:ultimaVez},
					{pregunta:"¿Qué te dijeron?",nombre:"resultado",valores:resultadoRevision,preguntaAdicional:"¿Qué le dijeron?",valoresAdicional:resultadoRevisionAdicional}
        ],
        identificador:"P",
    },
    {
        campo: "revisionMujer",
        pregunta:"¿Te has hecho un papanicolau y/o una colposcopía?",
        preguntaAdicional:"¿Se ha hecho un papanicolau y/o una colposcopía?",
        respuestas:[
            {respuesta:"Claro",valor:"1"},
            {respuesta:"Aún no",valor:"0"},
        ],
        tipoRespuesta:"combo",
        preguntasDetalle:[
					{pregunta:"¿Cuándo fue la última vez?",nombre:"Fecha",valores:ultimaVez,preguntaAdicional:"¿Cuándo fue la última vez?",valoresAdicional:ultimaVez},
					{pregunta:"¿Qué te dijeron?",nombre:"resultado",valores:resultadoRevision,preguntaAdicional:"¿Qué le dijeron?",valoresAdicional:resultadoRevisionAdicional}
        ],
        identificador:"M",
    },
    {
        campo: "revisionMujer2",
        pregunta:"¿Qué tal una mastografía y/o un ultrasonido mamario?",
        preguntaAdicional:"¿Qué tal una mastografía y/o un ultrasonido mamario?",
        respuestas:[
            {respuesta:"Claro",valor:"1"},
            {respuesta:"Aún no",valor:"0"},
        ],
        tipoRespuesta:"combo",
        preguntasDetalle:[
					{pregunta:"¿Cuándo fue la última vez?",nombre:"Fecha",valores:ultimaVez,preguntaAdicional:"¿Cuándo fue la última vez?",valoresAdicional:ultimaVez},
					{pregunta:"¿Qué te dijeron?",nombre:"resultado",valores:resultadoRevision,preguntaAdicional:"¿Qué le dijeron?",valoresAdicional:resultadoRevisionAdicional}
        ],
        identificador:"MS",
    },
    {
        campo: "revisionGral",
        pregunta:"¿Te has hecho alguna revisión médica general últimamente?",
        preguntaAdicional:"¿Se ha hecho alguna revisión médica general últimamente?",
        respuestas:[
            {respuesta:"Claro",valor:"1"},
            {respuesta:"Aún no",valor:"0"},
        ],
        tipoRespuesta:"combo",
        preguntasDetalle:[
            {pregunta:"¿Cuándo fue la última vez?",nombre:"Fecha",valores:ultimaVez,preguntaAdicional:"¿Cuándo fue la última vez?",valoresAdicional:ultimaVez},
            {pregunta:"¿Qué te dijeron?",nombre:"resultado",valores:resultadoRevision,preguntaAdicional:"¿Qué le dijeron?",valoresAdicional:resultadoRevisionAdicional}
        ],
        identificador:"G",
    },
];

export const padecimientos = [
    {
        pregunta: "Algún tipo de cáncer",
        descripcion: "(incluyendo leucemia y linfomas)",
        padecimiento: "cancer",
				lista: [
					"Cáncer",
				]
    },
    {
        pregunta: "Algo del corazón",
        descripcion: "(Presión arterial, infarto agudo al miocardio, angina de pecho u algún otro)",
        padecimiento: "corazon",
				lista: [
					"Presión aterial",
					"infarto agudo al miocardio",
					"angina de pecho",
				]
    },
    {
        pregunta: "Algo neurológico",
        descripcion: "(Hemorragias, infartos, tumores, entre otros)",
        padecimiento: "neurologico",
				lista: [
					"Hemorraias",
					"Infarto",
					"Tumores",
				]
    },
    {
        pregunta: "Algo digestivo",
        descripcion: "(Problemas gástricos, cualquier tipo de hernia, intestinales, hepáticos, entre otros)",
        padecimiento: "digestivo",
				lista: [
					"Hernia intestinal",
					"Problemas hépáticos",
				]
    },
    {
        pregunta: "Algo endocrinológico",
        descripcion: "(Diabetes mellitus, hipotiroidismo, tiroides, hipertiroidismo, entre otros)",
        padecimiento: "endocrinologico",
				lista: [
					"Diabetes Mellitus",
					"hipotiroidismo",
					"Tiroides",
					"Hipertiroidismo",
				]
    },
    {
        pregunta: "Algo pulmonar",
        descripcion: "(Epoc, enficema pulmonar, entre otros)",
        padecimiento: "pulmonar",
				lista: [
					"EPOC",
					"Eficema pulmonar",
				]
    },
    {
        pregunta: "Algo inmunológico",
        descripcion: "(Artritis reumatoide, lupus eritematoso, defectos de la coagulación, entre otros)",
        padecimiento: "inmunologico",
				lista: [
					"Artritis Reumatoide",
					"Lupus eritematoso",
					"Defectos de coagulación",
				]
    },
    {
        pregunta: "Algo de infectología",
        descripcion: "(VIH, sida, sífilis, tuberculosis, enfermedades infectocontagiosa tales como meningitis, entre otras)",
        padecimiento: "infectologia",
				lista: [
					"VIH",
					"Sifilis",
					"Tuberculosis",
					"Meningitis",
				]
    },
    {
        pregunta: "Algo ginecológico",
        descripcion: "(Alteración de la matriz/útero, ovarios, trompas de falopio, mamas, etc.)",
        padecimiento: "ginecologico",
				lista: [
					"Alterción de la matriz / utero",
					"Ovarios",
					"Trompas de falopio",
					"Mamas",
				]
    },
    {
        pregunta: "Algo  múscular/esquelético",
        descripcion: "(Osteoporosis, osteoartritis, osteopenia, columna vertebral, entre otros)",
        padecimiento: "muscular",
				lista: [
					"Osteoporosis",
					"Osteoartritis",
					"Ostopenia",
					"Fracturas de hueso",
				]
    },
    {
        pregunta: "Algo oftalmológico",
        descripcion: "(miopias, astigmatismo, hipermetropía, glaucomoma, estrabismo, catarátas, entre otras)",
        padecimiento: "oftalmologico",
				lista: [
					"Miopia",
					"Astigmatismo",
					"Hipermetropía",
					"Glaucoma",
					"Estrabismo",
					"Catarátas",
				]
    },
    {
        pregunta: "Algo auditivo ",
        descripcion: "(Otoesclerosis, disminución de la agudeza auditiva)",
        padecimiento: "auditivo",
				lista: [
					"Otoesclerosis",
					"Disminución de agudeza auditiva",
				]
    },
    {
        pregunta: "Algo dermatológico",
        descripcion: "(Vitiligo, rosacea, entre otros)",
        padecimiento: "dermatologico",
				lista: [
					"Vitiligo",
					"Rosacea",
				]
    },
    {
        pregunta: "Algo de las vías urinarias",
        descripcion: "(Riñón, urétra, vejiga, próstata, etc.)",
        padecimiento: "viasUrinarias",
				lista: [
					"Vias urinarias",
					"Riñon",
					"Uretra",
					"Vejiga",
					"Próstata",
				]
    },
    {
        pregunta: "Padecimientos de vesícula o apendicitis ",
        descripcion: "",
        padecimiento: "padecimientosO",
				lista: [
					"Vesícula",
					"Apendicitis",
				]
    },
];

export const congenita = [
    "Intervención Quirurgica",
    "Hospitalización al nacer",
];