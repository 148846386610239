import {
  Alert,
  Box,
  Grid,
  IconButton,
  MenuItem,
  Slide,
  Snackbar,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Api from "../Api/Api";
import { calcularEdad, formatDate } from "../Utils/Utils";
import "./crm.css";
import Select from "@mui/material/Select";
import clsx from "clsx";
import NotesViewer from "./components/NotesViewer";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import avatar from "../../assets/crm/avatar.png";
import { useParams } from "react-router-dom";
import {
  DOMICILIO,
  GOBER,
  INE,
  INTER_CONTRATANTE,
  TELEFONO,
} from "../Utils/Constantes";
import LeftRotateIcon from "./components/LeftRotateIcon";
import RightRotateIcon from "./components/RightRotateIcon";
import AccordionItem from "./components/AccordionItem";
import mapperFromDocumentsToDocumentsForAccordions from "../Utils/mapperFromDocumentsToDocumentsForAccordions";
import UpdateDocumentsButton from "./components/UpdateDocumentsButton";
import CrmFooter from "./components/CrmFooter";
import CrmHeader from "./components/CrmHeader";
import { AlertSnackBar } from "../Commons/AlertSnackBar";

export default function CRM(props) {
  let { folio } = useParams();

  const [datos, setDatos] = useState({});
  const [edad, setEdad] = useState(0);
  const [status, setStatus] = useState(400);
  const [openId, setOpenId] = useState(false);
  const [openDom, setOpenDom] = useState(false);
  const [openGob, setOpenGob] = useState(false);
  const [openDatos, setOpenDatos] = useState(false);
  const [expanded, setExpanded] = React.useState("");
  const [openOt, setOpenOt] = useState(false);
  const [openOs, setOpenOs] = useState(false);
  const [documentsForAccordions, setDocumentsForAccordions] = useState([]);
  const [profilePhoto, setProfilePhoto] = useState();
  const [openNotas, setOpenNotas] = useState(false);
  const [documentNameForNotes, setDocumentNameForNotes] = useState("");
  const [documentoActualizar, setDocumentoActualizar] = useState({});
  const [documents, setDocuments] = useState([]);
  const [rotation, setRotation] = useState(0);
  const [alertOptions, setAlertOptions] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    Api.getContratante(folio)
      .then((res) => {
        window.sessionStorage.setItem(
          INTER_CONTRATANTE,
          JSON.stringify(res.data.contratante)
        );
        setDatos(res.data.contratante);
        setStatus(res.data.contratante.estatus);
        setEdad(calcularEdad(new Date(res.data.contratante.fechanacimiento)));
        Api.getVerificacionDocs(folio)
          .then((res) => {
            const documentsFromRequest = res.data;
            setDocumentsForAccordions(
              mapperFromDocumentsToDocumentsForAccordions(documentsFromRequest)
            );
            setDocuments(documentsFromRequest);
            const photoDocument = documentsFromRequest.find(
              (document) => document.documentName === "FOTO"
            );
            const thereArePhoto = photoDocument.photos.length > 0;
            setProfilePhoto(
              thereArePhoto
                ? `data:image/png;base64,${photoDocument.photos[0]}`
                : avatar
            );
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [folio]);

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
    Api.setStatus(event.target.value, folio);
  };

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    const panelToOpen = panel === "panel-OS" ? "panel-OT" : panel;
    setExpanded(newExpanded ? panelToOpen : false);
    setOpenOs(false);
    setOpenOt(false);
    setOpenId(false);
    setOpenDom(false);
    setOpenGob(false);
    setOpenDatos(false);
    setOpenNotas(false);
    setScale(1);
    setRotation(0);
    switch (panel) {
      case "panel-" + INE:
        setOpenId(!openId);
        break;
      case "panel-" + DOMICILIO:
        setOpenDom(!openDom);
        break;
      case "panel-" + GOBER:
        setOpenGob(!openGob);
        break;
      case "panel-" + TELEFONO:
        setOpenDatos(!openDatos);
        break;
      case "panel-OT":
        setOpenOt(true);
        break;
      case "panel-OS":
        setOpenOs(true);
        break;
      default:
        break;
    }
  };

  const handleOpenNotas = (documentName) => {
    setOpenDatos(false);
    setOpenNotas(true);
    setDocumentNameForNotes(documentName);
  };

  const handleCloseNotas = () => {
    setOpenNotas(false);
  };

  const handleChangeDocumentsStatus =
    (documentToChangeStatus) =>
    ({ target }) => {
      setDocumentoActualizar({
        ...documentToChangeStatus,
        newStatus: target.value,
      });
    };

  const rightRotate = () => {
    let newRotation = rotation + 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    setRotation(newRotation);
  };

  const leftRotate = () => {
    let newRotation = rotation - 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    setRotation(newRotation);
  };

  const [scale, setScale] = useState(1);
  function zoomOut() {
    setScale(scale - 0.5);
  }

  function zoomIn() {
    setScale(scale + 0.5);
  }
  const convertDataURIToBinary = (data) => {
    const linkSource = `data:application/pdf;base64,${data}`;
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = linkSource.substr(
      "data:application/pdf;base64,".length
    );

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return URL.createObjectURL(new Blob([out], { type: "application/pdf" }));
  };

  const ButtonsGroup = (props) => {
    const { documentSelected } = props;
    const fileEncoded = documentSelected.photos;

    return (
      <Box className="group-buttons">
        <Stack className="border">
          <IconButton size="large" disabled={scale === 1} onClick={zoomOut}>
            <ZoomOutIcon className="gray-icon" fontSize="inherit" />
          </IconButton>
        </Stack>
        <Stack className="border bt-0 ">
          <IconButton size="large" disabled={scale >= 10} onClick={zoomIn}>
            <ZoomInIcon className="gray-icon" fontSize="inherit" />
          </IconButton>
        </Stack>
        <Stack className="border">
          <IconButton size="large" onClick={rightRotate}>
            <RightRotateIcon className="gray-icon" fontSize="inherit" />
          </IconButton>
        </Stack>
        <Stack className="border bt-0 ">
          <IconButton size="large" onClick={leftRotate}>
            <LeftRotateIcon className="gray-icon" fontSize="inherit" />
          </IconButton>
        </Stack>
        <Stack className="border bt-0">
          <a
            href={`data:image/png;base64,${fileEncoded[0]}`}
            download={documentSelected.documentName}
          >
            <IconButton size="large" disabled={scale >= 10}>
              <FileDownloadOutlinedIcon
                className="gray-icon"
                fontSize="inherit"
              />
            </IconButton>
          </a>
        </Stack>
      </Box>
    );
  };

  const handleAlertClose = () => {
    setOpen(false);
  }

  return (
    <>
      <CrmHeader cotizacion={folio} />
      <Box
        style={{
          padding: "1.5rem 10vw 3.25rem 10vw",
          backgroundColor: "#F4F4F4",
        }}
      >
        <Box
          style={{
            border: "1px solid #E3E3E3",
            boxShadow: "4px 6px 16px hsla(0, 0%, 13%, 0.05)",
            backgroundColor: "#FFF",
          }}
        >
          <Grid container direction="row">
            <div className="circulo-gde fix-crm">
              <div className="circulo  fix-crm"></div>
            </div>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className="perfil">
              <Box className="foto">
                <img src={profilePhoto} alt="" className="profile-photo-img" />
              </Box>
              <Grid container direction="column" className="profile-data-main-container">
                <Grid container className="profile-data-container">
                  <Grid item className="profile-data-title">
                    Datos del cliente
                  </Grid>
                </Grid>
                <Grid container className="profile-data-container" direction="column">
                  <Grid item className="profile-data-subtitle">
                    Nombre
                  </Grid>
                  <Grid item>
                    {datos.nombre !== undefined &&
                      datos.nombre +
                        " " +
                        datos.aPaterno +
                        " " +
                        datos.aMaterno}
                  </Grid>
                </Grid>
                <Grid container className="profile-data-container" direction="column">
                  <Grid item className="profile-data-subtitle">
                    Sexo
                  </Grid>
                  <Grid item>{datos.genero}</Grid>
                </Grid>
                <Grid container className="profile-data-container" direction="column">
                  <Grid item className="profile-data-subtitle">
                    Edad
                  </Grid>
                  <Grid item>{edad}</Grid>
                </Grid>
                <Grid container className="profile-data-container" direction="column">
                  <Grid item className="profile-data-title">
                    Datos de la solicitud
                  </Grid>
                </Grid>
                <Grid container className="profile-data-container" direction="column">
                  <Grid item className="profile-data-subtitle">
                    Número de cotización
                  </Grid>
                  <Grid item>{folio}</Grid>
                </Grid>
                <Grid container className="profile-data-container" direction="column">
                  <Grid item className="profile-data-subtitle">
                    Plan
                  </Grid>
                  <Grid item>{datos.cotizaciones
                      ? datos.cotizaciones[0].nombrePaquete
                      : ""}</Grid>
                </Grid>
                <Grid container className="profile-data-container" direction="column">
                  <Grid item className="profile-data-subtitle">
                    Aseguradora
                  </Grid>
                  <Grid item>{datos.cotizaciones
                      ? datos.cotizaciones[0].nombreAseguradora
                      : ""}</Grid>
                </Grid>
                <Grid container className="profile-data-container" direction="column">
                  <Grid item className="profile-data-subtitle">
                    Fecha de carga de documentos
                  </Grid>
                  <Grid item>{datos.fechaCargaInicialDocumentos
                      ? formatDate(datos.fechaCargaInicialDocumentos)
                      : ""}</Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
              <Grid
                container
                style={{
                  minHeight: "60px",
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "0 1rem",
                  border: "solid #E3E3E3 1px",
                }}
              >
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ marginRight: '1.8rem' }}>Estatus de la solicitud</p>
                    <Select
                      id="status"
                      name="status"
                      disableUnderline
                      style={{ maxHeight: "26px" }}
                      IconComponent={() => <KeyboardArrowDownIcon />}
                      className={clsx(
                        { "bkg-cmb-gray": status === 400 },
                        { "bkg-green": status === 200 },
                        { "bkg-red": status === 500 },
                        { "blue-bar-subtext cmb-w": true }
                      )}
                      variant="standard"
                      value={status}
                      onChange={handleChangeStatus}
                    >
                      <MenuItem value={400} className="bkg-cmb-gray">
                        Por verificar
                      </MenuItem>
                      <MenuItem value={200} className="bkg-green">
                        Aprobada
                      </MenuItem>
                      <MenuItem value={500} className="bkg-red">
                        Rechazada
                      </MenuItem>
                    </Select>
                  </Box>
                </Grid>
              </Grid>
              <Grid container direction="row" className="min-h">
                <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className="items">
                  {documentsForAccordions.map((document, id) => (
                    <AccordionItem
                      document={document}
                      title={document.title}
                      contratante={datos}
                      expanded={expanded}
                      handleChangeAccordion={handleChangeAccordion}
                      handleOpenNotas={handleOpenNotas}
                      onEstatusChange={handleChangeDocumentsStatus}
                      key={id}
                    />
                  ))}
                  <UpdateDocumentsButton
                    documentWithNewStatus={documentoActualizar}
                    setAlertOptions={setAlertOptions}
                    setOpen={setOpen}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={7} className="detail">
                  <Grid container direction="row" className="hw-100">
                    <Grid item lg={12} xl={12} className="hw-100">
                      {openNotas && (
                        <NotesViewer
                          documento={documentNameForNotes}
                          onClose={handleCloseNotas}
                        ></NotesViewer>
                      )}
                      <Box className="hw-100">
                        {openOs && (
                          <Box className="flex-direction-column hw-100">
                            {(() => {
                              const documentFound = documents.find(
                                (document) => document.documentName === "OS"
                              );
                              if (documentFound.photos.length <= 0) return;
                              return documentFound.photos.map(
                                (photo, index) => (
                                  <>
                                    <embed
                                      src={convertDataURIToBinary(photo)}
                                      key={index}
                                      alt=""
                                      className="hw-100"
                                    />
                                    <br />
                                  </>
                                )
                              );
                            })()}
                          </Box>
                        )}
                        {openOt && (
                          <Box className="flex-direction-column hw-100">
                            {documents.map(
                              (current, index) =>
                                current.documentName === "OT" && (
                                  <React.Fragment key={index}>
                                    {current.photos &&
                                      current.photos.map((photo, jndex) => (
                                        <>
                                          <embed
                                            src={
                                              "data:application/pdf;base64," +
                                              photo
                                            }
                                            key={jndex}
                                            alt=""
                                            className="hw-100"
                                          />
                                          <br />
                                        </>
                                      ))}
                                  </React.Fragment>
                                )
                            )}
                          </Box>
                        )}
                        {openId && (
                          <Box className="center-content flex-direction-column hw-100">
                            <ButtonsGroup
                              documentSelected={documents.find(
                                (document) => document.documentName === "INE"
                              )}
                            />
                            <Box className="center-content flex-direction-column">
                              {documents.map(
                                (current, index) =>
                                  current.documentName === INE && (
                                    <React.Fragment key={index}>
                                      {current.photos.map((photo, jndex) => (
                                        <>
                                          <img
                                            src={
                                              "data:image/png;base64," + photo
                                            }
                                            key={jndex}
                                            alt=""
                                            style={{
                                              transform: `scale(${scale}) rotate(${rotation}deg)`,
                                              width: "60%",
                                            }}
                                          />
                                          <br />
                                        </>
                                      ))}
                                    </React.Fragment>
                                  )
                              )}
                            </Box>
                          </Box>
                        )}
                        {openDom && (
                          <Box className="center-content flex-direction-column hw-100">
                            <ButtonsGroup
                              documentSelected={documents.find(
                                (document) =>
                                  document.documentName === DOMICILIO
                              )}
                            />
                            <Box className="center-content flex-direction-column">
                              {documents.map(
                                (current, index) =>
                                  current.documentName === DOMICILIO && (
                                    <React.Fragment key={index}>
                                      {current.photos.map((photo, jndex) => (
                                        <>
                                          <img
                                            src={
                                              "data:image/png;base64," + photo
                                            }
                                            key={jndex}
                                            alt=""
                                            style={{
                                              transform: `scale(${scale}) rotate(${rotation}deg)`,
                                              width: "60%",
                                            }}
                                          />
                                          <br />
                                        </>
                                      ))}
                                    </React.Fragment>
                                  )
                              )}
                            </Box>
                          </Box>
                        )}
                        {openDatos && (
                          <Grid container direction="row" className="">
                            <Grid container direction="row" className="">
                              <Grid
                                item
                                lg={12}
                                xl={12}
                                className="padding-box-datos"
                              >
                                <Box className="padding-datos bkg-gray">
                                  <p className="texto-dato">Tel&eacute;fono</p>
                                  <p className="texto-valor">
                                    {datos.telefono}
                                  </p>
                                </Box>
                              </Grid>
                              <Grid
                                item
                                lg={12}
                                xl={12}
                                className="padding-box-datos pt-0"
                              >
                                <Box className="padding-datos bkg-gray">
                                  <p className="texto-dato">
                                    Correo electr&oacute;nico
                                  </p>
                                  <p className="texto-valor">{datos.correo}</p>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        {openGob && (
                          <Grid container direction="row" className=""></Grid>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CrmFooter isMobile={props.isMobile} />
      <AlertSnackBar 
        open={open} 
        severity={alertOptions.severity} 
        message={alertOptions.message} 
        handleAlertClose={handleAlertClose} 
      />
    </>
  );
}
