
import { IconButton, styled, Tooltip } from '@mui/material';
import { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';

export default function BlueTooltip(props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const BlueTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
  ))(`
      color: black;
      background-color: #EBF8FB;
  `);
  return (
    <BlueTooltip  placement="bottom"
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      title={props.title}>
      <IconButton disableTouchRipple onClick={() => setShowTooltip(!showTooltip)}><InfoIcon color="primary" /></IconButton>
    </BlueTooltip>
  );
}
