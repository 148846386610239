import * as React from 'react';
import {Box,Button,TextField,Stack,Modal,Select,InputLabel,FormControl, MenuItem} from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import IconEditar from '../../assets/img/planesCotizacion/modal/Lapiz-edicion.svg';
import Api from '../Api/Api';
import './PlanesCotizacion.css'
import { maxLength } from '../Utils/FieldValidationFunctions';

export default function EditarPerfilCotizadorModal(props) {
    const dataObj = JSON.parse(sessionStorage.getItem("interContratante"))
    const dataTitular = dataObj.asegurados.find(asegurado => asegurado.titular === true);
    const nombreAusar = dataObj.tipoFlujo === 3 ? dataTitular.nombre : dataObj.nombre;
    const [name, setName] = React.useState(nombreAusar);
    const [phone, setPhone] = React.useState(dataObj.telefono);
    const [email, setEmail] = React.useState(dataObj.correo);
    const [Open, setOpenModalCotizador] = React.useState(false);

    const dateOfBirht = dataObj.tipoFlujo === 3 ? dataTitular.fechanacimiento : dataObj.fechanacimiento;

    const handleOpenModalCotizador = () => setOpenModalCotizador(true);
    const handleCloseModalCotizador = () => setOpenModalCotizador(false);

    const handleGuardarDatos = () => {
        let auxObj = { nombre: name, correo: email, telefono: phone }
        if (dataObj.tipoFlujo === 3) {
            dataTitular.nombre = auxObj.nombre
            dataTitular.telefono = auxObj.correo
            dataTitular.correo = auxObj.telefono
            Api.actualizaAsegurado(dataObj.id, dataTitular)
                .then(res => {
                    Api.getContratante(dataObj.folio)
                        .then(res => {
                            sessionStorage.setItem("interContratante", JSON.stringify(res.data.contratante))
                            props.cerraModal(auxObj);
                            setOpenModalCotizador(false);
                        })
                        .catch(error => {
                            console.log(error);
                        });
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            dataObj.nombre = auxObj.nombre
            dataObj.telefono = auxObj.correo
            dataObj.correo = auxObj.telefono
            dataTitular.nombre = auxObj.nombre
            dataTitular.telefono = auxObj.correo
            dataTitular.correo = auxObj.telefono
            Api.actualizaContratante(dataObj.id, dataObj)
                .then(res => {
                    Api.actualizaAsegurado(dataObj.id, dataTitular)
                        .then(res => {
                            Api.getContratante(dataObj.folio)
                                .then(res => {
                                    sessionStorage.setItem("interContratante", JSON.stringify(res.data.contratante))
                                    props.cerraModal(auxObj);
                                    setOpenModalCotizador(false);
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        })
                        .catch(error => {
                            console.log(error);
                        });
                })
                .catch(error => {
                    console.log(error);
                });

        }

    }

    const handleChangeName = (e) => {
        setName(e.target.value)
    }
    const handleChangeTelefono = (e) => {
        let val = e.target.value;
        val = maxLength(val, 10);
        setPhone(val)
    }
    const handleChangeCorreo = (e) => {
        setEmail(e.target.value)
    }

    return (
        <>
            <Button onClick={handleOpenModalCotizador}><img alt="icon-editar" src={IconEditar} /></Button>
            <Modal
                open={Open}
                onClose={handleCloseModalCotizador}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {
                        xs: "80%",
                        sm: "80%",
                        md: "30%",
                        lg: "40%",
                        xl: "30%",
                    },
                    height: {
                        xs: "78%",
                        sm: "70%",
                        md: "550px",
                        lg: "550px",
                        xl: "550px",
                    },
                    bgcolor: 'background.paper',
                    border: 'none',
                    borderRadius: '8px',
                    boxShadow: '',
                    p: 3,
                    textAlign: "-webkit-center"
                }
                } >
                    <h2 className="txt-principal-modal">
                        ¿Quieres editar la informaci&oacute;n
                        del asegurado titular?
                    </h2>
                    <TextField value={name} label="Nombre" variant="outlined" sx={{ m: 1, width:"80%" }} onChange={handleChangeName} />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            disabled
                            label="Fecha de nacimiento"
                            value={dateOfBirht}
                            renderInput={(params) => <TextField {...params} sx={{ m: 1, width:"80%" }} />}
                            disableFuture onChange={(newValue) => console.log(newValue)}
                        />
                    </LocalizationProvider>
                    <TextField value={phone} label="Teléfono" variant="outlined" sx={{ m: 1, width:"80%" }} onChange={handleChangeTelefono} />
                    <TextField value={email} label="E-mail" variant="outlined" sx={{ m: 1, width:"80%" }} onChange={handleChangeCorreo} />
                    <FormControl sx={{ m: 1, width:"80%" }}>
                        <InputLabel id="demo-simple-select-helper-label">G&eacute;nero</InputLabel>
                        <Select
                            disabled
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={dataObj.genero}
                            label="Age"
                        >
                            <MenuItem value={dataObj.genero}>{dataObj.genero}</MenuItem>
                        </Select>
                    </FormControl>
                    <Stack direction="row" spacing={4} alignItems="center" justifyContent="center">
                        <Button onClick={handleCloseModalCotizador} className="btn-cancelar-modal">Cancelar</Button>
                        <Button onClick={handleGuardarDatos} className="btn-guardar-modal">GUARDAR</Button>
                    </Stack>

                </Box>
            </Modal>
        </>
    );
}
