import './Modal.css';
import { CircularProgress, DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import logo from '../../assets/img/loader/LoaderGMM.svg';

export default function Loading(props) {

  const handleClickCloseLoader = (event, reason) => {
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={(event, reason) => handleClickCloseLoader(event, reason)} disableEscapeKeyDown={true}
      maxWidth="sm" fullWidth={true}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          height: '100%'
        },
      }}
    >
    <DialogContent>
      <div className="modal-contenedor">
        <div className="modal-loading">
            <img className="logoCoche" src={logo} width="200px" height="110px" alt=""/>
            <CircularProgress
              size={110}
              sx={{
                color: "#039ECC",
                position: 'absolute',
                top: -20,
                left: 45,
                zIndex: 1,
              }}
            />
        </div>
      </div>
          </DialogContent>
    </Dialog>
  );
}