import React from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  Box,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  AccordionActions,
  Button,
  ListItemIcon,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import EstatusDocumento from "./EstatusDocumento";

const AccordionItem = (props) => {
  const {
    document,
    expanded,
    handleChangeAccordion,
    title,
    handleOpenNotas,
    onEstatusChange
  } = props;

  const handleOpenOtOs = (documentStage) => () => {
    handleChangeAccordion('panel-' + documentStage.documentCode)({}, true);
  }

  return (
    <Grid container direction="row" className="bb-validacion">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Accordion
          expanded={expanded === "panel-" + document.documentName}
          onChange={handleChangeAccordion("panel-" + document.documentName)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content-0"
            id="panel-header-0"
            sx={{ minHeight: '56px' }}
          >
            <Box className="titulo-validacion">
              {(() => {
                switch (document.estatus) {
                  case 200:
                    return (
                      <CheckCircleOutlineIcon className="green-icon"></CheckCircleOutlineIcon>
                    );
                  case 400:
                    return (
                      <WarningAmberIcon className="yellow-icon"></WarningAmberIcon>
                    );
                  case 500:
                    return (
                      <HighlightOffIcon className="red-icon"></HighlightOffIcon>
                    );
                  default:
                    return null;
                }
              })()}
              {title}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {(document.title !== 'Datos de contacto' && document.title !== 'Documentos de la aseguradora') ?
            (<EstatusDocumento
              document={document}
              key={document.id}
              onEstatusChange={onEstatusChange}
            />) : ''}
            <List dense={true}>
              {document.etapas.map((value, i) => value.docuemntName !== null && (
                <ListItem key={i} onClick={handleOpenOtOs(value)}>
                  <ListItemIcon>
                    {value.success ? <CheckIcon className="green-icon" /> : <PriorityHighIcon className="yellow-icon" />}
                  </ListItemIcon>
                  <ListItemText className={document.title === "Documentos de la aseguradora" ? 'cursor-pointer' : ''} primary={value.docuemntName} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
          <AccordionActions disableSpacing={true}>
            <Button
              className="ver-notas-text text-transform-none"
              onClick={() => {
                handleOpenNotas(document.documentName);
              }}
            >
              Ver notas
            </Button>
          </AccordionActions>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default AccordionItem;
