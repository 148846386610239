import React from "react";
import { Card, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import FormNotQuotationNumber from "./FormQuotation/FormNotQuotationNumber";
import FormHaveQuotationNumber from "./FormQuotation/FormHaveQuotationNumber";

const FormularioCotizacion = () => {
  const [formVisibility, setFormVisibility] = React.useState(true);
  return (
    <Grid
      container
      sx={{
        backgroundColor: "#F2FAFC",
        padding: "40px 24px 80px 24px",
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            rowGap: "16px",
          }}
        >
          <Grid
            item
            container
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Card raised>
              {formVisibility ? (
                <FormNotQuotationNumber />
              ) : (
                <FormHaveQuotationNumber
                  setFormVisibility={setFormVisibility}
                />
              )}
            </Card>
          </Grid>
          {formVisibility ? (
            <Grid item sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  color: "#039ECC",
                  textAlign: "center",
                  textDecoration: "underline",
                  cursor: "pointer",
                  "&:hover": {
                    color: "hsl(193.73,97.1%,60.59%)",
                  },
                }}
                onClick={() => setFormVisibility(false)}
              >
                ¿Ya tienes un número de cotización?
              </Typography>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Container>
    </Grid>
  );
};

export default FormularioCotizacion;
