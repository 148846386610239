import React from 'react';

const RightRotateIcon = ({ className }) => {
  return (
    <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M18.8333 1L25.4999 7.66667L18.8333 14.3333" stroke="#8F8F8F" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M25.5 7.66675H14.8333C7.46933 7.66675 1.5 13.6361 1.5 21.0001V22.3334" stroke="#8F8F8F" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default RightRotateIcon;