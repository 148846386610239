import React from "react";
import { Button, Grid, Box, Link, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Family from "../../assets/landing/family-first-landing.png";
import { useMediaQuery, useTheme } from '@mui/material';


export default function FirstBlockLanding({ clickPlanesAction, clickCotizacionAction }) {

    const { breakpoints } = useTheme();
    const isTablet = useMediaQuery(breakpoints.down('md'));
    const isMobile = useMediaQuery(breakpoints.down('sm'));

    return (
        <Grid container justifyContent="center">
            <Grid item >
                <Typography variant="h1" sx={{
                    fontWeight: "300",
                    fontSize: isMobile ? '28px' : isTablet ? '36px' : "40px",
                    lineHeight: isMobile ? '34.13px' : isTablet ? '43.88px' : "59px",
                    textAlign: "center",
                    letterSpacing: "0.5px",
                    color: "#212121",
                    paddingLeft: isMobile ? '5%' : "10%",
                    paddingRight: isMobile ? '5%' :  "10%",
                    marginTop: '40px'
                }}><strong>¡En Inter tenemos el seguro de 
                gastos médicos ideal para ti!</strong>
                </Typography>
                <Box sx={{ textAlign: "center" }}>
                    <img width="80%" src={Family} alt="family" />
                </Box>

            </Grid>
            <Grid item justifyContent="center" sx={{ textAlign: "center", marginBottom: '40px' }}>
                <Typography sx={{
                    textAlign: "center",
                    fontWeight: "400",
                    fontSize: isMobile ? '24px' : isTablet ? '30px' : "32px",
                    lineHeight: isMobile ? '32.4px' : isTablet ? '36.57px' : "49px",
                    marginBottom: '40px',
                    paddingLeft: isMobile ? '10%' : "15%",
                    paddingRight: isMobile ? '10%' : "15%",
                }}>
                    Con <strong>Médico Seguro</strong> cotiza y elige el plan de salud con las coberturas que mejor se adapten a ti, fácil y en un sólo lugar.
                </Typography>
                <Button onClick={clickPlanesAction} variant="contained" sx={{
                    height: "48px",
                    maxWidth: "382px",
                    borderRadius: "10px",
                    padding: isMobile ? "16px 55px" : isTablet ? '16px 75px' : '16px 100px',
                    backgroundColor: "#039ECC",
                    color: "#fff",
                    marginBottom: '20px'
                }}>
                    ¡Quiero ver planes!
                </Button>
                <Grid item sx={{margin:"10px"}}>
                    <Typography sx={{
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "18px",
                        lineHeight: "24px",
                        display: "flex",
                        textAlign: "center",
                        textDecorationLine: "underline",
                        color: "#039ECC",
                        justifyContent:"center",
                        cursor: "pointer"

                    }} onClick={clickCotizacionAction} >¿Ya tienes un número de cotización?</Typography>

                </Grid>
            </Grid>
        </Grid >


    )
}

