import { CANTIDAD_MAX, CMB_MENOS_UNO, CMB_NECESARIO, initYear, MSG030 } from "./Constantes";
import { useState, useMemo, useEffect } from 'react'

export function validaMayorEdad (fecha) {
  if(calcularEdad(fecha) >= 18){
    return true;
  } else {
    return false;
  }
};

/**
 * recibe cumple como  fecha completa new Date()
 */
export function calcularEdad(cumple) {
  var hoy = new Date();
  var edad = hoy.getFullYear() - cumple.getFullYear();
  var m = hoy.getMonth() - cumple.getMonth();
  if (m < 0 || (m === 0 && hoy.getDate() < cumple.getDate())) {
      edad--;
  }
  return edad;
}

export function fillYears() {
  let years = [];
  const maxYear = new Date();
  for (let index = maxYear.getFullYear(); index > initYear; index--) {
    years.push(index)
  }
  return years;
}

export function fillCantidad() {
  let cantidad = [];
  for (let index = 1; index < CANTIDAD_MAX; index++) {
    cantidad.push(index)
  }
  return cantidad;
}

export function fillDesdeCuando() {
  let desdeCuando = [];
  desdeCuando.push("Menos a 1 año");
  desdeCuando.push("1 año");
  for (let index = 2; index < CANTIDAD_MAX; index++) {
    desdeCuando.push(index+" años")
  }
  return desdeCuando;
}

export function validaCombo(value) {
  let error;
  if (value !== null && value === CMB_MENOS_UNO) {
    error = CMB_NECESARIO;
  }
  return error;
}

export function maxLength(value, maxLength) {
  var res = String(value);
  if(res.length > maxLength){
    return res.substring(0,maxLength);
  } else {
    return res;
  }
}


export const handleError = (error, origen, setDialogo) => {
  console.error("Origen del error: ".concat(origen));
  setDialogo((val) => ({
    ...val,
    open: true,
    titulo: "Error en consulta",
    descripcion: descripcionError(error, MSG030),
  }));
};

export function descripcionError(error, mensajeDefault){
  var errorText = mensajeDefault;
  if (error.response) {
    /*
    * The request was made and the server responded with a
    * status code that falls out of the range of 2xx
    */
    errorText = error.response.data != null ?
                  error.response.data.errorMessage != null ?
                    error.response.data.errorMessage : validaStatus(error.response)
                      : validaStatus(error.response)
  } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    console.log(error.request);
  } else {
    // Something happened in setting up the request and triggered an Error
    console.log('Error', error.message);
  }
  return errorText;
}

function validaStatus(response){
  if(response.status === 400){
    return MSG030;
  } else {
    return response.statusText;
  }
}

export function validaResponse(res){
  if(res.status === 200 && res.data){
    return true;
  } else {
    return false;
  }
}

export function generoValueToService(genero){
  return (genero === 0 ? "Masculino" : "Femenino");
}

export function serviceToGeneroValue(genero){
  return (genero === "Masculino" ? 0 : 1);
}

export function ordenarAsc(p_array_json, p_key) {
  p_array_json.sort(function (a, b) {
    var textA = a[p_key];
    var textB = b[p_key];
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  return p_array_json;
}

export function medidaGridByLength (length) {
  const medida = 12 / length;
  return medida;
};

export function errorMessageObjetToString (objectError, name, message) {
  if(typeof objectError === 'object'){
    return message;
  } else {
    return objectError;
  }
};

export function checkIfExists (object) {
  return (object !== null && object !== undefined);
}

export function formatDate(incomingDate) {
  if(incomingDate === null || incomingDate === undefined || typeof incomingDate !== 'string') return ''

  const [dateFromIncomingDate] = incomingDate.split(' ');
  return dateFromIncomingDate;
}

export function useOnScreen(ref) {

  const [isIntersecting, setIntersecting] = useState(false)

  const observer = useMemo(() => new IntersectionObserver (
    ([entry]) => setIntersecting(entry.isIntersecting)
  ), [ref])


  useEffect(() => {
    observer.observe(ref.current)
    return () => observer.disconnect()
  }, [])

  return isIntersecting
}


export const UtmURls=(url="")=>{
  let utm_url = "";
  if (window.localStorage.getItem('utm_source') &&  window.localStorage.getItem('utm_content')!== 'undefined' ){
    utm_url =
      url  +
      '?utm_source=' +
      window.localStorage.getItem('utm_source') +
      '&utm_medium=' +
      window.localStorage.getItem('utm_medium') +
      '&utm_campaign=' +
      window.localStorage.getItem('utm_campaign') +
      '&utm_content=' +
      window.localStorage.getItem('utm_content') +
      '&gclid=' +
      window.localStorage.getItem('gclid')
  }else {
    utm_url= url
  }

  return utm_url
}
