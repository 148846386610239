const styles = {
  iconClose: {
    position: "absolute",
    right: 8,
    top: 0,
    color: (theme) => theme.palette.grey[500],
  },
  valeBtn: {
    width: { xs: "100%", sm: "90%" },
  },
  anotherWayBtn: {
    width: {
      xs: "100%",
      sm: "90%",
    },
    textTransform: "none",
    fontWeight: "400",
  },

  modalContacsSecond: {
    ".MuiBackdrop-root": {
      background: "none",
    },
  },

  arrowLeftIcon: { fontSize: "30px!important" },
  toReturnBtn: {
    fontSize: 14,
    textTransform: "none",
    fontWeight: "400",
  },
}

export default styles
