
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useField } from "formik";
import esLocale from "date-fns/locale/es";
import CustomErrorMessage from "./CustomErrorMessage";

export default function CustomDatePickerLab(props) {
  const [field, meta] = useField(props);
  const errorText = meta.error ? meta.error : "";
  const color = "#039ECC";
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
        <DatePicker
          openTo="year"
          views={['year', 'month', 'day']}
          inputFormat="dd/MM/yyyy"
          //format="dd/MM/yyyy"
          {...field}
          {...props}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                sx={{
                  svg: { color },
                }}
                className="w-100"
                helperText={errorText}
                error={!!errorText}
              />
            );
          }}
        />
      </LocalizationProvider>
      <CustomErrorMessage name={field.name} />
    </>
  );
}