import Button from "@mui/material/Button"
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import { useNavigate } from "react-router-dom"
const CancelarContratacionModal = (props) => {
  const { isVisible = false, handleClose } = props
  const { nombre } = JSON.parse(
    sessionStorage.getItem("interContratante")
  )
  const navigate = useNavigate()
  const goToInit = () => {
    sessionStorage.clear()
    navigate("/")
  }
  const capitalize = (s) => {
    if (typeof s !== "string") return ""
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  return (
    <Dialog open={isVisible}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} sx={{ textAlign: "center" }} mt={1}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            {capitalize(nombre) || ""} esperamos que regreses pronto
          </Typography>
          <Typography mt={2} mb={2}>
            Te compartiremos tu número de cotización por correo para que puedas
            terminar tu solicitud más tarde
          </Typography>

          <Button variant="contained" onClick={goToInit} sx={{ width: "100%" }}>
            de acuerdo
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default CancelarContratacionModal
