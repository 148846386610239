import React from "react";
import { Stack, Grid } from "@mui/material"
import Typography from '@mui/material/Typography';
import Icon1 from "../../assets/landing/Block2Icon1.png"
import Icon2 from "../../assets/landing/Block2Icon2.png"
import Icon3 from "../../assets/landing/Block2Icon3.png"
import { useMediaQuery, useTheme } from '@mui/material';


export default function SecondBlockLanding(props) {

    const { breakpoints } = useTheme();
    const isTablet = useMediaQuery(breakpoints.down('md'));
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const isUnderLarge = useMediaQuery(breakpoints.down('lg'));


    return (
        <Stack 
            direction="column" 
            sx={{ backgroundColor: "#F2FAFC", padding: '40px' }}
            // justifyContent='center'
            alignItems='center'
        >
            <Typography variant="h1" sx={{
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "28px",
                lineHeight: "28px",
                textAlign: "center",
                /* Black */
                color: "#212121",
            }}>¿Por qué contratar un seguro de Gasto Médico Mayor?
            </Typography>
            <Typography 
                sx={{
                    fontSstyle: "normal",
                    fontWeight: "400",
                    fontSize: "22px",
                    lineHeight: "22px",
                    textAlign: "center",
                    marginTop: '20px',
                    marginBottom: '20px'
                }}
            >
                Con el respaldo de un plan de salud estarás protegido en caso de:
            </Typography>
            <Grid container spacing={4} sx={{textAlign:"center", justifyContent:"center", marginTop:"5px", width: isTablet ? '100%' : isUnderLarge ? '90%' : '70%'}}>
                <Grid item xs ={12} sm={4}  md={4} >
                    <Stack alignItems="center">
                        <img height="100px" width="70px" src={Icon1} alt="firstIconSecondBlock" />
                        <Typography sx={{
                            fontSstyle: "normal",
                            fontWeight: "400",
                            fontSize: "18px",
                            lineHeight: "22px",
                            textAlign: "center",
                            width: (isTablet && !isMobile) ? '70%' : '50%',
                            marginTop: '40px'
                        }}>
                            Que sufras algún accidente
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs ={12} sm={4}  md={4}>
                    <Stack alignItems="center">
                        <img height="100px" width="70px" src={Icon2} alt="secondIconSecondBlock" />
                        <Typography
                            sx={{
                                fontSstyle: "normal",
                                fontWeight: "400",
                                fontSize: "18px",
                                lineHeight: "22px",
                                textAlign: "center",
                                width: (isTablet && !isMobile) ? '85%' : '70%',
                                marginTop: '40px'
                            }}
                        >
                            Que llegues a sufrir alguna enfermedad
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs ={12} sm={4}  md={4}>
                    <Stack alignItems="center">
                        <img height="100px" width="100px" src={Icon3} alt="thirdIconSecondBlock" />
                        <Typography sx={{
                            fontSstyle: "normal",
                            fontWeight: "400",
                            fontSize: "18px",
                            lineHeight: "22px",
                            textAlign: "center",
                            width: (isTablet && !isMobile) ? '87%' : '60%',
                            marginTop: '40px'
                        }}>
                            Que necesites una hospitalización
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Stack >
    )
}