import Button from "@mui/material/Button"
import { Dialog, DialogContent, DialogTitle } from "@mui/material"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import Stack from "@mui/material/Stack"
import { ReactComponent as ReactIconEmail } from "../../../assets/img/contratacion/email.svg"
import { ReactComponent as ReactIconPhone } from "../../../assets/img/contratacion/phone.svg"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import sx from "./styles"
const ContactosModal = (props) => {
  const {
    isVisible = false,
    handleClose,
    handleClossAll = null,
    isGoBack = false,
    sxProps = {},
  } = props
  return (
    <Dialog open={isVisible} sx={sxProps}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClossAll || handleClose}
          sx={sx.iconClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} className="modal-individual-padding">
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <span className="modal-titulo">¿No sabes qué onda?</span>
            <span className="modal-tel">Llámanos al</span>
            <Link href="tel:5544246837" target="_blank" rel="noreferrer">
              <Button>
                <span className="comunica">55 44 24 68 37</span>
              </Button>
            </Link>
            <span className="modal-tel">
              Mándanos un mensajito por WhatsApp
            </span>
            <Link
              href="https://wa.me/525543269037"
              target="_blank"
              rel="noreferrer"
            >
              <Button startIcon={<ReactIconPhone />}>
                <span className="comunica">55 43 26 90 37</span>
              </Button>
            </Link>
            <span className="modal-tel">o a nuestro mail</span>
            <Link href="mailto:hola@inter.mx" target="_blank" rel="noreferrer">
              <Button startIcon={<ReactIconEmail />}>
                <span
                  style={{ textTransform: "toLowercase" }}
                  className="comunica"
                >
                  hola@inter.mx
                </span>
              </Button>
            </Link>

            {isGoBack && (
              <Button
                onClick={handleClose}
                startIcon={<ArrowBackIcon sx={sx.arrowLeftIcon} />}
                sx={sx.toReturnBtn}
              >
                Contactar con un asesor ahoritita!
              </Button>
            )}
          </Stack>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ContactosModal
