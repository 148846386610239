import axios from "axios";
import { PATH } from "../Utils/Constantes";

// Create instance called instance
const instance = axios.create({
  baseURL: PATH,
});

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
	get: () =>
		instance({
			method: "GET",
			url: 'get',
		}),
	guardaEstilosVida: (estilosVida, idContratante, idAsegurado) =>
		instance({
		method: "PUT",
		url: 'EstiloVida/Save/'+idContratante+'/'+idAsegurado,
		data: estilosVida,
		}),
	guardaPrevenciones: (prevenciones, idContratante, idAsegurado) =>
		instance({
		method: "PUT",
		url: 'PrevencionControl/Save/'+idContratante+'/'+idAsegurado,
		data: prevenciones,
		}),
	guardaCongenito: (congenito, idContratante, idAsegurado) =>
		instance({
		method: "PUT",
		url: 'Padecimiento/Savecongenito/'+idContratante+'/'+idAsegurado,
		data: congenito,
		}),
	updateCongenito: (congenito, idContratante, idAsegurado) =>
		instance({
		method: "PUT",
		url: 'Padecimiento/Updatecongenito/'+idContratante+'/'+idAsegurado+'/'+congenito.id,
		data: congenito,
		}),
	deleteCongenito: (idContratante, idAsegurado, idPadecimiento) =>
		instance({
		method: "DELETE",
		url: 'Padecimiento/Delete/'+idContratante+'/'+idAsegurado+'/'+idPadecimiento,
		}),
	guardaPadecimiento: (padecimiento, idContratante, idAsegurado) =>
		instance({
		method: "PUT",
		url: 'Padecimiento/SaveHospitalizado/'+idContratante+'/'+idAsegurado,
		data: padecimiento,
		}),
	updatePadecimiento: (padecimiento, idContratante, idAsegurado) =>
		instance({
		method: "PUT",
		url: 'Padecimiento/UpdateHospitalizado/'+idContratante+'/'+idAsegurado+'/'+padecimiento.id,
		data: padecimiento,
		}),
	deletePadecimiento: (idContratante, idAsegurado, idPadecimiento) =>
		instance({
		method: "DELETE",
		url: 'Padecimiento/UpdateHospitalizado/'+idContratante+'/'+idAsegurado+'/'+idPadecimiento,
		}),
}
