import * as yup from "yup";
import { CHECK_NECESARIO, CMB_NECESARIO, FECHA_VALIDA, VALOR_NECESARIO } from "../Utils/Constantes";


export const validationForm = yup.object().shape({
  nombre: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(20,'Debe tener como máximo 20 caracteres'),
  fechanacimiento: yup
    .date()
    .required(VALOR_NECESARIO)
    .typeError(FECHA_VALIDA)
    .test(
      "fechaValida",
      FECHA_VALIDA,
      (fecha) => {
        if(fecha instanceof Date && !isNaN(fecha)){
          return true;
        } else {
          return false;
        }
      }
    ),
  genero: yup
    .number()
    .nullable()
    .required(CMB_NECESARIO)
    .min(0, CMB_NECESARIO),
  parentesco: yup
    .string()
    .nullable()
    .required(CMB_NECESARIO)
    .test(
      "parentesco",
      CMB_NECESARIO,
      (valor) => {
        if(Number(valor) !== -1){
          return true;
        } else {
          return false;
        }
      }
    ),
});

export const validationEmbarazoForm = yup.object().shape({
  nombre: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(20,'Debe tener como máximo 20 caracteres'),
  fechanacimiento: yup
    .date()
    .required(VALOR_NECESARIO)
    .typeError(FECHA_VALIDA)
    .test(
      "fechaValida",
      FECHA_VALIDA,
      (fecha) => {
        if(fecha instanceof Date && !isNaN(fecha)){
          return true;
        } else {
          return false;
        }
      }
    ),
  genero: yup
    .number()
    .nullable()
    .required(CMB_NECESARIO)
    .min(0, CMB_NECESARIO),
  embarazo: yup
    .boolean()
    .nullable()
    .required(CMB_NECESARIO),
  parentesco: yup
    .string()
    .nullable()
    .required(CMB_NECESARIO)
    .test(
      "parentesco",
      CMB_NECESARIO,
      (valor) => {
        if(Number(valor) !== -1){
          return true;
        } else {
          return false;
        }
      }
    ),
});

export const validationTratamientoForm = yup.object().shape({
  tipoEvento: yup
    .string()
    .required(CHECK_NECESARIO)
    .min(1, CHECK_NECESARIO)
    .max(1, CHECK_NECESARIO),
  descripcion: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(2000,'Debe tener como máximo 2000 caracteres'),
  mes: yup
    .number()
    .required(CMB_NECESARIO)
    .min(0, CMB_NECESARIO),
  anio: yup
    .number()
    .required(CMB_NECESARIO)
    .min(0, CMB_NECESARIO),
  estadoActual: yup
    .string()
    .required(CHECK_NECESARIO)
    .min(1, CHECK_NECESARIO)
    .max(1, CHECK_NECESARIO),
});

export const validationRecuperadoForm = yup.object().shape({
  tipoEvento: yup
    .string()
    .required(CHECK_NECESARIO)
    .min(1, CHECK_NECESARIO)
    .max(1, CHECK_NECESARIO),
  descripcion: yup
    .string()
    .required(VALOR_NECESARIO)
    .min(1, VALOR_NECESARIO)
    .max(2000,'Debe tener como máximo 2000 caracteres'),
  mes: yup
    .number()
    .required(CMB_NECESARIO)
    .min(0, CMB_NECESARIO),
  anio: yup
    .number()
    .required(CMB_NECESARIO)
    .min(0, CMB_NECESARIO),
  estadoActual: yup
    .string()
    .required(CHECK_NECESARIO)
    .min(1, CHECK_NECESARIO)
    .max(1, CHECK_NECESARIO),
  mesUltimaVez: yup
    .number()
    .required(CMB_NECESARIO)
    .min(0, CMB_NECESARIO),
  anioUltimaVez: yup
    .number()
    .required(CMB_NECESARIO)
    .min(0, CMB_NECESARIO),
});