import { Navigate, useRoutes } from 'react-router-dom';
import Inicio from "../Inicio/Inicio";
import Wrapper from "../Commons/Wrapper";
import Mayor from "../Advertencias/Mayor";
import { ALGUIEN_MAS, BIENVENIDA, DATOS, SOLO_AM, SOLO_AM_AGREGAR } from "../Utils/Constantes";
import Gracias from '../DetallesCotizacion/Gracias';
import YaTerminamos from "../Contratacion/YaTerminamos";
import Mantenimiento from "../Mantenimiento/Mantenimiento"
import PasoFirmaMobile from "../Contratacion/PasoFirmaMobile";
import CRM from '../CRM/crm';
import CotizacionPlanes from '../../pages/cotizacion/planes';
import CotizacionDetalles from '../../pages/cotizacion/detalles';
import CotizacionComparacion from '../../pages/cotizacion/comparacion';
import ContratacionPage from '../../pages/contratacion';
import CuestionarioPage from '../../pages/cuestionario';

export default function Pages(props) {
  let routes = useRoutes([
    { path: "/*", element: <Navigate to="/inicio" /> },
    { path: "/inicio", element: <Inicio isMobile={props.isMobile} mantenimiento={false} /> },
    { path: "/bienvenida", element: <Wrapper isMobile={props.isMobile} componente={BIENVENIDA} /> },
    { path: "/datos-personales", element: <Wrapper isMobile={props.isMobile} componente={DATOS} /> },
    { path: "/datos/alguien-mas", element: <Wrapper isMobile={props.isMobile} componente={ALGUIEN_MAS} /> },
    { path: "/datos/alguien-mas/:continua", element: <Wrapper isMobile={props.isMobile} componente={ALGUIEN_MAS} /> },
    { path: "/datos/solo-alguien-mas", element: <Wrapper isMobile={props.isMobile} componente={SOLO_AM} /> },
    { path: "/datos/alguien-mas/agregar-mas", element: <Wrapper isMobile={props.isMobile} componente={SOLO_AM_AGREGAR} /> },
    { path: "/datos/alguien-mas/agregar-mas/:continua", element: <Wrapper isMobile={props.isMobile} componente={SOLO_AM_AGREGAR} /> },
    { path: "/mayor", element: <Mayor isMobile={props.isMobile} /> },
    { path: "/cotizacion", element: <CotizacionPlanes isMobile={props.isMobile} /> },
    { path: "/detalles-cotizacion", element: <CotizacionDetalles isMobile={props.isMobile} /> },
    { path: "/comparacion-planes", element: <CotizacionComparacion /> },
    { path: "/contratacion", element: <ContratacionPage isMobile={props.isMobile} /> },
    { path: "/cuestionario", element: <CuestionarioPage isMobile={props.isMobile} /> },
    { path: "/terminamos-contratacion", element: <YaTerminamos /> },
    { path: "/agradecimiento-cotizacion", element: <Gracias /> },
    { path: "/crm/:folio", element: <CRM isMobile={props.isMobile} /> },
    { path: "/mantenimiento", element: <Mantenimiento /> },
    { path: "/firma-mobile/:folio", element: <PasoFirmaMobile isMobile={props.isMobile} /> },
    // ...
  ]);
  return routes;
};