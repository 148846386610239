import * as react from "react"
import { Navigate } from "react-router-dom"
import ComparacionPlanes from "../../../components/PlanesCotizacion/ComparacionPlanes"

const CotizacionComparacion = (props) => {
    const existDataSession =  sessionStorage.getItem("interContratante")? true : false
    return (
        existDataSession? <ComparacionPlanes isMobile={props.isMobile} /> : <Navigate to="/inicio?view=haveCotizacion" />
    )
}

export default CotizacionComparacion