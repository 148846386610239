import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Box, Dialog, Typography, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import QrCode from "qrcode"
import Api from "../../Api/Api"
import { INTER_CONTRATANTE } from "../../Utils/Constantes"
import sx from "./styles"

const QrModal = (props) => {
  const { isVisible = false, handleShow, isVisibleX = true } = props
  const [src, setSrc] = useState("")
  const { folio } = JSON.parse(window.sessionStorage.getItem(INTER_CONTRATANTE))
  const signatureMobileUrl = `${window.location.origin}/firma-mobile/${folio}`
  const navigate = useNavigate()
  useEffect(() => {
    QrCode.toDataURL(signatureMobileUrl).then((string) => setSrc(string))
    const intervalId = setInterval(() => {
      getContratante()
    }, 5000)
    return () => clearInterval(intervalId)
  }, [])

  const getContratante = async () => {
    const { data } = await Api.getContratante(folio)
    if (data?.contratante?.firma) navigate("/terminamos-contratacion")
  }
  return (
    <Dialog open={isVisible}>
      {isVisibleX && (
        <IconButton onClick={handleShow} sx={sx.closeIcon}>
          <CloseIcon />
        </IconButton>
      )}
      <Box p={4}>
        <Typography variant="h5" sx={sx.title}>
          Escanea el código QR y firma desde tu celular
        </Typography>
        <Typography variant="subtitle1" mt={2}>
          Se abrirá en el navegador de tu teléfono una página para que puedas
          firmar y enviar tu solicitud.
        </Typography>
        <Box sx={sx.contentQr}>{src && <img src={src} alt={src} />}</Box>
        <Button variant="contained" sx={sx.btn} onClick={handleShow}>
          ¡Entendido!
        </Button>
      </Box>
    </Dialog>
  )
}

export default QrModal
