import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Stack,
  Switch,
  Typography,
} from "@mui/material"
import * as React from "react"
import CustomTextField from "../CustomFields/CustomTextField"
import { Form, Formik } from "formik"
import {
  validationFormPasoDos,
  validationFormPasoDosEstudiante,
} from "./ValidationForm"
import CustomDatePickerLab from "../CustomFields/CustomDatePickerLab"
import CustomSelect from "../CustomFields/CustomSelect"
import {
  CMB_MENOS_UNO,
  CP_INVALIDO,
  FECHA_VALIDA,
  INTER_CONTRATANTE,
  MAX_LENGTH_CP,
  ocupaciones,
  paises,
} from "../Utils/Constantes"
import { handleMaxLength } from "../Utils/FieldValidationFunctions"
import { maxLength, validaResponse } from "../Utils/Utils"
import Api from "../../components/Api/Api"
import { styled } from "@mui/material/styles"
import CustomTextArea from "../CustomFields/CustomTextArea"
import { useEffect } from "react"
import Loading from "../Commons/Loader"

export default function PasoDos(props) {
  const dataContratante = JSON.parse(sessionStorage.getItem("interContratante"))
  const [contratante, setContratante] = React.useState(dataContratante)
  const dataTitular = dataContratante.asegurados.find(
    (elemento) => elemento.nombre && elemento.titular === true
  )
  const [checked, setChecked] = React.useState(false)
  const [checked2, setChecked2] = React.useState(false)
  const [colonias, setColonias] = React.useState([])
  const [isLoading, setLoading] = React.useState(true)
  const [isEstudiante, setEstudiante] = React.useState(
    dataTitular.ocupacion === "ESTUDIANTE"
  )
  const [openLoader, setOpenLoader] = React.useState(false)

  const handleChangeFechaNacimiento = (fecha, setFieldValue, setFieldError) => {
    if (fecha instanceof Date && !isNaN(fecha)) {
      setFieldValue("fechanacimiento", fecha)
    } else {
      setFieldError("fechanacimiento", FECHA_VALIDA)
    }
  }
  const handleChanges = (event) => {
    setChecked(event.target.checked)
  }
  const handleChanges2 = (event) => {
    setChecked2(event.target.checked)
  }

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }))

  const handleOnChangePais = (event, setFieldValue) => {
    const {
      target: { value, name },
    } = event
    setFieldValue(name, value)
  }

  const handleOnChangeOcupacion = (event, setFieldValue) => {
    const {
      target: { value, name },
    } = event
    setFieldValue(name, value)
    if (value === "ESTUDIANTE") {
      setEstudiante(true)
    } else {
      setEstudiante(false)
    }
  }

  const handleContinuar = (values, setSubmitting) => {
      window.dataLayer.push({
        event : "medico_seguro_datos_asegurado",
        no_cotizacion: dataContratante.folio,   
        telefono : values.cel,
        email : values.email,     
        nacionalidad: values.nacionalidad,
        pais: values.pais,
        cp: values.cp,  
        nombre_trabajo: values.ocupacion,
        actividad_peligrosa: values.decripMaquinariaPesada ? 'Si' : 'No',
        boton_contratante: 'Continuar',
      }) 
    const currentTitular = contratante.asegurados.find(
      (asegurado) => asegurado.titular === true
    )
    currentTitular.nombre = values.nombre
    currentTitular.apaterno = values.apellidop
    currentTitular.amaterno = values.apellidom
    currentTitular.fechanacimiento = values.date
    currentTitular.genero = values.genero
    currentTitular.pais = values.pais
    currentTitular.nacionalidad = values.nacionalidad
    currentTitular.rfc = values.rfc
    currentTitular.curp = values.curp
    currentTitular.direccion = values.calle
    currentTitular.colonia = values.colonia
    currentTitular.municipio = values.municipio
    currentTitular.estado = values.estado
    currentTitular.noInt = values.numin
    currentTitular.noExt = values.numex
    currentTitular.ocupacion = values.ocupacion
    currentTitular.telefono = values.cel
    currentTitular.correo = values.email
    currentTitular.puesto = values.puesto
    currentTitular.giroEmpresa = values.giro
    currentTitular.decripMaquinariaPesada = values.decripMaquinariaPesada
    window.dataLayer.push({
      event: "form_step_10_submit",
      cp: currentTitular.cp,
      rfc: values.rfc,
      curp: values.curp,
      nombre_completo:
        values.nombre + " " + values.apellidop + " " + values.apellidom,
    })
    contratante.ultimoPaso = 10

    Api.actualizaContratante(contratante.id, contratante)
      .then((res) => {
        Api.actualizaAsegurado(contratante.id, currentTitular)
          .then((res) => {
            Api.getContratante(contratante.folio)
              .then((res) => {
                setOpenLoader(false)
                window.sessionStorage.setItem(
                  INTER_CONTRATANTE,
                  JSON.stringify(res.data.contratante)
                )
                setSubmitting(false)
                props.handleContinuar()
              })
              .catch((error) => {
                setOpenLoader(false)
                setSubmitting(false)
                console.log(error)
              })
          })
          .catch((error) => {
            setOpenLoader(false)
            setSubmitting(false)
            console.log(error)
          })
      })
      .catch((e) => {
        setOpenLoader(false)
        setSubmitting(false)
        console.log(e)
      })
  }

  const handleChangeCP = (event, setFieldValue, setFieldError) => {
    const cp = maxLength(event.target.value, MAX_LENGTH_CP)
    setFieldValue("cp", cp)
    if (cp.length === MAX_LENGTH_CP) {
      Api.validaCP(cp)
        .then((res) => {
          if (validaResponse(res) && res.data.existe) {
            Api.consultaCP(cp)
              .then((res) => {
                setColonias(res.data)
              })
              .catch((error) => {
                console.log(error)
              })
          } else {
            setFieldError("cp", CP_INVALIDO)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
    }
  }

  function handleChangeCPSubmit(values, setFieldError, setSubmitting) {
    const cp = maxLength(values.cp, MAX_LENGTH_CP)
    if (cp.length === MAX_LENGTH_CP) {
      Api.validaCP(cp)
        .then((res) => {
          if (validaResponse(res) && res.data.existe) {
            handleContinuar(values, setSubmitting)
          } else {
            setFieldError("cp", CP_INVALIDO)
            setSubmitting(false)
            setOpenLoader(false)
          }
        })
        .catch((error) => {
          setFieldError("cp", CP_INVALIDO)
          setSubmitting(false)
          setOpenLoader(false)
          console.log(error)
        })
    } else {
      setFieldError("cp", CP_INVALIDO)
      setSubmitting(false)
      setOpenLoader(false)
    }
  }

  useEffect(() => {
    window.dataLayer.push({
      event: "form_step_10_view",
    })
    if (window.sessionStorage.getItem(INTER_CONTRATANTE)) {
      const contratanteSess = JSON.parse(
        window.sessionStorage.getItem(INTER_CONTRATANTE)
      )
      setContratante(contratanteSess)
      if (contratanteSess.cp !== null) {
        Api.consultaCP(contratanteSess.cp)
          .then((res) => {
            setColonias(res.data)
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)
            console.log(error)
          })
      } else {
        setLoading(false)
      }
    }
    /* Especifica cómo sanear este efecto:
    return function cleanup() {
    };*/
  }, [])

  return (
    <>
      {!isLoading && (
        <Formik
          innerRef={props.formRef}
          validateOnChange={true}
          initialValues={{
            nombre: dataTitular.nombre,
            apellidop:
              dataTitular.apaterno !== null ? dataTitular.apaterno : "",
            apellidom:
              dataTitular.amaterno !== null ? dataTitular.amaterno : "",
            fechanacimiento:
              dataTitular.fechanacimiento !== null
                ? dataTitular.fechanacimiento
                : new Date(),
            genero: dataTitular.genero !== null ? dataTitular.genero : -1,
            pais: dataTitular.pais !== null ? dataTitular.pais : -1,
            nacionalidad:
              dataTitular.nacionalidad !== null ? dataTitular.nacionalidad : "",
            rfc: dataTitular.rfc !== null ? dataTitular.rfc : "",
            curp: dataTitular.curp !== null ? dataTitular.curp : "",
            cel: dataTitular.telefono !== null ? dataTitular.telefono : "",
            email: dataTitular.correo !== null ? dataTitular.correo : "",
            cp: dataTitular.cp,
            ocupacion:
              dataTitular.ocupacion !== null ? dataTitular.ocupacion : -1,
            colonia: dataTitular.colonia !== null ? dataTitular.colonia : -1,
            municipio:
              dataTitular.municipio !== null ? dataTitular.municipio : -1,
            estado: dataTitular.estado !== null ? dataTitular.estado : -1,
            calle: dataTitular.direccion !== null ? dataTitular.direccion : "",
            numin: dataTitular.noInt !== null ? dataTitular.noInt : "",
            numex: dataTitular.noExt !== null ? dataTitular.noExt : "",
            puesto: dataTitular.puesto !== null ? dataTitular.puesto : "",
            giro:
              dataTitular.giroEmpresa !== null ? dataTitular.giroEmpresa : "",
            decripMaquinariaPesada:
              dataTitular.decripMaquinariaPesada !== null
                ? dataTitular.decripMaquinariaPesada
                : "",
          }}
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            setSubmitting(true)
            setOpenLoader(true)
            handleChangeCPSubmit(values, setFieldError, setSubmitting)
          }}
          validationSchema={
            isEstudiante
              ? validationFormPasoDosEstudiante
              : validationFormPasoDos
          }
        >
          {({
            values,
            submitForm,
            setFieldValue,
            errors,
            isSubmitting,
            setFieldError,
          }) => (
            <Form>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <span className="text-preguntas">
                      Ahora, &iquest;Nos ayudas a completar la información del
                      titular?
                    </span>
                  </Grid>
                  <Box sx={{ mt: 1 }}>
                    <Stack 
                          direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                           spacing={2}>
                      <CustomTextField
                        id="nombre"
                        name="nombre"
                        placeholder="Nombre"
                      />
                      <CustomTextField
                        id="apellidop"
                        name="apellidop"
                        placeholder="Primer apellido"
                      />
                    </Stack>
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Stack 
                          direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                           spacing={2}>
                      <CustomTextField
                        id="apellidom"
                        name="apellidom"
                        placeholder="Segundo apellido"
                      />
                      <FormControl fullWidth>
                        <CustomDatePickerLab
                          id="fechanacimiento"
                          name="fechanacimiento"
                          type="date"
                          disabled
                          placeholder="Fecha Nacimiento"
                          onChange={(newValue) => {
                            handleChangeFechaNacimiento(
                              newValue,
                              setFieldValue,
                              setFieldError
                            )
                          }}
                        />
                      </FormControl>
                    </Stack>
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Stack 
                          direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                           spacing={2}>
                      <FormControl fullWidth>
                        <CustomSelect
                          id="genero"
                          name="genero"
                          disabled
                          onChange={(event) =>
                            handleOnChangePais(event, setFieldValue)
                          }
                        >
                          <MenuItem value={CMB_MENOS_UNO}>
                            Sexo de nacimiento
                          </MenuItem>
                          <MenuItem value={"Masculino"}>Hombre</MenuItem>
                          <MenuItem value={"Femenino"}>Mujer</MenuItem>
                        </CustomSelect>
                      </FormControl>
                      <FormControl fullWidth>
                        <CustomSelect
                          id="pais"
                          name="pais"
                          onChange={(event) =>
                            handleOnChangePais(event, setFieldValue)
                          }
                        >
                          <MenuItem value={CMB_MENOS_UNO}>
                            Pa&iacute;s de nacimiento
                          </MenuItem>
                          {paises.map((pais, i) => (
                            <MenuItem key={i} value={pais}>
                              {pais}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      </FormControl>
                    </Stack>
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Stack 
                          direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                           spacing={2}>
                      <CustomTextField
                        id="nacionalidad"
                        name="nacionalidad"
                        placeholder="Nacionalidad"
                      />
                      <CustomTextField id="rfc" name="rfc" placeholder="RFC" />
                    </Stack>
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Stack 
                          direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                           spacing={2}>
                      <CustomTextField
                        id="curp"
                        name="curp"
                        placeholder="CURP"
                      />
                      <CustomTextField
                        id="cel"
                        name="cel"
                        placeholder="Celular"
                        onChange={(event) =>
                          handleMaxLength(event, setFieldValue, "cel", 10)
                        }
                      />
                    </Stack>
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <Stack
                        
                          direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                          
                        spacing={2}
                      >
                        <CustomTextField
                          id="email"
                          name="email"
                          placeholder="Correo electrónico"
                        />
                      </Stack>
                    </Grid>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <span className="text-preguntas">
                        &iquest;D&oacute;nde vive?
                      </span>
                    </Grid>
                    <Box sx={{ mt: 2 }}>
                      <Stack
                        
                          direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                          
                        spacing={2}
                      >
                        <CustomTextField
                          id="cp"
                          name="cp"
                          type="text"
                          placeholder="C.P."
                          className="grid-item-input"
                          onChange={(event) =>
                            handleChangeCP(event, setFieldValue, setFieldError)
                          }
                        />
                        <FormControl fullWidth>
                          <CustomSelect
                            id="colonia"
                            name="colonia"
                            onChange={(event) =>
                              handleOnChangePais(event, setFieldValue)
                            }
                          >
                            <MenuItem value={CMB_MENOS_UNO}>Colonia</MenuItem>
                            {colonias.map((colonia, i) => (
                              <MenuItem key={i} value={colonia.d_asenta}>
                                {colonia.d_asenta}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                        </FormControl>
                      </Stack>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Stack
                          
                          direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                          
                          spacing={1}
                        >
                          <FormControl fullWidth>
                            <CustomSelect
                              id="municipio"
                              name="municipio"
                              onChange={(event) =>
                                handleOnChangePais(event, setFieldValue)
                              }
                            >
                              <MenuItem value={CMB_MENOS_UNO}>
                                Municipio
                              </MenuItem>
                              {colonias.length > 0 && (
                                <MenuItem key={0} value={colonias[0].d_mnpio}>
                                  {colonias[0].d_mnpio}
                                </MenuItem>
                              )}
                            </CustomSelect>
                          </FormControl>
                          <FormControl fullWidth>
                            <CustomSelect
                              id="estado"
                              name="estado"
                              onChange={(event) =>
                                handleOnChangePais(event, setFieldValue)
                              }
                            >
                              <MenuItem value={CMB_MENOS_UNO}>Estado</MenuItem>
                              {colonias.length > 0 && (
                                <MenuItem key={0} value={colonias[0].d_estado}>
                                  {colonias[0].d_estado}
                                </MenuItem>
                              )}
                            </CustomSelect>
                          </FormControl>
                        </Stack>
                      </Grid>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                      <Stack
                        
                          direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                          
                        spacing={2}
                      >
                        <CustomTextField
                          id="calle"
                          name="calle"
                          placeholder="Calle"
                        />
                      </Stack>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                      <Stack
                        
                          direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                          
                        spacing={2}
                      >
                        <CustomTextField
                          id="numin"
                          name="numin"
                          placeholder="Número Interior"
                        />
                        <CustomTextField
                          id="numex"
                          name="numex"
                          placeholder="Número Exterior"
                        />
                      </Stack>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      {/*Seccion que se agrega en titular y contratatnte misma persona*/}
                      {dataContratante.tipoFlujo === 3 && (
                        <Box sx={{ mt: 4 }}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <span className="text-preguntas">
                              &iquest;A qu&eacute; te dedicas?
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack
                              
                          direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                          
                              spacing={1}
                            >
                              <FormControl fullWidth>
                                <CustomSelect
                                  id="ocupacion"
                                  name="ocupacion"
                                  onChange={(event) =>
                                    handleOnChangeOcupacion(
                                      event,
                                      setFieldValue
                                    )
                                  }
                                >
                                  <MenuItem value={CMB_MENOS_UNO}>
                                    Ocupación
                                  </MenuItem>
                                  {ocupaciones.map((ocupacion, i) => (
                                    <MenuItem key={i} value={ocupacion.valor}>
                                      {ocupacion.valor}
                                    </MenuItem>
                                  ))}
                                </CustomSelect>
                              </FormControl>
                            </Stack>
                          </Grid>
                          {values.ocupacion !== "ESTUDIANTE" && (
                            <>
                              <Box sx={{ mt: 2 }}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <Stack
                                    
                          direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                          
                                    spacing={1}
                                  >
                                    <CustomTextField
                                      id="puesto"
                                      name="puesto"
                                      type="text"
                                      placeholder="Puesto que desempeña"
                                    />
                                    <CustomTextField
                                      id="giro"
                                      name="giro"
                                      type="text"
                                      placeholder="Giro de la empresa"
                                    />
                                  </Stack>
                                </Grid>
                              </Box>
                              <Box sx={{ mt: 2 }}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={10}
                                  lg={10}
                                  xl={10}
                                >
                                  <span className="text-preguntas">
                                    En el trabajo que realizas &iquest;Usas
                                    maquinaria pesada, herramientas de riesgo o
                                    sustancias peligrosas?
                                  </span>
                                </Grid>
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <Typography>NO</Typography>
                                  <AntSwitch
                                    checked={checked2}
                                    onChange={handleChanges2}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  <Typography>SI</Typography>
                                </Stack>
                                {checked2 === true && (
                                  <Box sx={{ mt: 2 }}>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                    >
                                      <Stack
                                        
                          direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                          
                                        spacing={1}
                                      >
                                        <CustomTextArea
                                          minRows={6}
                                          id="decripMaquinariaPesada"
                                          name="decripMaquinariaPesada"
                                          type="text"
                                          placeholder="Danos un poco m&aacute;s de informaci&oacute;n"
                                        />
                                      </Stack>
                                    </Grid>
                                  </Box>
                                )}
                              </Box>
                            </>
                          )}
                        </Box>
                      )}
                      {dataContratante.tipoFlujo !== 3 && (
                        <Box sx={{ mt: 4 }}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <span className="text-preguntas">
                              &iquest;El contratante ha chambeado como servidor
                              p&uacute;blico?
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Typography>NO</Typography>
                              <AntSwitch
                                checked={checked}
                                onChange={handleChanges}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                              <Typography>SI</Typography>
                            </Stack>
                          </Grid>

                          {checked === true && (
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Box sx={{ mt: 4 }}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <span className="text-preguntas">
                                    &iquest;Que puesto desempe&ntilde;as?
                                  </span>
                                </Grid>
                                <Box sx={{ mt: 2 }}>
                                  <Stack
                                    
                          direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                          
                                    spacing={1}
                                  >
                                    <CustomTextField
                                      id="puestoserv"
                                      name="puestoserv"
                                      type="text"
                                      placeholder="Puesto "
                                    />
                                  </Stack>
                                </Box>
                              </Box>
                              <Box sx={{ mt: 2 }}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <span className="text-preguntas">
                                    &iquest;En que periodo de tiempo?
                                  </span>
                                </Grid>
                                <Box sx={{ mt: 2 }}>
                                  <Stack
                                    
                          direction={{ xs: "column", sm: "column", md: "column", lg: "row", xl: "row" }}
                          
                                    spacing={1}
                                  >
                                    <CustomTextField
                                      id="desde"
                                      name="desde"
                                      type="text"
                                      placeholder="Desde"
                                    />
                                    <CustomTextField
                                      id="hasta"
                                      name="hasta"
                                      type="text"
                                      placeholder="Hasta"
                                    />
                                  </Stack>
                                </Box>
                              </Box>
                            </Grid>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
      <Loading open={openLoader} onClose={() => setOpenLoader(false)} />
    </>
  )
}
