import * as React from 'react';

//Components imports
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns'
import { INTER_CONTRATANTE } from '../Utils/Constantes';
import { validaResponse } from '../Utils/Utils';
import './DetallesCotizacion.css'
import Api from '../Api/Api';
import Loading from '../Commons/Loader';
import useWindowSize from '../Utils/useWindowSize';

//Material
import { Button, Grid, Divider, Box, Stack, IconButton } from '@mui/material';
import { InputLabel, FormControl, Select, MenuItem, Tooltip } from '@mui/material';
import { List, ListItem, ListItemButton, Checkbox, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createTheme } from '@mui/material/styles';

//Iconos
import TooltipIcon from '../../assets/img/planesCotizacion/TooltipIsolated.svg';
import IconSumaAsegurada from '../../assets/img/detallesCotizacion/icono-suma-asegurada.svg';
import IconCoaseguro from '../../assets/img/detallesCotizacion/icono-coaseguro.svg';
import IconTopeCoaseguro from '../../assets/img/detallesCotizacion/icono-topecoaseguro.svg';
import IconNivelH from '../../assets/img/detallesCotizacion/icono-nivelhospitalario.svg';
import IconMarternidad from '../../assets/img/detallesCotizacion/icono-maternidad.svg';
import IconEmergenciaExtranjero from '../../assets/img/detallesCotizacion/icono-emergencia-extranjero.svg';
import IconoDeducible from '../../assets/img/detallesCotizacion/icono-deducible.svg';
import IconoAccidenteSDeduc from '../../assets/img/detallesCotizacion/icon-accidente-sin-deducible.svg';
import IconoCheckupBasico from '../../assets/img/detallesCotizacion/icon-checkup-basico.svg';
import IconPlanDental from '../../assets/img/detallesCotizacion/icon-plan-dental.svg';
import IconMedicoCerca from '../../assets/img/detallesCotizacion/icon-medico-cerca-de-ti.svg';
import IconCatastrofe from '../../assets/img/detallesCotizacion/icono-catastrofica.svg'
import IconFlechaIzq from '../../assets/img/detallesCotizacion/flechaIzq.svg';
import IconBack from '../../assets/img/planesCotizacion/Back.svg'

//Logos Aseguradoras
import LogoGNP from '../../assets/img/detallesCotizacion/logo-gnp.svg';
import LogoAxa from '../../assets/img/planesCotizacion/logo-axa.svg';
import LogoGDS from '../../assets/img/planesCotizacion/logo-general-de-salud.svg';
import LogoMapfre from '../../assets/img/planesCotizacion/logo-mapfre.svg';
import { useScoreLead } from "../../hooks/useScoreLead";

export default function DetallesCotizacion(props) {
    const navigate = useNavigate();
    const [openLoader, setOpenLoader] = React.useState(false);

    const dataObj = JSON.parse(sessionStorage.getItem("interContratante"))
    const dataTitular = dataObj.asegurados.find(asegurado => asegurado.titular === true);

    const dataContratante = dataObj.tipoFlujo === 3 ? dataTitular : dataObj;
    const dataContratantef3 = dataObj.tipoFlujo === 3 ? dataObj : dataObj;
    const dataCotizacion = JSON.parse(sessionStorage.getItem("detalleCotizacion"))
    var cadenaFecha = ""
    cadenaFecha = dataContratante.fechanacimiento != null ? format(Date.parse(dataContratante.fechanacimiento), 'dd/MM/yyyy') : ""

    const [deducible, setDeducible] = React.useState('');
    const [coaseguro, setCoaseguro] = React.useState('');
    const [topeCoaseguro, setTopeCoaseguro] = React.useState('');
    const [planes, setPlanes] = React.useState(dataCotizacion.plan);
    const [mostrarPersonalizar, setMostrarPersonalizar] = React.useState(false)
    const [coberturasExtrasSeleccionadas, setCoberturasExtrasSeleccionadas] = React.useState([]);
    const [detalles, setDetalles] = React.useState([]);
    const [coberturasExtras, setCoberturasExtras] = React.useState([])
    const [plus, setPlus] = React.useState([]);
    const [costoAnual, setCostoAnual] = React.useState("0")
    const [personaData, setPersonaData] = React.useState({})
    const [mostrarAvisoColaborador, setMostrarAvisoColaborador] = React.useState(false)
    const [cupon, setCupon] = React.useState(null);
    const [noColaborador, setNoColaborador] = React.useState(null)
    const [isMobiles, setIsMobiles] = React.useState((window.innerWidth < 1025) ? true : false);
    const size = useWindowSize();
    const {device, tipoSeguro, scoreLead} = useScoreLead()
    const handleChangeSelectPlanes = (event) => {
        setOpenLoader(true);
        setPlanes(event.target.value);
        dataCotizacion.plan = event.target.value
        sessionStorage.setItem("detalleCotizacion", JSON.stringify(dataCotizacion))

        const cotizacion = JSON.parse(sessionStorage.getItem("detalleCotizacion"));

        Api.getDetallePaquete(cotizacion.aseguradora, event.target.value)
            .then(res => {
                if (validaResponse(res)) {
                    setDetalles(res.data.detalles);
                    setPlus(res.data.plusadicional);
                    setCoberturasExtras(res.data.extra)
                    Api.getSumaAsegurada(cotizacion.idAseguradora, res.data.id, dataContratantef3.id)
                        .then(res2 => {
                            setPersonaData(res2.data.primas[0])
                            setCostoAnual(res2.data.total)
                        })
                        .catch(error => { console.error("Error on API getSumaAsegurada:", error.message) })
                } else {
                    console.log("ERROR", res);
                }
                setOpenLoader(false);
            })
            .catch(error => {
                console.log(error);
                setOpenLoader(false);
            });

    };
    const handleChangeSelectCoaseguro = (event) => {
        setCoaseguro(event.target.value);
    };
    const handleChangeSelectTopeCoaseguro = (event) => {
        setTopeCoaseguro(event.target.value);
    };
    const handleChangeSelectDeducible = (event) => {
        setDeducible(event.target.value);
    };
    const changeMostrarPersonalizar = () => {
        window.dataLayer.push({
            'event': 'form_step_1_viewpers'
        });
        window.dataLayer.push({
            event : "medico_seguro_precontratar",
            no_cotizacion: dataObj.folio,
            aseguradora:dataCotizacion.aseguradora,
            codigo_promocion: cupon,
            num_colaborador: noColaborador,
            plan: dataCotizacion.plan,
            modalidad_pago: 'Anual',
            boton_precontratar:'Personalizar'
        });
        setMostrarPersonalizar(!mostrarPersonalizar)
    }

    const handleToggleCoberturasExtrasSeleccionadas = (value) => () => {
        const Index = coberturasExtrasSeleccionadas.indexOf(value);
        const nuevaSeleccion = [...coberturasExtrasSeleccionadas];

        if (Index === -1) {
            value.id = '';
            nuevaSeleccion.push(value);
        } else {
            nuevaSeleccion.splice(Index, 1);
        }

        setCoberturasExtrasSeleccionadas(nuevaSeleccion);
    };
    const handleChangeNoColaborador = (event) => {
        setNoColaborador(event.target.value)
    }
    const handleChangeCupon = (event) => {
        setCupon(event.target.value)
    }


    const guardarCotizacion = (URLRedirige) => {
        setOpenLoader(true);
        if (URLRedirige === "/agradecimiento-cotizacion") {
            window.dataLayer.push({
                'event': 'form_step_1_submitpers'
            });
            window.dataLayer.push({
                event : "medico_seguro_personalizar",
                no_cotizacion: dataObj.folio,
                deducible: deducible,
                coaseguro: coaseguro,
                plan: planes,
                modalidad_pago: "Anual",
                opciones_coberturas_seleccionadas: coberturasExtrasSeleccionadas.map(coberturaSeleccionada => (
                    {
                        cobertura: coberturaSeleccionada.nombre
                    }
                )),
                boton_personalizar:'Cotizar personalización'

            })
        } else {
            window.dataLayer.push({
                'event': 'form_step_4_submit',
                'nombre': dataContratante.nombre,
                'parentesco': '',
                'total costo': dataCotizacion.costo.toString()
            });
        }
        if (URLRedirige === "/agradecimiento-cotizacion") {
            window.dataLayer.push({
                'event': 'form_step_1_submitpers'
            });
        } else {
            window.dataLayer.push({
                'event': 'form_step_4_submit',
                'nombre': dataContratante.nombre,
                'parentesco': '',
                'total costo': dataCotizacion.costo.toString()
            });
        }

        let arrConfiguraciones = []
        if (deducible) {
            arrConfiguraciones.push({
                "etiqueta": "Deducible",
                "valor": deducible
            })
        }
        if (coaseguro) {
            arrConfiguraciones.push({
                "etiqueta": "Coaseguro",
                "valor": coaseguro
            })
        }
        if (topeCoaseguro) {
            arrConfiguraciones.push({
                "etiqueta": "Tope Coaseguro",
                "valor": topeCoaseguro
            })
        }
        const objData = [
            {
                "nombreAseguradora": dataCotizacion.aseguradora,
                "nombrePaquete": planes,
                "genero": personaData.genero,
                "edad": personaData.edad,
                "detalles": coberturasExtrasSeleccionadas,
                "configuraciones": arrConfiguraciones.length > 0 ? arrConfiguraciones : [],
                "noColaborador": noColaborador != null ? noColaborador : null,
                "cupon": cupon != null ? cupon : null,
                "id": dataObj.asegurados[0].id,
                "costo": dataCotizacion.costo.toString(),
                "sumaAsegurada": dataCotizacion.sumaAsegurada,
                "Deducible": dataCotizacion.deducible,
                "coaseguro": dataCotizacion.coaseguro,
                "topeCoaseguro": dataCotizacion.topeCoaseguro,
            }
        ]

        Api.guardarCotizacion(dataContratantef3.id, personaData.idAsegurado, objData)
            .then(res => {
                dataObj.ultimoPaso = 4;
                dataObj.cotizaciones = objData;
                delete dataContratante.cotizaciones;
                Api.actualizaContratante(dataContratantef3.id, {...dataObj, dispositivo: device, tipoSeguro, scoreLead})
                    .then(res => {
                        Api.getContratante(dataObj.folio)
                            .then(res => {
                                setOpenLoader(false);
                                window.sessionStorage.setItem(INTER_CONTRATANTE, JSON.stringify({...res.data.contratante, dispositivo: device, tipoSeguro, scoreLead}));
                                window.sessionStorage.removeItem("detalleCotizacion")
                                navigate(URLRedirige);
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                console.log("Error durante guardado: ", error)
            })
        if (URLRedirige === '/cuestionario'){
            window.dataLayer.push({
                event : "medico_seguro_precontratar",
                no_cotizacion: dataObj.folio,
                aseguradora:dataCotizacion.aseguradora,
                codigo_promocion: cupon,
                num_colaborador: noColaborador,
                plan: dataCotizacion.plan,
                modalidad_pago: 'Anual',
                boton_precontratar:'Contratar'
            });
        }
    }

    const evaluacionCoberturas = (objeto, nombreProp, valorVacio, campo) => {
        if (dataCotizacion.plan === 'Blindado' && nombreProp === 'Nivel Hospitalario Básico') {
            if (dataCotizacion.aseguradora === "MAPFRE") {
                nombreProp = 'Nivel hospitalario Alto';
            } else {
                nombreProp = 'Nivel Hospitalario Alto';
            }
        } else if (dataCotizacion.plan === 'Protegido' && nombreProp === 'Nivel Hospitalario Básico') {
            if (dataCotizacion.aseguradora === "MAPFRE") {
                nombreProp = 'Nivel hospitalario Intermedio';
            } else {
                nombreProp = 'Nivel Hospitalario Intermedio';
            }
        }
        if (nombreProp === "Coaseguro" && campo === "valor") {
            return objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp) ? (objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp).valor * 100) + '%' : valorVacio
        } else {
            return objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp) ? objeto.find(elementosDetalles => elementosDetalles.nombre === nombreProp)[campo] : valorVacio
        }
    }

    const handleMostrarAvisoColaborador = () => {
        setMostrarAvisoColaborador(true)
    }

    React.useEffect(() => {
        if (window.sessionStorage.getItem("detalleCotizacion")) {

        } else {
            if (window.sessionStorage.getItem("interContratante")) {
                switch (dataObj.ultimoPaso) {
                    case 1:
                        navigate('/datos-personales');
                        break;
                    case 2:
                        if (dataObj.tipoFlujo === 3) {
                            navigate('/datos/alguien-mas');
                        } else {
                            navigate('/datos/alguien-mas/agregar-mas');
                        }
                        break;
                    case 3:
                        navigate('/detalles-cotizacion');
                        break;
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        navigate('/cuestionario');
                        break;
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        navigate('/contratacion');
                        break;
                    case 12:
                        navigate('/terminamos-contratacion');
                        break;
                    default:
                        navigate('/Bienvenida');
                        break;
                }

            } else {
                navigate("/inicio")
            }
        }
    });
    var primeraLectura = false;

    const styleTagsCabecera = {
        fontWeight: "600",
        fontSize: "12px",
        lineHeight: "24px",
    };

    React.useEffect(() => {

        window.dataLayer.push({
            'event': 'form_step_4_view'
        });
        if (primeraLectura === false && window.sessionStorage.getItem("detalleCotizacion")) {
            if (primeraLectura === false) {
                primeraLectura = true;
                Api.getDetallePaquete(dataCotizacion.aseguradora, dataCotizacion.plan)
                    .then(res => {
                        if (validaResponse(res)) {
                            setDetalles(res.data.detalles);
                            setPlus(res.data.plusadicional);
                            setCoberturasExtras(res.data.extra)

                            Api.getSumaAsegurada(dataCotizacion.idAseguradora, res.data.id, dataContratantef3.id)
                                .then(res2 => {
                                    setPersonaData(res2.data.primas[0])
                                    setCostoAnual(res2.data.total)
                                })
                                .catch(error => { console.error("Error on API getSumaAsegurada:", error.message) })
                        } else {
                            console.log("ERROR");
                            return false
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    }, [dataCotizacion.aseguradora, dataCotizacion.plan, dataCotizacion.idAseguradora, dataContratantef3.id, primeraLectura]);


    const formato = (valor, nombre) => {
        if (nombre === "Coaseguro") {
            return valor + '%';
        }
        if (!isNaN(valor) && valor !== '') {
            return (
                new Intl.NumberFormat("ES-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0
                }).format(valor)
            );
        }
        if (typeof valor === 'string') {
            if (valor !== '') {
                return valor;
            } else {
                return "N/A";
            }

        }
    };

    const handleClickEliminar = () => {
        const seleccionadas = coberturasExtrasSeleccionadas.lenght;
        if (seleccionadas !== 0) {
            setCoberturasExtrasSeleccionadas([]);
        }
        setDeducible('');
        setCoaseguro('');
        setTopeCoaseguro('');

    }

    const mapIconPlan = {
        "Plan Dental": IconPlanDental, 
        "Maternidad": IconMarternidad, 
        "Check up básico": IconoCheckupBasico, 
        "Médicos cerca de ti ": IconMedicoCerca, 
        "Accidentes sin deducible": IconoAccidenteSDeduc,
        "Emergencia en el extranjero": IconEmergenciaExtranjero,
        "Enfermedades catastróficas nacional": IconCatastrofe
    }

    const selectIconForEachPlan = (plan) => {
        for(let iconPlan in mapIconPlan){ 
            if(plan === iconPlan) return <img alt="logos" src={mapIconPlan[iconPlan]} />
        }
    }

    React.useEffect(() => {
        setIsMobiles((size.width <= 467) ? true : false);
    }, [size]);

    const innerTheme = createTheme({
        breakpoints: {
            values: {
                xs: 360,
                sm: 390,
                md: 636,
                lg: 890,
                xl: 1024,
            },
        },
    });

    return (
        <div className="fondoStyle pb-2">
            <Grid container style={{ "justifyContent": "space-around" }}>
                <Grid theme={innerTheme} item md={6} lg={6} sm={12} xs={12} xl={8}>
                    {mostrarPersonalizar ? (
                        <>
                            <Grid sx={{ paddingTop: "6%", }}>
                                <div className="regresar-opciones ml-11" onClick={changeMostrarPersonalizar}>
                                    <img alt="icon-back'" src={IconFlechaIzq} className="w-1 img-goBack-mobile" />Regresar a detalle del seguro</div>
                            </Grid>
                            <Grid container direction="row" className="ml-2">
                                <Grid className="vl-titulo-personalizar" item md={1} xs={1}>
                                    <Divider orientation="vertical" flexItem />
                                </Grid>
                                <Grid item md={10} xs={10}>
                                    <h1 className="margin-personaliza-plan-title personaliza-tu-plan-titulo">Personaliza tu plan</h1>
                                    <p className="ml-1 p-personaliza-tu-plan-descripcion">Modifica o agrega coberturas extras a tu plan y quédate seguro ante cualquier situación.</p>
                                </Grid>
                            </Grid>
                            <div className=" bg-white">
                                <Grid item xs={12} md={12}>
                                    <Box sx={{ maxWidth: "120px" }}> <img width="100" alt="logos" src={dataCotizacion.aseguradora === 'MAPFRE' ? LogoMapfre : dataCotizacion.aseguradora === 'AXA' ? LogoAxa : dataCotizacion.aseguradora === 'GNP' ? LogoGNP : LogoGDS} className="img-gnp-mobile" />
                                    </Box>
                                    <FormControl sx={{ m: 1, maxWidth: "30%", minWidth: "25%" }} className="w-95-mobile">
                                        <InputLabel id="demo-simple-select-helper-label">Planes</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={planes}
                                            label="Age"
                                            onChange={(e) => handleChangeSelectPlanes(e)}
                                        >
                                            <MenuItem value={"Esencial"}>Esencial</MenuItem>
                                            <MenuItem value={"Protegido"}>Protegido</MenuItem>
                                            {dataCotizacion.aseguradora !== 'GENERAL DE SEGUROS' &&
                                                <MenuItem value={"Blindado"}>Blindado</MenuItem>
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ m: 1, maxWidth: "30%", minWidth: "25%" }} className="w-95-mobile">
                                        <TextField
                                            disabled
                                            value="Anual"
                                            label="Modalidad de pago"
                                        />
                                    </FormControl>
                                </Grid>
                                <Divider className="divider-personalizar" />
                                <Grid md={12}>
                                    <h4 className="mtb-1">Aquí puedes modificar tu deducible y coaseguro. </h4>
                                    <p>Míralo así, entre más altos sean, menor será el pago anual de tu seguro.</p>
                                </Grid>
                                {dataCotizacion.aseguradora === "AXA" ?
                                    <>
                                        {/* Selects para AXA */}
                                        <FormControl sx={{ m: 1, minWidth: 230 }}>
                                            <InputLabel id="demo-simple-select-helper-label">Deducible</InputLabel>
                                            <Select
                                                value={deducible}
                                                label="Deducible"
                                                onChange={handleChangeSelectDeducible}
                                            >
                                                <MenuItem value={"11000"}>$11,000</MenuItem>
                                                <MenuItem value={"16000"}>$16,000</MenuItem>
                                                <MenuItem value={"21000"}>$21,000</MenuItem>
                                                <MenuItem value={"31000"}>$31,000</MenuItem>
                                                <MenuItem value={"44000"}>$44,000</MenuItem>
                                                <MenuItem value={"68000"}>$68,000</MenuItem>
                                                <MenuItem value={"145000"}>$145,000</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{ m: 1, minWidth: 230 }}>
                                            <InputLabel id="demo-simple-select-helper-label">Coaseguro</InputLabel>
                                            <Select
                                                label="Coaseguro"
                                                value={coaseguro}
                                                onChange={handleChangeSelectCoaseguro}
                                            >
                                                <MenuItem value={"5%"}>5% </MenuItem>
                                                <MenuItem value={"10%"}>10% </MenuItem>
                                                <MenuItem value={"20%"}>20% </MenuItem>
                                                <MenuItem value={"30%"}>30% </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </> :
                                    dataCotizacion.aseguradora === "GNP" ?
                                        <>
                                            {/* Selects para GNP */}
                                            <FormControl sx={{ m: 1, minWidth: 230 }}>
                                                <InputLabel id="demo-simple-select-helper-label">Deducible</InputLabel>
                                                <Select
                                                    label="Deducible"
                                                    value={deducible}
                                                    onChange={handleChangeSelectDeducible}
                                                >
                                                    <MenuItem value={"15000"}>$15,000</MenuItem>
                                                    <MenuItem value={"18500"}>$18,500</MenuItem>
                                                    <MenuItem value={"27000"}>$27,000</MenuItem>
                                                    <MenuItem value={"32500"}>$32,500</MenuItem>
                                                    <MenuItem value={"40000"}>$40,000</MenuItem>
                                                    <MenuItem value={"49500"}>$49,500</MenuItem>
                                                    <MenuItem value={"69500"}>$69,500</MenuItem>
                                                    <MenuItem value={"86500"}>$86,500</MenuItem>
                                                    <MenuItem value={"109000"}>$109,000</MenuItem>
                                                    <MenuItem value={"150000"}>$150,000</MenuItem>
                                                    <MenuItem value={"172500"}>$172,500</MenuItem>

                                                </Select>
                                            </FormControl>
                                            <FormControl sx={{ m: 1, minWidth: 230 }}>
                                                <InputLabel id="demo-simple-select-helper-label"> Coaseguro</InputLabel>
                                                <Select
                                                    label="Coaseguro"
                                                    value={coaseguro}
                                                    onChange={handleChangeSelectCoaseguro}
                                                >
                                                    <MenuItem value={"5%"}>5%</MenuItem>
                                                    <MenuItem value={"10%"}>10%</MenuItem>
                                                    <MenuItem value={"15%"}>15%</MenuItem>
                                                    <MenuItem value={"20%"}>20%</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </> :
                                        dataCotizacion.aseguradora === "MAPFRE" ?
                                            <>
                                                {/* Selects para MAPFRE */}
                                                <FormControl sx={{ m: 1, minWidth: 230 }}>
                                                    <InputLabel id="demo-simple-select-helper-label">Deducible</InputLabel>
                                                    <Select
                                                        value={deducible}
                                                        label="Deducible"
                                                        onChange={handleChangeSelectDeducible}
                                                    >
                                                        <MenuItem value={"10000"}>$10,000</MenuItem>
                                                        <MenuItem value={"11000"}>$11,000</MenuItem>
                                                        <MenuItem value={"12000"}>$12,000</MenuItem>
                                                        <MenuItem value={"13000"}>$13,000</MenuItem>
                                                        <MenuItem value={"14000"}>$14,000</MenuItem>
                                                        <MenuItem value={"15000"}>$15,000</MenuItem>
                                                        <MenuItem value={"16000"}>$16,000</MenuItem>
                                                        <MenuItem value={"17000"}>$17,000</MenuItem>
                                                        <MenuItem value={"18000"}>$18,000</MenuItem>
                                                        <MenuItem value={"19000"}>$19,000</MenuItem>
                                                        <MenuItem value={"20000"}>$20,000</MenuItem>
                                                        <MenuItem value={"30000"}>$30,000</MenuItem>
                                                        <MenuItem value={"40000"}>$40,000</MenuItem>
                                                        <MenuItem value={"50000"}>$50,000</MenuItem>
                                                        <MenuItem value={"60000"}>$60,000</MenuItem>
                                                        <MenuItem value={"70000"}>$70,000</MenuItem>
                                                        <MenuItem value={"80000"}>$80,000</MenuItem>
                                                        <MenuItem value={"90000"}>$90,000</MenuItem>
                                                        <MenuItem value={"100000"}>$100,000</MenuItem>
                                                        <MenuItem value={"150000"}>$150,000</MenuItem>
                                                        <MenuItem value={"200000"}>$200,000</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <FormControl sx={{ mb: 1, minWidth: 230 }}>
                                                    <InputLabel id="demo-simple-select-helper-label">Coaseguro</InputLabel>
                                                    <Select
                                                        label="Coaseguro"
                                                        value={coaseguro}
                                                        onChange={handleChangeSelectCoaseguro}
                                                    >
                                                        <MenuItem value={"10%"}>10%</MenuItem>
                                                        <MenuItem value={"15%"}>15% </MenuItem>
                                                        <MenuItem value={"20%"}>20% </MenuItem>
                                                        <MenuItem value={"30%"}>30% </MenuItem>
                                                        <MenuItem value={"35%"}>35% </MenuItem>
                                                        <MenuItem value={"40%"}>40% </MenuItem>
                                                        <MenuItem value={"45%"}>45% </MenuItem>
                                                        <MenuItem value={"50%"}>50% </MenuItem>

                                                    </Select>
                                                </FormControl>
                                                <FormControl sx={{ mb: 2 }} className="coinsurance-cap-form-control">
                                                    <InputLabel id="demo-simple-select-helper-label">Tope Coaseguro</InputLabel>
                                                    <Select
                                                        label="Tope Coaseguro"
                                                        value={topeCoaseguro}
                                                        onChange={handleChangeSelectTopeCoaseguro}
                                                    >
                                                        <MenuItem value={"40000"}>$40,000</MenuItem>
                                                        <MenuItem value={"60000"}>$60,000</MenuItem>
                                                        <MenuItem value={"100000"}>$100,000</MenuItem>
                                                        <MenuItem value={"140000"}>$140,000</MenuItem>
                                                        <MenuItem value={"200000"}>$200,000</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </> :
                                            <>
                                                {/* Selects para General de Salud */}
                                                <FormControl sx={{ mb: 2 }} className="coinsurance-cap-form-control">
                                                    <InputLabel id="demo-simple-select-helper-label">Tope de Coaseguro</InputLabel>
                                                    <Select
                                                        label="Tope de Coaseguro"
                                                        value={topeCoaseguro}
                                                        onChange={handleChangeSelectTopeCoaseguro}
                                                    >
                                                        <MenuItem value={"20000"}>$20,000</MenuItem>
                                                        <MenuItem value={"50000"}>$50,000</MenuItem>

                                                    </Select>
                                                </FormControl>
                                            </>}
                                <Grid container className="bg-grey bg-azul5">
                                    <h2 className="ml-5 margin-title-beneficios-extras">Coberturas extras</h2>
                                    <List sx={{ width: '100%', maxWidth: 900, }} className="pl-0-mobile">
                                        {coberturasExtras.map((objeto, idx) => {

                                            const labelId = `checkbox-list-label-${objeto.titulo}`;

                                            return (
                                                <ListItem className="pl-0-mobile">
                                                    <ListItemButton role={undefined} className="padding-1">

                                                        <Grid container>
                                                            <Grid item md={12}>
                                                                <Grid container>
                                                                    <Grid item md={12} sm={12} display={'flex'} alignItems={'baseline'}>
                                                                        <Checkbox
                                                                            checked={coberturasExtrasSeleccionadas.indexOf(objeto) !== -1}
                                                                            onClick={handleToggleCoberturasExtrasSeleccionadas(objeto)}
                                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                                        />
                                                                        <Box sx={{ fontWeight: "700" }}>{objeto.nombre} </Box>
                                                                    </Grid>
                                                                    <Grid item md={12} sm={12}>
                                                                        {objeto.descripcion}
                                                                    </Grid>
                                                                </Grid>


                                                                {/* <p className="txt-contenido-coberturas-extras txt-titulo-coberturas-extras-mobile txt-contenido-coberturas-extras-mobile"> </p> */}
                                                            </Grid>
                                                            {/* <Grid item md={12}>
                                                                <p className="txt-contenido-coberturas-extras txt-titulo-coberturas-extras-mobile txt-contenido-coberturas-extras-mobile">{objeto.descripcion} </p>
                                                            </Grid> */}
                                                        </Grid>
                                                    </ListItemButton>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </Grid>

                            </div>
                        </>
                    ) : (
                        <>
                            <Grid className="pt-15rem">
                                <a href="/cotizacion" className=" regresar-opciones">
                                    <img alt="icon-back" src={IconBack} className="img-goBack-mobile" style={{ height: '12px', marginRight: '1rem' }} />
                                    Regresar a mis opciones
                                </a>
                            </Grid>
                            <div className="card-selected-insurer">
                                <Grid item xs={12} md={12}>
                                    <Grid container gap={'20px'}>
                                        <Grid item xs={12} md={3} display={'flex'} justifyContent={'center'}>
                                            <img 
                                                alt="logo-aseguradora" 
                                                src={dataCotizacion.aseguradora === 'MAPFRE' ? LogoMapfre : dataCotizacion.aseguradora === 'AXA' ? LogoAxa : dataCotizacion.aseguradora === 'GNP' ? LogoGNP : LogoGDS} 
                                                className="logo-insurer-mobile"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} display={'flex'} justifyContent={'center'}>
                                            <FormControl fullWidth className="w-95-mobile">
                                                <InputLabel id="demo-simple-select-helper-label">Planes</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={planes}
                                                    label="Age"
                                                    onChange={(e) => handleChangeSelectPlanes(e)}
                                                >
                                                    <MenuItem value={"Esencial"}>Esencial</MenuItem>
                                                    <MenuItem value={"Protegido"}>Protegido</MenuItem>
                                                    {dataCotizacion.aseguradora !== 'GENERAL DE SEGUROS' &&
                                                        <MenuItem value={"Blindado"}>Blindado</MenuItem>
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4} display={'flex'} justifyContent={'center'}>
                                            <FormControl fullWidth className="w-95-mobile">
                                                <TextField
                                                    disabled
                                                    value="Anual"
                                                    label="Modalidad de pago"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    {/* Contenedor Suma Asegurada */}
                                    <Grid container className="cont-beneficios">
                                        <Grid item xs={12} md={9} display={'flex'}>
                                            <img alt="logos" src={IconSumaAsegurada} />
                                            <p className="title-beneficios">{evaluacionCoberturas(detalles, "Suma Asegurada", "No incluido", "nombre")}</p>
                                        </Grid>
                                        <Grid item xs={12} md={9}>
                                            <p className="p-beneficios">{evaluacionCoberturas(detalles, "Suma Asegurada", "No incluido", "descripcion")}</p>
                                        </Grid>
                                        <Grid item xs={12} md={3} className="bg-beneficios">
                                            <h3 className="data-flag-feature">
                                                {new Intl.NumberFormat('es-MX',
                                                    { style: 'currency', currency: 'MXN', minimumFractionDigits: 0 })
                                                    .format(evaluacionCoberturas(detalles, "Suma Asegurada", "No incluido", "valor"))}
                                            </h3>
                                        </Grid>
                                    </Grid>

                                    {/* Contenedor Deducible (Condicionado por aseguradoras que no incluyen) */}
                                    {evaluacionCoberturas(detalles, "Deducible", "No incluido", "descripcion") === "No incluido" ? <>  </> :
                                        <Grid container className="cont-beneficios">
                                            <Grid item xs={12} md={9} display={'flex'}>
                                                <img alt="logos" src={IconoDeducible} className="m-15" />
                                                <p className="title-beneficios">{evaluacionCoberturas(detalles, "Deducible", "No incluido", "nombre")}</p>
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                <p className="p-beneficios">{evaluacionCoberturas(detalles, "Deducible", "No incluido", "descripcion")}</p>
                                            </Grid>
                                            <Grid item xs={12} md={3} className="bg-beneficios">
                                                <h3 className="data-flag-feature">
                                                    {new Intl.NumberFormat('es-MX',
                                                        { style: 'currency', currency: 'MXN', minimumFractionDigits: 0 })
                                                        .format(evaluacionCoberturas(detalles, "Deducible", "No incluido", "valor"))}
                                                </h3>
                                            </Grid>
                                        </Grid>}

                                    {/* Contenedor nivel Hospitalario */}
                                    <Grid container className="cont-beneficios">
                                        <Grid item xs={12} md={9} display={'flex'}>
                                            <img alt="logos" src={IconNivelH} className="m-15" />
                                            <p className="title-beneficios">{evaluacionCoberturas(detalles, "Nivel Hospitalario Básico", "No incluido", "nombre")}</p>
                                        </Grid>
                                        <Grid item xs={12} md={9}>
                                            <p className="p-beneficios">{evaluacionCoberturas(detalles, "Nivel Hospitalario Básico", "No incluido", "descripcion")}</p>
                                        </Grid>
                                        <Grid item xs={12} md={3} className="bg-beneficios">
                                            <h3 className="data-flag-feature">
                                                {evaluacionCoberturas(detalles, "Nivel Hospitalario Básico", "No incluido", "valor")}
                                            </h3>
                                        </Grid>
                                    </Grid>

                                    {/* Contenedor Coaseguro */}
                                    <Grid container className="cont-beneficios">
                                        <Grid item xs={12} md={9} display={'flex'}>
                                            <img alt="logos" src={IconCoaseguro} className="m-15" />
                                            <p className="title-beneficios">{evaluacionCoberturas(detalles, "Coaseguro", "No incluido", "nombre")}</p>
                                        </Grid>
                                        <Grid item xs={12} md={9}>
                                            <p className="p-beneficios">{evaluacionCoberturas(detalles, "Coaseguro", "No incluido", "descripcion")}</p>
                                        </Grid>
                                        <Grid item xs={12} md={3} className="bg-beneficios">
                                            <Stack direction="column" justifyContent="center" alignItems="center" spacing={0} sx={{ fontWeight: "700", height: "100%" }}>
                                                <div className="m-0-mobile">
                                                    {evaluacionCoberturas(detalles, "Coaseguro", "No incluido", "valor")}
                                                </div>
                                                {dataCotizacion.aseguradora !== "GENERAL DE SEGUROS" && <div>por padecimiento</div>}
                                            </Stack>
                                            {/* <h3>
                                                {new Intl.NumberFormat('es-MX',
                                                    { style: 'currency', currency: 'MXN', minimumFractionDigits: 0 })
                                                    .format(evaluacionCoberturas(detalles, "Coaseguro", "No incluido", "valor"))}</h3> */}
                                        </Grid>
                                    </Grid>

                                    {/* Contenedor Tope Coaseguro */}
                                    <Grid container className="cont-beneficios">
                                        <Grid item xs={12} md={9} display={'flex'}>
                                            <img alt="logos" src={IconTopeCoaseguro} />
                                            <p className="title-beneficios">{evaluacionCoberturas(detalles, "Tope Coaseguro", "No incluido", "nombre")}</p>
                                        </Grid>
                                        <Grid item xs={12} md={9}>
                                            <p className="p-beneficios">{evaluacionCoberturas(detalles, "Tope Coaseguro", "No incluido", "descripcion")}</p>
                                        </Grid>
                                        <Grid item xs={12} md={3} className="bg-beneficios">
                                            <h3 className="data-flag-feature">
                                                {new Intl.NumberFormat('es-MX',
                                                    { style: 'currency', currency: 'MXN', minimumFractionDigits: 0 })
                                                    .format(evaluacionCoberturas(detalles, "Tope Coaseguro", "No incluido", "valor"))}</h3>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* Aca abajo los contenedores mobile */}

                                {plus.length > 0 ? <><div className="banderin-beneficios">Beneficios adicionales gratuitos</div></> : <></>}
                                <Box>
                                    {plus.map((cobertura, i) => (
                                        <Grid container className="cont-beneficios">
                                            <Grid item xs={12} md={9} display={'flex'}>
                                                { selectIconForEachPlan(cobertura.nombre) }
                                                <p className="title-beneficios">{cobertura.nombre}</p>
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                <Grid container>
                                                    <Grid item xs={11} md={11} xl={11}>
                                                        <p className="p-beneficios">{cobertura.descripcion} </p>
                                                    </Grid>
                                                    {cobertura.nombre === "Emergencia en el extranjero" &&
                                                        <Grid item xs={1} md={1} xl={1}>
                                                            <Tooltip title="Aplica deducible por un monto de $150 USD">
                                                                <img alt="ico-tooltip" src="https://i.ibb.co/c1zyz04/tooltip.png" className="ico-tooltip" />
                                                            </Tooltip>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={3} className="bg-beneficios-adic">
                                                <h3 className="m-0-mobile">{formato(cobertura.valor, cobertura.nombre)}</h3>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Box>
                            </div>
                        </>)
                    }
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12} xl={3} >
                    <div >
                        <Grid item xl={10} lg={10} sm={10} xs={10} className="" sx={{
                            paddingTop: "15%",
                            marginLeft: "3%",

                        }}>
                            <Box sx={{
                                display: "flex",
                            }}>
                                <Grid sx={styleTagsCabecera}>
                                    Número de cotización
                                </Grid>
                                <Grid>
                                    <Tooltip title="Con este número podrás completar más tarde tu proceso de contratación, para que no tengas que llenar otra vez todos tus datos">
                                        <img alt="ico-tooltip" src={TooltipIcon} className="ico-tooltip" />
                                    </Tooltip>
                                </Grid>
                            </Box>
                            <Box sx={{
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                width: "100%",
                                height: "34px",
                                padding: "4% 2% 0% 5%"
                            }} >
                                {dataObj.folio}
                            </Box>
                        </Grid>

                        <Grid className="vl-amarilla-titulo">
                            <Divider orientation="vertical" flexItem />
                        </Grid>
                        {/* <Grid item className="vl-titulo" lg={1} sm={1} xs={1} sx={{
                            marginLeft: "-8%",
                            marginRight: "10px",
                        }}>
                            <Divider orientation="vertical" flexItem />
                            <h2 className="ml-3">Cotización</h2>
                        </Grid> */}
                        <Grid>
                            <div className="linea-amarilla">
                                <Box sx={{ width: "100%", height: "100%" }} >
                                    <Divider className="vl-amarilla-titulo" orientation="vertical" flexItem />
                                </Box>
                                <div className="text-dos"><Box sx={{borderLeft:"3px solid #ffd60e", padding:" 0 0 0 10px", margin:"2% 2% 2% 0"}}>Cotizaci&oacute;n</Box></div>
                            </div>
                        </Grid>

                        <Grid>
                            <Stack classNamed="zIndex-15">
                                <Box sx={{
                                    backgroundColor: "white",
                                }} className="cajon-superior-detalle">
                                    <Grid container className="p-5">
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                                            <h4>Titular</h4>
                                        </Grid>
                                        <Grid item xl={6} lg={6} md={12} sm={6} xs={6} >
                                            <p className="txt-tarjeta-cotizacion">Nombre</p>
                                            <p className="subTxt-tarjeta-cotizacion">{dataContratante.nombre}</p>
                                        </Grid>
                                        <Grid item xl={6} lg={6} md={12} sm={6} xs={6}>
                                            <p className="txt-tarjeta-cotizacion">Fecha de nacimiento</p>
                                            <p className="subTxt-tarjeta-cotizacion">{cadenaFecha}</p>
                                        </Grid>
                                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12} >
                                            <p className="txt-tarjeta-cotizacion">Sexo de nacimiento</p>
                                            <p className="subTxt-tarjeta-cotizacion">{dataContratante.genero}</p>
                                        </Grid>
                                        <Grid item xl={6} lg={6} sm={12} xs={12} >
                                            <p className="txt-tarjeta-cotizacion">Teléfono</p>
                                            <p className="subTxt-tarjeta-cotizacion">{dataContratante.telefono}</p>
                                        </Grid>
                                        <Grid item xl={12} lg={12} sm={12} xs={12}>
                                            <p className="txt-tarjeta-cotizacion">E-mail</p>
                                            <p className="subTxt-tarjeta-cotizacion">{dataContratante.correo}</p>
                                        </Grid>
                                        {dataObj.asegurados.lenght > 0 ?
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <h4>Adicional (es)</h4>
                                                </Grid>
                                            </> : <></>}

                                        {dataObj.asegurados.map(cadaAsegurado => (
                                            cadaAsegurado.indice > 0 ?
                                                <>
                                                    <Grid item xs={6} md={6}>
                                                        <p className="txt-tarjeta-cotizacion">Nombre</p>
                                                        <p className="subTxt-tarjeta-cotizacion">{cadaAsegurado.nombre}</p>
                                                    </Grid>
                                                    <Grid item xs={6} md={6}>
                                                        <p className="txt-tarjeta-cotizacion">Parentesco</p>
                                                        <p className="subTxt-tarjeta-cotizacion">{cadaAsegurado.parentesco}</p>
                                                    </Grid>
                                                </>
                                                : <></>
                                        ))}


                                    </Grid>
                                </Box>
                            </Stack>

                            <Stack>
                                <Box sx={{
                                    zIndex: "0",
                                    backgroundColor: "white",
                                    padding: "30px",
                                    margin: "-6px 0 0 0"

                                }}>
                                    <div className="text-cupones-detalle">Cupones</div>
                                    <TextField value={cupon === null ? undefined : cupon} sx={{  marginBottom: '1.5rem'}} className="w-100" id="outlined-basic" label="Ingresa tu código de promoción" variant="outlined" onChange={handleChangeCupon} />
                                    <div className="text-auxiliar-cupones" onClick={handleMostrarAvisoColaborador}>¿Eres colaborador de interprotecci&oacute;n?</div>
                                    {mostrarAvisoColaborador === true ?
                                        <Box sx={{
                                            backgroundColor: "#F2FAFC",
                                            padding: "5%",
                                            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
                                            borderRadius: "10px"
                                        }}>
                                            <Box sx={{ margin: "0 0 10px 0" }} className="encabezado-aviso-colaborador">¡ATENCIÓN!</Box>
                                            <Box sx={{ margin: "0 0 10px 0" }} className="sub-cabecera-aviso-colaborador">¿Eres colaborador de inter?</Box>
                                            <Box sx={{ margin: "0 0 10px 0" }} classname="txt-cabecera-aviso-colaborador">Agrega tu número de colaborador para aplicar los beneficios que tenemos para tí.</Box>
                                            <TextField value={noColaborador === null ? undefined : noColaborador} onChange={handleChangeNoColaborador} className="w-100 input-no-colaborador" label="Número de colaborador" variant="outlined" />
                                        </Box>
                                        : <></>}
                                </Box>
                                <Box sx={{
                                    backgroundColor: "white",
                                    zIndex: "5"
                                }}>
                                    <Stack direction="row"  >
                                        <div className="half-circle-left"></div>
                                        <hr className='hr' />
                                        <div className="half-circle-right"></div>
                                    </Stack>
                                </Box>
                                <Box sx={{
                                    zIndex: "0",
                                    backgroundColor: "white",
                                    padding: "30px",
                                    margin: "-6px 0 0 0"

                                }}>
                                    <Stack direction="row" justifyContent="space-between">
                                        <div className="text-cupones-detalle">Precio informativo</div>
                                        <Tooltip title="Esta cotización es de caracter informativo, el precio final de tu seguro médico puede variar cuando la aseguradora revise la información de tu solicitud.">
                                            <img alt="ico-tooltip" src="https://i.ibb.co/c1zyz04/tooltip.png" className="ico-tooltip" />
                                        </Tooltip>
                                    </Stack>



                                    {mostrarPersonalizar ? (<>
                                        <Box sx={{ mt: 2 }} />
                                        <Stack direction="row" justifyContent="space-between">
                                            <div>Plan {planes} </div>
                                            <div className="txt-monto-plan">{new Intl.NumberFormat('es-MX',
                                                { style: 'currency', currency: 'MXN', minimumFractionDigits: 0 })
                                                .format(costoAnual)}</div>
                                        </Stack>
                                        <Box sx={{ mt: 2 }} />
                                        <Stack>
                                            <Stack direction="row" justifyContent="space-between">
                                                <div>Plan personalizado </div>
                                                <IconButton
                                                    sx={{ position: 'relative' }}
                                                    onClick={handleClickEliminar}
                                                >
                                                    <CloseIcon sx={{ fontSize: 14, color: (theme) => theme.palette.grey[400], }} />
                                                </IconButton>
                                            </Stack>
                                            <ul >
                                                {deducible !== '' ? <li className="list-estilos-plant-personalizado">Deducible <b>{new Intl.NumberFormat('es-MX',
                                                    { style: 'currency', currency: 'MXN', minimumFractionDigits: 0 })
                                                    .format(deducible)}</b></li> : <></>}
                                                {coaseguro !== '' ? <li className="list-estilos-plant-personalizado">Coaseguro <b>{coaseguro}</b></li> : <></>}
                                                {topeCoaseguro !== '' ? <li className="list-estilos-plant-personalizado">Tope de coaseguro <b>{new Intl.NumberFormat('es-MX',
                                                    { style: 'currency', currency: 'MXN', minimumFractionDigits: 0 })
                                                    .format(topeCoaseguro)}</b></li> : <></>}

                                                {coberturasExtrasSeleccionadas.map(cadaCoberturaExtra => (
                                                    <li className="list-estilos-plant-personalizado">{cadaCoberturaExtra.nombre}</li>
                                                ))}</ul>
                                        </Stack>
                                        {/* <Box sx={{
                                            backgroundColor: "white",
                                           width:"100%"
                                        }}>
                                            <Stack direction="row" justifyContent="space-between" >
                                                <div className="half-circle-left"></div>
                                                <hr className="hr" />
                                                <div className="half-circle-right"></div>
                                            </Stack>
                                        </Box> */}
                                        <Box sx={{ mt: 2 }} />
                                        <Stack>
                                            <Box sx={{
                                                background: "#F2FAFC",
                                                borderRadius: "6px;",
                                                padding: "10%"
                                            }}>

                                                <div className="titulo-cabecera-aviso-importante">Aviso importante</div>
                                                <div className="txt-aviso-importante">Un asesor inter.mx te contactará para darte más información y el precio final de tu plan personalizado.</div>
                                            </Box>
                                            <Box sx={{ mt: 3 }} />
                                        </Stack>
                                        {(!isMobiles) ?
                                            <Grid md={12}>
                                                <Button className="btn-contratar" onClick={() => { guardarCotizacion('/agradecimiento-cotizacion') }}>Cotizar Personalización</Button>
                                            </Grid>
                                            :
                                            <Grid md={12}
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center">
                                                <Button
                                                    style={{
                                                        width: '90%',
                                                        position: 'fixed',
                                                        top: "90%",
                                                    }}
                                                    className="boton-flotante"
                                                    onClick={() => { guardarCotizacion('/agradecimiento-cotizacion') }}>Cotizar Personalización</Button>
                                            </Grid>
                                        }

                                    </>) : (<>
                                        <Box sx={{ mt: 3 }} />
                                        <Stack direction="row" justifyContent="space-between">
                                            <div>Costo anual </div>
                                            <div className="txt-monto-plan">{new Intl.NumberFormat('es-MX',
                                                { style: 'currency', currency: 'MXN', minimumFractionDigits: 0 })
                                                .format(costoAnual)}</div>
                                        </Stack>
                                        <Box sx={{
                                            backgroundColor: "white",
                                        }}>
                                            <Box sx={{ mt: 3 }} />
                                            <hr className='hr-hidden' />
                                            <hr className='hr-dos' />
                                        </Box>
                                        <Box sx={{ mt: 3 }} />
                                        <Grid md={12}>
                                            <Button className="bnt-personalizar" onClick={changeMostrarPersonalizar}>Personalizar</Button>
                                        </Grid>
                                        <Grid md={12}>
                                            <Button className="btn-contratar" onClick={() => { guardarCotizacion('/cuestionario') }}>Contratar</Button>
                                        </Grid>
                                    </>)}
                                </Box>
                            </Stack>
                        </Grid>
                    </div>
                </Grid >
            </Grid >
            <Loading open={openLoader} onClose={() => setOpenLoader(false)} />
        </div >
    )
}
