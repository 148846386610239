import * as react from "react"
import { Navigate } from "react-router-dom"
import DetallesCotizacion from "../../../components/DetallesCotizacion/DetallesCotizacion"


const CotizacionDetalles = (props) => {
    const existDataSession =  sessionStorage.getItem("interContratante")? true : false
    return (
        existDataSession? <DetallesCotizacion isMobile={props.isMobile} /> : <Navigate to="/inicio?view=haveCotizacion" />
    )
}

export default CotizacionDetalles