
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TarjetaAgregar from './TarjetaAgregar';
import { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';


export default function Inicio(props) {
  const navigate = useNavigate();
  let { continua } = useParams();
  const [agregarMas, setAgregarMas] = useState(false);

  const handleDespues = () => {

    window.dataLayer.push ({
       'event': 'form_step_1_submitADI'
    });
    navigate('/cotizacion');
    window.dataLayer.push({
      event: 'medico_seguro_terceros_eleccion',
      boton_cotizacion: 'Tal vez después',
    })
  }

  const handleContinuar = (value) => {

    window.dataLayer.push ({
       'event': 'form_step_1_submitADI'
    });
    setAgregarMas(true);
    window.dataLayer.push ({
       'event': 'form_step_2_viewADI'
    });
    window.dataLayer.push({
      event: 'medico_seguro_terceros_eleccion',
      boton_cotizacion: 'Si, me encanta la idea',
    })
  }

  const handleRegresar = (value) => {
    if(agregarMas){
      setAgregarMas(!agregarMas);
    } else {
      props.handleRegresar();
    }
  }

  useEffect(() => {
    window.dataLayer.push ({
       'event': 'form_step_1_viewADI'
    });
    
    if(continua){
      setAgregarMas(true);
    }
    /* Especifica cómo sanear este efecto:
    return function cleanup() {
    };*/
  },[continua]);

  
  return (
    <>
      { (!props.isMobile) ?
        <IconButton className="back-button" onClick={handleRegresar}>
          <ArrowBackIcon/>
        </IconButton>
        :
        <IconButton className="hidden-movil" onClick={handleRegresar} ref={props.regresarRef}>
          <ArrowBackIcon/>
        </IconButton>
      }
      <Box className='box-perfilamiento-form'>
        { agregarMas ?
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} className="normalize-modal">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100">
              <p className="hola-txt"></p>
              <p className="desc-txt">¿A qui&eacute;n te gustar&iacute;a asegurar? Dinos qui&eacute;n es el afortunad&#64;</p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100 pt-24">
              <TarjetaAgregar handleAdvertencias={props.handleAdvertencias}/>
            </Grid>
          </Grid>
        :
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} className="normalize-modal">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100">
                <p className="desc-txt">
                  ¿Qu&eacute; te parecer&iacute;a asegurar a alguien m&aacute;s?
                  (Pareja, hijos, hermanos o personal dom&eacute;stico que compartan el mismo domicilio)
                </p>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100 pt-24">
                <Button variant="outlined" disableElevation
                  className="boton-para w-100"
                  onClick={() => handleContinuar()}>
                  S&iacute;, me encanta la idea
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="minus-pl-32px w-100 pt-24">
                <Button variant="outlined" disableElevation
                  className="boton-para w-100"
                  onClick={() => handleDespues()}>
                  Tal vez despu&eacute;s
                </Button>
              </Grid>
            </Grid>
        }
      </Box>
    </>
  );
}