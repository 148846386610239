import * as React from 'react';
import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import useWindowSize from '../Utils/useWindowSize';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  titulo: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#000000"
  },
  textoPaso: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lilineHeight: "17px",
    textAlign: "center",
    color: "#000000"
  },
  pasos: {
    margin: "10px 0 22px 0",
    display: "inline-block",
    width: "100%"
  },
  paso: {
    borderBottomStyle: "solid",
    borderBottomWidth: "5px",
    display: "inline-block",
    borderRadius: "8px",
    marginRight: "8px"
  },
  pasoActivo: {
    borderBottomColor: "#ffd60e",
  },
  pasoInactivo: {
    borderBottomColor: "#c7c7c7",
  },
  ultimoPaso: {
    marginRight: "0px"
  }
}));

export default function Stepper(props) {
  const classes = useStyles();
  const size = useWindowSize();
  const [dynamicW, setDynamicW] = useState(2);

  React.useEffect(() => {
    if(size.width > 749) {
      setDynamicW(1);
    }
  }, [size]);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div className={classes.titulo}>{props.stepper.titulo}</div>
        <div className={classes.textoPaso}>{props.stepper.textoPaso}</div>
        <div className={classes.pasos}>
          {props.stepper.pasos.map((paso, i) => (
            <div className={
              clsx(classes.paso, paso.activo && classes.pasoActivo, !paso.activo && classes.pasoInactivo,
                    (i === (props.stepper.pasos.length-1)) && classes.ultimoPaso)} 
                              style={{width: ((100 / props.stepper.pasos.length)-dynamicW) + '%'}}
                        key={i}></div>
          ))}
        </div>
      </Grid>
    </Grid>
  );

}